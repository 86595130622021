<p class="sbx-auth-main-title">Forgot password?</p>
<p class="sbx-auth-sub-title">
  Provide an email address associated with your Fidelity Private Shares account.
  <br />We'll send you a code to verify it's you.
</p>
<form [formGroup]="emailForm" (ngSubmit)="getAvailableIdentificationMethods()">
  <div class="sbx-auth-form-group">
    <label for="email">Email</label>
    <input
      class="sbx-auth-input"
      type="email"
      id="email"
      formControlName="email"
      ngDefaultControl
      name="email"
      placeholder="Enter email"
    />
  </div>
  <div class="sbx-auth-submit-btn">
    <button
      type="submit"
      class="sbx-auth-primary-button"
      [disabled]="emailForm.invalid"
    >
      Continue
    </button>
  </div>
</form>
