<p class="sbx-auth-main-title">Terms of Use and Privacy Policy</p>
<p class="sbx-auth-sub-title">
  To continue, please read and agree to the Fidelity Private Shares
  <a
    class="sbx-auth-link"
    href="/privacy-and-terms.html#tab=terms-of-use"
    target="_blank"
    >Terms of Use</a
  >
  and
  <a
    class="sbx-auth-link"
    href="/privacy-and-terms.html#tab=privacy-policy"
    target="_blank"
    >Privacy Policy</a
  >.
</p>

<form [formGroup]="agreementForm" (ngSubmit)="takeAgreementAndContinue()">
  <div class="sbx-auth-form-group">
    <div class="sbx-auth-checkbox-input">
      <input
        class="sbx-auth-checkbox"
        formControlName="agreeToTerms"
        type="checkbox"
        id="agreeToTerms"
      />
      <label for="agreeToTerms"
        >I have read and agree to the Fidelity Private Shares Terms of Use and Privacy
        Policy
      </label>
    </div>
    <div class="sbx-auth-submit-btn">
      <button
        type="submit"
        class="sbx-auth-primary-button"
        [disabled]="!agreementForm.value.agreeToTerms"
      >
        Continue
      </button>
    </div>
  </div>
</form>
