<div class="sbx-auth-popup">
  <div class="sbx-auth-popup-title" *ngIf="title">
    {{ title }}
  </div>
  <div class="sbx-auth-popup__content">
    <ng-content select=".content"></ng-content>
  </div>
  <div class="sbx-auth-popup-action-button" *ngIf="actionButtonTitle">
    <button class="sbx-auth-primary-button" type="primary" (click)="emitAction()">
      {{ actionButtonTitle }}
    </button>
  </div>
</div>

<div class="sbx-auth-popup-close-button">
  <img
    [src]="sbxUrlService.assetUrl('img/fidelity/svg/close.svg')"
    alt="close"
    (click)="closePopup()"
  />
</div>
