import { SbxUrlService } from '@/core/url/url.service';
import { Component, EventEmitter, HostListener, Input, Output } from '@angular/core';

@Component({
  selector: 'sbx-auth-overlay',
  templateUrl: './sbx-auth-overlay.component.html',
  styleUrls: ['./sbx-auth-overlay.component.scss'],
})
export class SbxAuthOverlayComponent {
  @Input()
  title: string;

  @Output()
  close = new EventEmitter<void>();

  @HostListener('document:keydown.escape', ['$event'])
  onEscapeKeyDownHandler(): void {
    this.closeOverlay();
  }

  constructor(protected readonly sbxUrlService: SbxUrlService) {}

  public closeOverlay(): void {
    this.close.emit();
  }
}
