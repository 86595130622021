<ng-container
  *ngTemplateOutlet="isUnassociated ? unassociated : associated"
></ng-container>

<ng-template #associated>
  <p class="sbx-auth-main-title">Do you have a Fidelity log in?</p>
  <p class="sbx-auth-sub-title">
    If you have any type of account with Fidelity, like a 401(k), IRA or brokerage
    account, log in with Fidelity credentials to complete the transition process. If
    not, you can create one now.
  </p>
  <div class="sbx-auth-button-panel">
    <button class="sbx-auth-primary-button" (click)="login()">
      Log in with Fidelity
    </button>
    <button class="sbx-auth-secondary-button" (click)="signup()">
      Create a Fidelity account
    </button>
  </div>
  <a class="sbx-auth-link" (click)="navigateToPasswordRecovery()"
    >I have a Fidelity account, but I forgot my username</a
  >
</ng-template>

<ng-template #unassociated>
  <p class="sbx-auth-main-title">
    That Fidelity login isn’t associated with a Fidelity Private Shares account
  </p>
  <p class="sbx-auth-sub-title">
    If you have a Fidelity Private Shares account and haven’t transitioned your login,
    you need to transition your login to access your account.
  </p>
  <div class="sbx-auth-button-panel">
    <button class="sbx-auth-primary-button" (click)="completeTransition()">
      Transition your login
    </button>
    <button class="sbx-auth-secondary-button" (click)="backToLogin()">
      Return to login page
    </button>
  </div>
</ng-template>

<sbx-info-action-popup (close)="hidePopup()" *ngIf="isPopupVisible">
  <p class="content">
    Have questions? Visit our
    <a
      class="sbx-auth-link"
      target="_blank"
      href="https://shoobx.zendesk.com/hc/en-us/articles/27911891574157-Fidelity-Log-In-Credentials"
      >Login Transition Help Center</a
    >.
  </p>
</sbx-info-action-popup>

<sbx-auth-alert
  *ngIf="isVerifiedAlertVisible"
  [type]="alertTypeEnum.success"
  (close)="toggleVerifiedAlertVisibility()"
>
  <p>Account verified</p>
</sbx-auth-alert>
