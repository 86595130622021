import { Plugin, Command } from '@ckeditor/ckeditor5-core';
import {
  addListToDropdown,
  createDropdown,
  ListDropdownItemDefinition,
  ViewModel,
} from '@ckeditor/ckeditor5-ui';
import { Collection } from '@ckeditor/ckeditor5-utils';
import { DomEventObserver } from '@ckeditor/ckeditor5-engine';
import { SchemaContextDefinition } from '@ckeditor/ckeditor5-engine/src/model/schema';

class PlaceholderCommand extends Command {
  constructor(editor) {
    super(editor);
  }
  execute() {
    const editor = this.editor;

    editor.model.change((writer) => {
      const placeholder = writer.createElement('placeholder', { innerHTML: '<Data>' });
      editor.model.insertContent(placeholder);
      writer.setSelection(placeholder, 'on');
    });
  }

  refresh() {
    const model = this.editor.model;
    const selection = model.document.selection;
    const isAllowed = model.schema.checkChild(
      selection.focus.parent as SchemaContextDefinition,
      'placeholder',
    );

    this.isEnabled = isAllowed;
  }
}

class PlaceholderUI extends Plugin {
  init() {
    const editor = this.editor;
    const t = editor.t;
    const placeholderNames = ['date', 'first name', 'surname'];

    editor.ui.componentFactory.add('placeholder', (locale) => {
      const dropdownView = createDropdown(locale);

      addListToDropdown(dropdownView, getDropdownItemsDefinitions(placeholderNames));

      dropdownView.buttonView.set({
        label: t('Placeholder'),
        tooltip: true,
        withText: true,
      });

      this.listenTo(dropdownView, 'execute', (evt) => {
        // eslint-disable-next-line  @typescript-eslint/no-explicit-any
        editor.execute('placeholder', { value: (evt.source as any).commandParam });
        editor.editing.view.focus();
      });

      return dropdownView;
    });
  }
}

function getDropdownItemsDefinitions(
  placeholderNames,
): Collection<ListDropdownItemDefinition> {
  const itemDefinitions: Collection<ListDropdownItemDefinition> = new Collection();

  for (const name of placeholderNames) {
    const definition: ListDropdownItemDefinition = {
      type: 'button',
      model: new ViewModel({
        commandParam: name,
        label: name,
        withText: true,
      }),
    };

    itemDefinitions.add(definition);
  }

  return itemDefinitions;
}

class MouseOverObserver extends DomEventObserver<'mouseover'> {
  // It can also be defined as a normal property in the constructor.
  get domEventType(): 'mouseover' {
    return 'mouseover';
  }

  onDomEvent(domEvent) {
    this.fire('mouseover', domEvent);
  }
}

class MouseOutObserver extends DomEventObserver<'mouseout'> {
  // It can also be defined as a normal property in the constructor.
  get domEventType(): 'mouseout' {
    return 'mouseout';
  }

  onDomEvent(domEvent) {
    this.fire('mouseout', domEvent);
  }
}

export { PlaceholderCommand, PlaceholderUI, MouseOverObserver, MouseOutObserver };
