import { Plugin } from '@ckeditor/ckeditor5-core';
import CustomParaStyleCommand from './customParaStyleCommand';
import { PARA_STYLES } from './utils';
const CUSTOMSTYLE = 'customParaStyle';

export default class CustomParaStyleEditing extends Plugin {
  constructor(editor) {
    super(editor);
    editor.config.define(CUSTOMSTYLE, {
      options: PARA_STYLES,
    });
  }

  init() {
    const editor = this.editor;
    const schema = editor.model.schema;
    const enabledOptions = editor.config.get('customParaStyle.options');

    schema.extend('$text', { allowAttributes: CUSTOMSTYLE });
    schema.extend('$block', { allowAttributes: CUSTOMSTYLE });
    const definition = _buildDefinition(enabledOptions);
    editor.conversion.attributeToAttribute(definition);
    editor.commands.add(CUSTOMSTYLE, new CustomParaStyleCommand(editor));
  }
}

// @private
function _buildDefinition(options) {
  const definition = {
    model: {
      key: 'customParaStyle',
      values: options.map((option) => option.type).slice(),
    },
    view: {},
  };
  options.forEach((option) => {
    definition.view[option.type] = {
      key: 'class',
      value: option.class,
    };
  });
  return definition;
}
