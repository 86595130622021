import { Component, OnInit } from '@angular/core';
import { SbxAuthService } from '../sbx-auth.service';
import { filter, take } from 'rxjs';
import { Router } from '@angular/router';
import { AuthIdentificationMethod } from '@shoobx/types/webapi-v2';

@Component({
  selector: 'sbx-auth-password-recovery-page',
  templateUrl: './sbx-auth-password-recovery-page.component.html',
  styleUrls: ['./sbx-auth-password-recovery-page.component.scss'],
})
export class SbxAuthPasswordRecoveryPageComponent implements OnInit {
  public availableMethods$ = this.sbxAuthService.availableIdentificationMethods$;

  constructor(
    private readonly sbxAuthService: SbxAuthService,
    private readonly router: Router,
  ) {}

  public ngOnInit(): void {
    this.subscribeToAvailableMethods();
  }

  private subscribeToAvailableMethods(): void {
    this.sbxAuthService.availableIdentificationMethods$
      .pipe(
        filter((methods: AuthIdentificationMethod[]) => methods?.length === 1),
        take(1),
      )
      .subscribe((options: AuthIdentificationMethod[]) => {
        this.router.navigate(['/auth/verification-code'], {
          queryParams: { method: options[0].method },
        });
        this.sbxAuthService.availableIdentificationMethods = [];
      });
  }
}
