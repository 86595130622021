import { Component, OnDestroy, OnInit } from '@angular/core';
import { SbxAuthService } from '../../sbx-auth.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Observable } from 'rxjs';
import { AuthIdentificationMethod } from '@shoobx/types/webapi-v2';
import { Router } from '@angular/router';

@Component({
  selector: 'sbx-auth-recovery-method',
  templateUrl: './sbx-auth-recovery-method.component.html',
  styleUrls: ['./sbx-auth-recovery-method.component.scss'],
})
export class SbxAuthRecoveryMethodComponent implements OnInit, OnDestroy {
  public authMethodForm: FormGroup;

  public availableIdentificationMethods$: Observable<AuthIdentificationMethod[]> =
    this.sbxAuthService.availableIdentificationMethods$;

  constructor(
    private readonly fb: FormBuilder,
    private readonly sbxAuthService: SbxAuthService,
    private readonly router: Router,
  ) {}
  
  public ngOnInit(): void {
    this.initForms();
  }

  public ngOnDestroy(): void {
    this.sbxAuthService.availableIdentificationMethods = [];
  }
  
  public sendRecoveryRequest(): void {
    this.router.navigate(['/auth/verification-code'], {
      queryParams: { method: this.authMethodForm.value.method },
    });
  }

  private initForms(): void {
    this.authMethodForm = this.fb.group({
      method: ['', Validators.required],
    });
  }
}
