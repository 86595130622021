<div class="sbx-auth-code-verification-page-content">
  <p class="sbx-auth-main-title">Enter your verification code</p>
  <p class="otp-server-message">{{ message }}</p>
  <form [formGroup]="codeForm" (ngSubmit)="identifyUser()">
    <div class="sbx-auth-form-group">
      <label for="code">Code</label>
      <input
        class="sbx-auth-input"
        type="code"
        id="code"
        name="code"
        formControlName="code"
        ngDefaultControl
        placeholder="Code"
      />
      <div
        [style.visibility]="errorMessage ? 'visible' : 'hidden'"
        class="sbx-auth-error-message"
      >
        <img
          [src]="sbxUrlService.assetUrl('img/fidelity/svg/alert-critical.svg')"
          alt="alert-critical"
        />{{ errorMessage }}
      </div>
      <div class="sbx-auth-controls">
        <button
          class="sbx-auth-primary-button"
          type="submit"
          id="continueButton"
          [disabled]="codeForm.invalid"
        >
          Continue
        </button>
      </div>
    </div>
  </form>

  <div class="sbx-auth-resend-btn">
    <span class="sbx-auth-loader" *ngIf="loading"></span>
    <a class="sbx-auth-link" (click)="sendRecoveryRequest()">Resend</a>
  </div>
</div>
