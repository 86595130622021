import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { UpgradeModule } from '@angular/upgrade/static';
import { CoreModule } from './core/core.module';
import { SharedModule } from './shared/shared.module';
import { SbxCheckpointModule } from './sbx-checkpoint/sbx-checkpoint.module';
import { SbxProcessIntroModule } from './sbx-process-intro/sbx-process-intro.module';
import { SbxPeerCompanyAdminModule } from './sbx-peer-company-admin/sbx-peer-company-admin.module';
import { TailoredModule } from './tailored/tailored.module';
import { ProcessModule } from './process/sbx-process.module';
import { SbxWorkflowsModule } from './workflows/sbx-workflows.module';
import { WorkitemModule } from './workitem/workitem.module';
import { SbxDealRoomModule } from './sbx-deal-room/sbx-deal-room.module';
import { SbxAppModule } from './sbx-app/sbx-app.module';
import { SbxInvestmentModule } from './sbx-investment/sbx-investment.module';
import { SbxHrAdminModule } from './sbx-hr-admin/sbx-hr-admin.module';
import { AppConfig } from './core/config/app.config';
import { SbxErrorPageModule } from './sbx-error-page/sbx-error-page.module';
import { SbxPieSettingsModule } from './sbx-pie-settings/sbx-pie-settings.module';
import { SbxIsoNqoTableModule } from './sbx-iso-nqo-table/sbx-iso-nqo-table.module';
import { SbxAsc718AdminModule } from './sbx-asc718-admin/sbx-asc718-admin.module';
import { SbxLogoutRedirectModule } from './sbx-logout-redirect/sbx-logout-redirect.module';
import { SbxAuthModule } from './sbx-auth/sbx-auth.module';

@NgModule({
  imports: [
    BrowserModule,
    UpgradeModule,
    CoreModule,
    SharedModule,
    SbxInvestmentModule,
    SbxAsc718AdminModule,
    SbxCheckpointModule,
    SbxProcessIntroModule,
    TailoredModule,
    ProcessModule,
    SbxWorkflowsModule,
    WorkitemModule,
    SbxDealRoomModule,
    SbxPeerCompanyAdminModule,
    SbxHrAdminModule,
    SbxAppModule,
    SbxAuthModule,
    SbxErrorPageModule,
    SbxPieSettingsModule,
    SbxIsoNqoTableModule,
    SbxLogoutRedirectModule,
  ],
})
export class AppModule {
  constructor(
    private upgrade: UpgradeModule,
    private appConfig: AppConfig,
  ) {}

  ngDoBootstrap() {
    this.upgrade.bootstrap(document.querySelector('body'), ['ngShoobx'], {
      strictDi: true,
    });
    this.appConfig.bootstrapped$.next(true);
  }
}
