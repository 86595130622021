import angular from 'angular';
import Formly from 'angular-formly';
import { OverrideControl } from './override';
import { rtvOnBlur, rtvOnChange, rtvOnFocus } from './validation-handler';

/**
 * @description
 * Formly configuration run block
 */

export const FormlyConfig = [
  'formlyConfigProvider',
  function (formlyConfigProvider) {
    /** Formly Widget Templates **/
    function textlineBaseTOShape(APICheck) {
      return {
        prefix: APICheck.string.optional,
        postfix: APICheck.string.optional,
        type: APICheck.string.optional,
      };
    }
    formlyConfigProvider.setType([
      {
        /* Read Only */
        name: 'read-only',
        template: require('./templates/formly/read-only.html'),
        data: {},
      },
      {
        /* Enum Dropdown */
        name: 'enum-dropdown',
        template: require('./templates/formly/enum-dropdown.html'),
        data: {},
      },
      {
        /* Dynamic Enum Dropdown */
        name: 'dynamic-enum-dropdown',
        template: require('./templates/formly/dynamic-enum-dropdown.html'),
        data: {},
      },
      {
        /* Enum Radios */
        name: 'enum-radios',
        template: require('./templates/formly/enum-radios.html'),
        data: {},
      },
      {
        /* Reference Radios */
        name: 'reference-radios',
        template: require('./templates/formly/reference-radios.html'),
        data: {},
      },
      {
        /* Stripe */
        name: 'stripe-element',
        template: require('./templates/formly/stripe.html'),
        data: {},
      },
      {
        /* Stakeholder Widget */
        name: 'stakeholder',
        template: require('./templates/formly/stakeholder.html'),
        data: {},
      },
      {
        /* String Textline */
        name: 'string-textline',
        template: require('./templates/formly/textline.html'),
        apiCheck: function (APICheck) {
          return {
            templateOptions: textlineBaseTOShape(APICheck),
          };
        },
        data: {},
      },
      {
        /* Department Selection */
        name: 'department',
        template: require('./templates/formly/department.html'),
        data: {},
      },
      {
        /* Number Textline */
        name: 'number-textline',
        template: require('./templates/formly/number-textline.html'),
        data: {},
      },
      {
        /* Object Typeahead Textline */
        name: 'object-typeahead-textline',
        extends: 'string-textline',
        template: require('./templates/formly/typeahead.html'),
        apiCheck: function (APICheck) {
          return {
            templateOptions: angular.extend(textlineBaseTOShape(APICheck), {
              options: APICheck.array,
            }),
          };
        },
        data: {},
      },
      {
        /* Email Textline */
        name: 'email-textline',
        extends: 'string-textline',
        data: {},
      },
      {
        /* Verification requiring contact & token challenge */
        name: 'require-verification',
        template: require('./templates/formly/verify.html'),
        data: {},
      },
      {
        /* Measurement Textline */
        name: 'measurement-textline',
        extends: 'number-textline',
        data: {},
      },
      {
        /* Text Blob */
        name: 'text',
        template: require('./templates/formly/text.html'),
        data: {},
      },
      {
        /* Date */
        name: 'date',
        template: require('./templates/formly/date.html'),
        data: {},
      },
      {
        /* Time */
        name: 'time',
        template: require('./templates/formly/time.html'),
        data: {},
      },
      {
        /* Credit Card Expiration Date */
        name: 'exp-date',
        template: require('./templates/formly/exp-date.html'),
        data: {},
      },
      {
        /* Boolean Toggle */
        name: 'bool-toggle',
        template: require('./templates/formly/bool-toggle.html'),
        data: {},
      },
      {
        /* Boolean Checkbox */
        name: 'bool-checkbox',
        template: require('./templates/formly/bool-checkbox.html'),
        data: {},
      },
      {
        /* Boolean Radios */
        name: 'bool-radios',
        template: require('./templates/formly/bool-radios.html'),
        data: {},
      },
      {
        /* Address */
        name: 'address',
        template: require('./templates/formly/address.html'),
        data: {},
      },
      {
        /* Asc */
        name: 'stock-ticker',
        template: require('./templates/formly/stock-ticker.html'),
        data: {},
      },
      {
        /* List of Stakeholder */
        name: 'list-of-stakeholder',
        template: require('./templates/formly/list-of-sh.html'),
        apiCheck: function (APICheck) {
          return {
            templateOptions: {
              valueLabel: APICheck.string.optional,
              valueOptions: APICheck.arrayOf(APICheck.object).optional,
              valueType: APICheck.object.optional,
            },
          };
        },
        data: {},
      },
      {
        /* List of Document */
        name: 'list-of-document',
        template: require('./templates/formly/list-of-doc.html'),
        apiCheck: function (APICheck) {
          return {
            templateOptions: {
              valueLabel: APICheck.string.optional,
              valueOptions: APICheck.arrayOf(APICheck.object).optional,
              valueType: APICheck.object.optional,
            },
          };
        },
        data: {},
      },
      {
        name: 'list-of-profile',
        template: require('./templates/formly/list-of-prof.html'),
      },
      {
        name: 'profile',
        template: require('./templates/formly/profile.html'),
      },
      {
        /* List of Record */
        name: 'record-table',
        template: require('./templates/formly/record-table.html'),
        apiCheck: function (APICheck) {
          return {
            templateOptions: {
              valueLabel: APICheck.string.optional,
              valueOptions: APICheck.arrayOf(APICheck.object).optional,
              valueType: APICheck.object.optional,
            },
          };
        },
        data: {},
      },
      {
        /* List */
        name: 'list',
        template: require('./templates/formly/list.html'),
        apiCheck: function (APICheck) {
          return {
            templateOptions: {
              valueLabel: APICheck.string.optional,
              valueOptions: APICheck.arrayOf(APICheck.object).optional,
              valueType: APICheck.object.optional,
            },
          };
        },
        data: {},
      },
      {
        /* Record */
        name: 'record',
        template: require('./templates/formly/record.html'),
        apiCheck: function (APICheck) {
          return {
            templateOptions: {
              valueSchema: APICheck.array,
            },
          };
        },
        data: {},
      },
      {
        /* Folder Tree */
        name: 'folder-tree',
        template: require('./templates/formly/folder-tree.html'),
        apiCheck: function (APICheck) {
          return {
            templateOptions: {
              folders: APICheck.array,
            },
          };
        },
        data: {},
      },
      {
        /* Tree */
        name: 'tree',
        template: require('./templates/formly/tree.html'),
        apiCheck: function (APICheck) {
          return {
            templateOptions: {
              singleSelect: APICheck.bool.optional,
              folders: APICheck.bool.optional,
              collapse: APICheck.bool.optional,
            },
          };
        },
        data: {},
      },
      {
        /* CheckList */
        name: 'checklist',
        template: require('./templates/formly/checklist.html'),
        apiCheck: function (APICheck) {
          return {
            templateOptions: {
              valueOptions: APICheck.array,
            },
          };
        },
        data: {},
      },
      {
        /* Dictionary */
        name: 'dictionary',
        template: require('./templates/formly/dictionary.html'),
        apiCheck: function (APICheck) {
          return {
            templateOptions: {
              valueLabel: APICheck.string.optional,
              keyLabel: APICheck.string.optional,
            },
          };
        },
        data: {},
      },
      {
        /* Dictionary  of Records*/
        name: 'dict-table',
        template: require('./templates/formly/dict-table.html'),
        apiCheck: function (APICheck) {
          return {
            templateOptions: {
              keyLabel: APICheck.string.optional,
              valueLabel: APICheck.string.optional,
              valueOptions: APICheck.arrayOf(APICheck.object).optional,
              valueType: APICheck.object.optional,
            },
          };
        },
        data: {},
      },
      {
        /* Document Ref/Upload */
        name: 'document-reference',
        template: require('./templates/formly/document-file.html'),
        apiCheck: function (APICheck) {
          return {
            templateOptions: {
              apiResource: APICheck.string,
            },
          };
        },
        data: {},
      },
      {
        /* PDF file upload */
        name: 'pdf-file',
        template: require('./templates/formly/pdf-file.html'),
        apiCheck: function (APICheck) {
          return {
            templateOptions: {
              apiResource: APICheck.string,
            },
          };
        },
        data: {},
      },
      {
        name: 'section-header',
        template: require('./templates/formly/section-header.html'),
      },
    ]);

    /** Formly Wrappers **/
    formlyConfigProvider.setWrapper([
      {
        name: 'default',
        template: require('./templates/formly-wrappers/default.html'),
        apiCheck: function (APICheck) {
          return {
            templateOptions: {
              label: APICheck.string.optional,
              help: APICheck.string.optional,
            },
          };
        },
      },
      {
        name: 'default-inner',
        template: require('./templates/formly-wrappers/default-inner.html'),
        apiCheck: function (APICheck) {
          return {
            templateOptions: {
              required: APICheck.bool.optional,
            },
          };
        },
        types: [
          'read-only',
          'address',
          'stock-ticker',
          'text',
          'enum-dropdown',
          'dynamic-enum-dropdown',
          'enum-radios',
          'reference-radios',
          'stakeholder',
          'email-textline',
          'require-verification',
          'string-textline',
          'department',
          'measurement-textline',
          'string-typeahead-textline',
          'object-typeahead-textline',
          'number-textline',
          'bool-radios',
          'bool-toggle',
          'date',
          'time',
          'exp-date',
          'document-reference',
          'dictionary',
          'list',
          'checklist',
          'list-of-stakeholder',
          'list-of-document',
          'list-of-profile',
          'profile',
          'record-table',
          'dict-table',
          'stripe-element',
          'pdf-file',
          'section-header',
        ],
      },
      {
        name: 'record-inner',
        template: require('./templates/formly-wrappers/record-inner.html'),
        types: ['record'],
      },
      {
        name: 'checkbox-inner',
        template: require('./templates/formly-wrappers/checkbox-inner.html'),
        types: ['bool-checkbox'],
      },
    ]);

    /** Formly Extras **/
    formlyConfigProvider.extras.fieldTransform = [fieldTransformation];
  },
]; // end FormlyConfig

export default angular.module('sb.lib.form.formlyConfig', [Formly]).config(FormlyConfig)
  .name;

// To avoid using having to reindent fieldTransformation in a closure
// we just use this as a module constant rather than a true injection (in
// this code).
export const TextModelOptions = Object.freeze({
  updateOn: 'default blur',
  allowInvalid: true,
  debounce: Object.freeze({ default: 500, blur: 0 }),
});
function fieldTransformation(fields, model, options) {
  const optionsData = (options && options.data) || {};
  const { uploadUrl } = optionsData;
  const prefix = optionsData.prefix || '';
  angular.forEach(fields, (field) => {
    if (!field.id) {
      field.id = prefix + field.key;
    }
    field.ngModelAttrs = {};
    field.templateOptions.showErrorTooltip = true;
    if (field.templateOptions.override) {
      field.templateOptions.override = new OverrideControl(field, model);
    }
    // ==> Lists
    const { valueType: listValueConfig } = field.templateOptions;
    if (field.type === 'list' && listValueConfig && !field.templateOptions.readOnly) {
      if (listValueConfig.type === 'stakeholder') {
        field.type = 'list-of-stakeholder';
      } else if (listValueConfig.type === 'document-reference') {
        field.type = 'list-of-document';
      } else if (listValueConfig.type === 'profile') {
        field.type = 'list-of-profile';
      } else if (listValueConfig.type === 'pdf-file') {
        field.templateOptions.listClass = 'list-of-upload';
      } else if (listValueConfig.type === 'record') {
        field.type = 'record-table';
        // Parent model to allow form context of subforms
        // to access fields of the parent form
        field.additionalContext = model;
      }
      return fieldTransformation([field.templateOptions.valueType], model, options).map(
        (f) => {
          f.templateOptions.showErrorTooltip = false;
          return f;
        },
      );
    }
    // ==> Dictionary
    if (field.type === 'dictionary') {
      const { templateOptions } = field;
      const { valueType } = templateOptions;
      if (valueType.type === 'record' && valueType.templateOptions.summaryFields) {
        field.type = 'dict-table';
        return fieldTransformation([field.templateOptions.valueType], model, options);
      }
      const dictSubs = [field.templateOptions.valueType].concat(
        templateOptions.keyType ? [templateOptions.keyType] : [],
      );
      return fieldTransformation(dictSubs, model, options);
    }
    // ==> Records
    if (field.type === 'record' && field.templateOptions.valueSchema) {
      return fieldTransformation(field.templateOptions.valueSchema, model, options);
    }
    // ==> All Types
    field.templateOptions.onChange = (value, field, scope, domElement) => {
      if (!scope && domElement) {
        scope = angular.element(domElement).injector().get('$rootScope');
      }

      if (field.data.serverErrors) {
        field.data.serverErrors.splice(0, field.data.serverErrors.length);
      }
      scope.$emit('sbFormField::change', { value, field, scope });
      rtvOnChange(field);
    };

    const { confirmOf } = field.templateOptions;
    if (angular.isDefined(confirmOf)) {
      field.templateOptions.confirmOfWithPrefix = confirmOf.map((i) => prefix + i);
      field.expressionProperties = {
        'templateOptions.confirmOfWithPrefix': 'to.confirmOfWithPrefix',
      };
      field.ngModelAttrs.confirmOfWithPrefix = { bound: 'data-sb-fpv-confirm' };
    }

    if (angular.isDefined(field.templateOptions.disabled)) {
      field.expressionProperties = {
        'templateOptions.disabled': 'to.disabled',
      };
    }

    if (field.type === 'address') {
      // ==> Address
      field.templateOptions.showErrorTooltip = false;
    } else if (
      field.type === 'measurement-textline' &&
      !angular.isNumber(field.templateOptions.precision)
    ) {
      // An integer textline
      field.templateOptions.precision = 0;
    } else if (field.type.indexOf('-textline') >= 0) {
      // ==> Textline Family
      if (!field.templateOptions.type) {
        field.templateOptions.type = 'text';
      }
      if (field.templateOptions.type === 'email') {
        field.templateOptions.emailFPV = '';
        field.ngModelAttrs.emailFPV = { attribute: 'data-sb-fpv-empty-email' };
        field.templateOptions.onFocus = 'field.validation.show = false';
        field.templateOptions.onBlur = 'field.validation.show = true';
      } else if (field.type === 'email-textline') {
        // Email Textline
        field.templateOptions.type = 'email';
        field.templateOptions.emailFPV = '';
        field.ngModelAttrs.emailFPV = { attribute: 'data-sb-fpv-email' };
        field.templateOptions.onFocus = rtvOnFocus(field);
        field.templateOptions.onBlur = rtvOnBlur(field);
      }
    } // end textline family

    if (field.type === 'text' || field.type.indexOf('-textline') >= 0) {
      // ==> Text and Textline Family
      field.templateOptions.onFocus = () => {
        options.data.focused = true;
        rtvOnFocus(field);
      };
      field.templateOptions.onBlur = () => {
        options.data.focused = false;
        rtvOnBlur(field);
      };
      if (!field.modelOptions) {
        field.modelOptions = TextModelOptions;
      }
    }
    if (
      field.hideExpression &&
      typeof field.hideExpression !== 'function' &&
      field.hideExpression.indexOf('itemIsIn') >= 0
    ) {
      field.data.itemIsIn = options.data.arrayIsIn;
    }
    if (field.type === 'document-reference') {
      const apiUrl = field.templateOptions.apiResource || uploadUrl + '/' + field.key;
      field.templateOptions.apiResource = apiUrl;
      if (!field.templateOptions.required) {
        field.templateOptions.showErrorTooltip = false;
      }
    }
    if (field.type.indexOf('enum-') === 0) {
      /**
       * We want a select to have real values as the <option value="">
       * so that tests can continue to use element.select().
       * To achieve this, we do this tranform on options and use this
       * new templateOptions.viewOptions in ng-options.
       */
      const fo = { values: [], labels: {}, descriptionTexts: {} };
      angular.forEach(field.templateOptions.enumVocab, (option) => {
        fo.values.push(option.value);
        fo.labels[option.value] = option.label;
        if (option.descriptionText) {
          fo.descriptionTexts[option.value] = option.descriptionText;
        }
      });
      field.templateOptions.viewOptions = fo;
    }

    if (field.templateOptions.readOnly && field.type !== 'bool-checkbox') {
      field.templateOptions.originalType = field.type;
      field.type = 'read-only';
      field.templateOptions.showErrorTooltip = false;
    }
    if (!field.templateOptions.label || field.type === 'stripe-element') {
      field.templateOptions.showErrorTooltip = false;
    }

    if (field.type === 'enum-dropdown') {
      field.templateOptions.additionalLabels = {};
      for (const property in field.templateOptions.viewOptions.labels) {
        if (!field.templateOptions.viewOptions.labels[property]) {
          continue;
        }
        const additionalLabels =
          field.templateOptions.viewOptions.labels[property].split('\n');
        if (additionalLabels.length > 1) {
          field.templateOptions.viewOptions.labels[property] = additionalLabels[0];
          additionalLabels.shift();
          field.templateOptions.additionalLabels[property] = additionalLabels;
        }
      }
    }
  }); // end foreach field
  return fields;
}
