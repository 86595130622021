import angular from 'angular';
import { doNotDismissOnNavModalClass } from 'lib/bootstrap/bootstrap';
import { tap, filter } from 'rxjs';

export const ProcessModel = [
  '$http',
  '$q',
  '$timeout',
  '$window',
  '$rootScope',
  'BackendLocation',
  'SbxWs',
  'ProcessUrlInfo',
  'ProcessStatus',
  'ProcessButtonModel',
  'SbxUserNotifications',
  'AsyncTasks',
  'ProcessModalUrlService',
  function (
    $http,
    $q,
    $timeout,
    $window,
    $rootScope,
    BackendLocation,
    SbxWs,
    ProcessUrlInfo,
    ProcessStatus,
    ProcessButtonModel,
    SbxUserNotifications,
    AsyncTasks,
    ProcessModalUrlService,
  ) {
    const NO_ACTIVITY_ERROR =
      'This is no longer an active step in the workflow. ' +
      'Visit your Workflow Status panel for available workflows.';
    const NO_WORKITEM_ERROR =
      'That URL looks incorrect. Please confirm the URL ' +
      'above matches the page you are looking for.';
    const GENERIC_PROCESS_ERROR =
      'An error occurred and has been reported to Fidelity Private Shares support.';
    const NETWORK_ERROR =
      'Unable to connect to Fidelity Private Shares. Please check your ' +
      'internet connection. If the problem persists, ' +
      'contact <a href="mailto:support.fps@fmr.com">' +
      'Fidelity Private Shares support</a> (support.fps@fmr.com).';
    const LONG_LOAD_TIME = 3000;
    const obj = {};

    /**
     * @description
     * `$$handleSuccessSocketMessages` resolves during $$handleSuccess
     *
     * (Third party code may throw errors)
     */
    obj.$$handleSuccessSocketMessages = function () {
      const newWi = obj.$wi;
      if (!obj.$$messageKey && newWi.loadingMessageTopic) {
        obj.$$messageKey = newWi.loadingMessageTopic;
        obj.$$messageListener = SbxWs.listenTopic$(obj.$$messageKey)
          .pipe(
            tap((message) => {
              obj.$loadingUpdate = message;
            }),
          )
          .subscribe();
      }

      obj.$$registerFinishedWorkitem(obj.$wi.finishedTopic);
    };

    /**
     * @description
     * `$$handleSuccess` resolves after having processed a successful "WI
     * response."
     *
     * @param {object} response A response object (likely from `$http`).
     */
    obj.$$handleSuccess = function (response) {
      const oldId = obj.$wi && obj.$wi.id;
      const oldC = obj.$wi && obj.$wi.html;
      const newWi = response.data;

      obj.$wi = newWi;
      obj.$lastLoadResult = 200;

      angular.forEach(newWi.backendEndpointUrl, (loc, version) => {
        angular.forEach(loc, (url, ctx) => {
          BackendLocation.updateEndpoint(version, ctx, url);
        });
      });

      if (oldC === newWi.html) {
        obj.$$contentWillNotChange = true; // need to renable buttons
      }

      ProcessButtonModel.$clear();
      // We can set the base url for process url now. When we switch stakeholder
      // we are working on behalf of, we must update the base url, so we can load
      // resources for the current stakeholder.
      ProcessUrlInfo.setBaseUrl(newWi.baseUrl);

      // WI has a status.
      if (newWi && newWi.status) {
        return ProcessStatus.$setStatus(newWi.status.content, newWi.status.type);
      }

      /**
       * If we're explicitly changing workitem, clear the button model. This
       * seems to only be a problem in sync mode, but seems like a good idea
       * regardless.
       */
      if (newWi.id !== oldId) {
        ProcessButtonModel.$clear();
        ProcessButtonModel.disable();
      }

      ProcessStatus.$setStatus();

      SbxUserNotifications.fetch();

      // Handle this at the end, as it may throw errors that
      // will prevent anything after it from being run
      obj.$$handleSuccessSocketMessages();
      return undefined;
    };

    /**
     * @description `$$registerFinishedWorkitem` registers a handler for
     *    finished work item messages and unregisters the old one.
     *
     * @param {topic} The topic of finished work item messages to register to.
     *
     */
    obj.$$registerFinishedWorkitem = (topic) => {
      if (obj.$$finishedWITopic) {
        SbxWs.unregisterTopic(obj.$$finishedWITopic);
      }
      obj.$$finishedWITopic = topic;

      SbxWs.listenTopic$(topic)
        .pipe(filter(() => !obj.$loading))
        .subscribe(() => {
          ProcessModalUrlService.goToUrl(ProcessUrlInfo.nextUrl());
        });
    };

    /**
     * @description
     * `$$handleFailure` rejects after processing a "WI response".
     *
     * @param {object} A response object (likely from `$http`).
     *
     * @returns {promise} This promise rejects with a failure reason
     *    based on response.
     */
    obj.$$handleFailure = function (response, internalError) {
      const data = response.data || {},
        isRedirect = response.status === 478;
      let errorStr = GENERIC_PROCESS_ERROR;
      if (isRedirect && data.redirectUrl !== $window.location.href) {
        // This is a shoobx redirect that's going somewhere other than here.
        // We can keep the buttons disabled. In all other cases, $$handleFailure
        // needs to enable the buttons via `$$contentWillNotChange`.
        obj.$$contentWillNotChange = false;
      } else {
        obj.$$contentWillNotChange = true;
      }
      if (isRedirect) {
        // A "shoobx redirect." See $httpProvider.interceptors in bootstrap.js.
        obj.$lastLoadResult = undefined;
        return $q.reject();
      }
      if (data.reason) {
        errorStr = response.data.reason;
      } else if (data.error) {
        errorStr = response.data.error;
      } else if (response.status === 0 || response.status === -1) {
        errorStr = NETWORK_ERROR;
      } else if (response.status === 404) {
        const entUrl = BackendLocation.entity(1),
          procId = ProcessUrlInfo.processId();
        let foundWorkitem = false;
        return $http({
          method: 'GET',
          url: `${entUrl}process/${procId}/`,
        })
          .then(
            (procResp) => {
              foundWorkitem = [
                {
                  link: `#/${procResp.data.currentWiId}`,
                  workitemName: procResp.data.currentWiTitle,
                },
              ];
              return $q.reject(internalError);
            },
            () => $q.reject(internalError),
          )
          .finally(() => {
            obj.$wi = { otherFoundWorkitems: foundWorkitem };
            obj.$lastLoadResult = 404;
          });
      } else if (response.status === 403) {
        errorStr = undefined; // No status
      } else if (response.status === 410) {
        errorStr = response.data.message;
        obj.$wi = { title: response.data.title };
      }
      obj.$lastLoadResult = response.status;
      return $q.reject(internalError || errorStr);
    };

    obj.$$resetLongLoadTimeout = function () {
      if (obj.$$longLoadPromise) {
        $timeout.cancel(obj.$$longLoadPromise);
        obj.$$longLoadPromise = null;
      }
    };

    obj.$$finishLoad = function () {
      obj.$loadingUpdate = '';
      obj.$loading = false;
      obj.$longLoad = false;
      const enableOff = $rootScope.$on('sbWiHtml::contentLoaded', () => {
        ProcessButtonModel.requestEnable();
        enableOff();
      });
      if (obj.$$contentWillNotChange) {
        $rootScope.$broadcast('sbWiHtml::contentLoaded');
        obj.$$contentWillNotChange = false;
      }
      obj.$$resetLongLoadTimeout();
      if (!obj.$firstLoad) {
        $timeout(() => {
          obj.$firstLoad = true;
        }, 500);
      }
    };

    obj.$$startLoad = function () {
      if (!obj.$loading) {
        // If we're already loading, don't disable again.
        ProcessButtonModel.disable();
      }
      obj.$loading = true;
      obj.$longLoad = false;
      obj.$$resetLongLoadTimeout();
      obj.$$longLoadPromise = $timeout(() => {
        obj.$longLoad = true;
      }, LONG_LOAD_TIME);
    };

    obj.$$internalLoad = function (wiId) {
      obj.$$startLoad();
      return $http
        .get(ProcessUrlInfo.urlByWorkItemId(wiId))
        .then(obj.$$handleSuccess, obj.$$handleFailure)
        .finally(obj.$$finishLoad);
    };

    obj.$$pollTaskById = function (taskId) {
      function cleanup() {
        obj.$alert = null;
      }
      function poll(resolve, reject, i) {
        timeout = false;
        $http.get(ProcessUrlInfo.currentProcessInfoUrlTask(taskId)).then(
          (response) => {
            const data = response.data;
            if (messagedWorkitem) {
              resolve(messagedWorkitem);
            } else if (data.ready !== true) {
              // This is an exponential wait with a ceiling.
              const waitTime = Math.min(15000, 2000 * Math.pow(2, i));
              timeout = $timeout(poll, waitTime, false, resolve, reject, i + 1);
            } else if (data.failed) {
              ProcessStatus.$setStatus(GENERIC_PROCESS_ERROR, 'danger');
              obj.$$contentWillNotChange = true;
              reject(data.result);
              cleanup();
            } else {
              resolve(data.result);
              cleanup();
            }
          },
          (response) => {
            if (messagedWorkitem) {
              resolve(messagedWorkitem);
            } else {
              cleanup();
              obj.$$handleFailure(response).catch(reject);
            }
          },
        );
      }
      let messagedWorkitem, timeout;
      return $q((resolve, reject) => {
        obj.$alert = (msg) => {
          if (timeout) {
            $timeout.cancel(timeout);
            cleanup();
            resolve(msg);
          } else {
            cleanup();
            messagedWorkitem = msg;
          }
        };
        timeout = $timeout(poll, 1000, false, resolve, reject, 0);
      });
    };

    /**
     * @ngdoc property
     * @name $longLoad
     * @propertyOf sb.process.ProcessModel
     *
     * @description
     * Boolean describing if the process has been loading for an "extended"
     * amount of time.
     */
    obj.$longLoad = false;

    /**
     * @ngdoc property
     * @name $firstLoad
     * @propertyOf sb.process.ProcessModel
     *
     * @description
     * Boolean describing if the process has completed its first load or not.
     */
    obj.$firstLoad = false;

    /**
     * @ngdoc property
     * @name $lastLoadResult
     * @propertyOf sb.process.ProcessModel
     *
     * @description
     * Indicates the result of the last load. For simplicity, HTTP codes are used
     * (`404` means not found, `200` means okay, etc). Additionally, a value of
     * `undefined` means model was never loaded.
     */
    obj.$lastLoadResult = undefined;

    /**
     * @ngdoc property
     * @name $loading
     * @propertyOf sb.process.ProcessModel
     *
     * @description
     * Boolean indicating if the process is currently loading.
     */
    obj.$loading = false;

    /**
     * @ngdoc property
     * @name $loadingUpdate
     * @propertyOf sb.process.ProcessModel
     *
     * @description
     * String with content of the latest message update to process loading.
     */
    obj.$loadingUpdate = '';

    /**
     * @ngdoc property
     * @name $wi
     * @propertyOf sb.process.ProcessModel
     *
     * @description
     * The current WI information object. A value of `undefined` signifies
     * no current workitem.
     */
    obj.$wi = undefined;

    /**
     * @ngdoc method
     * @name $load
     * @methodOf sb.process.ProcessModel
     *
     * @description
     * Load the process to a new WI.
     *
     * @param {string} wiId The ID of the WI to load.
     *
     * @returns {promise} Returns a promise that either resolves when the load
     * is complete or rejects with an object with a `.error` string.
     */
    obj.$load = function (wiId) {
      if (!wiId) {
        ProcessStatus.$setStatus(NO_WORKITEM_ERROR, 'danger');
        obj.$$finishLoad();
        return $q.reject({ error: 'No workitem.' });
      }
      if (obj.$wi && obj.$wi.id === wiId) {
        return $q.reject({ error: 'Already loaded this worktiem.' });
      }
      return obj.$$internalLoad(wiId);
    };

    /**
     * @ngdoc method
     * @name $setStakeholder
     * @methodOf sb.process.ProcessModel
     *
     * @description
     * Change the attributes of a sidebar stakeholder.
     *
     * @param {object} newSh The new stakeholder object.
     * @param {object} oldSh Optional. The old stakeholder object.
     *                       Necessary if the id of the newSh has changed
     */
    obj.$setStakeholder = function (newSh, oldSh = null) {
      const workitem = obj.$wi || {};
      const sidebar = workitem.sidebar || {};
      sidebar.stakeholders = (sidebar.stakeholders || []).map((origSh) => {
        let replace = false;
        if ((oldSh && origSh.id === oldSh.id) || (newSh && origSh.id === newSh.id)) {
          replace = true;
        }

        return replace ? newSh : origSh;
      });
    };

    /**
     * @ngdoc method
     * @name $resolveWorkItemId
     * @methodOf sb.process.ProcessModel
     *
     * @description
     *
     * Find applicable workitem id for the user, given activity id. This requires
     * roundtrip to server.
     *
     * @param  {string} activityId Activity id in the form
     *     "<process_id>-<activity_nr>""
     * @return {promise} Promise to applicable workitem id
     */
    obj.$resolveWorkItemId = function (activityId) {
      const apiUrl = BackendLocation.entity(1) + `activities/${activityId}/workitem-id`;
      obj.$loading = true;
      return $http({ url: apiUrl })
        .then(
          (resp) => resp.data.nextWorkItemId,
          ({ status }) => {
            if (status === 404) {
              ProcessStatus.$setStatus(NO_ACTIVITY_ERROR, 'danger');
            } else {
              ProcessStatus.$setStatus(GENERIC_PROCESS_ERROR, 'danger');
            }
            return $q.reject();
          },
        )
        .finally(() => {
          obj.$loading = false;
        });
    };

    /**
     * @ngdoc method
     * @name $goto
     * @methodOf sb.process.ProcessModel
     *
     * @description
     * Ask the process to GOTO a particular workitem by step name or URL.
     *
     * @param {string} [stepName=undefined] The workitem's unique step name
     *    (wizard name). If left undefined, it is implied to go to the current
     *    URL's activity (useful for back button).
     *
     * @returns {promise} A promise that resolves with the load of the branched
     *    workitem or rejects with an error object.
     */
    obj.$goto = function (stepName) {
      let data;
      if (angular.isUndefined(stepName)) {
        data = { jumpToId: ProcessUrlInfo.activityId() };
      } else if (obj.$wi && obj.$wi.id === stepName) {
        return $q.reject({ error: 'Already at this workitem.' });
      } else {
        data = { jumpTo: stepName };
      }
      obj.$$startLoad();
      return $http
        .post(ProcessUrlInfo.currentProcessGotoUrl(), data)
        .then((response) => {
          if (!response.data.result && !response.data.taskId) {
            return $q.resolve(obj.$$handleFailure({}, { error: 'No workitem ID.' }));
          }

          if (response.data.result) {
            return $q.resolve(obj.$$internalLoad(response.data.result));
          }

          if (response.data.taskId) {
            const deferred = $q.defer();

            AsyncTasks.resultOfId(response.data.taskId).subscribe((asyncResult) => {
              if (asyncResult.state === 'FAILED') {
                deferred.resolve(obj.$$handleFailure({}, { error: 'No workitem ID.' }));
                return;
              }

              if (asyncResult.state === 'DONE') {
                if (asyncResult.result) {
                  deferred.resolve(obj.$$internalLoad(asyncResult.result));
                  return;
                }

                deferred.resolve(obj.$$handleFailure({}, { error: 'No workitem ID.' }));
              }
            });

            return deferred.promise;
          }
        }, obj.$$handleFailure);
    };

    /**
     * @ngdoc method
     * @name $submit
     * @methodOf sb.process.ProcessModel
     *
     * @description
     * Submit the workitem and attempt to proceed.
     *
     * @param {string} formData A query string of data to submit the process with.
     *
     * @returns {promise} A promise that resolves with a `nextWorkitemId`. If this
     *    is `undefined`, then the process has completed. If this promise has been
     *    rejected, the process either stayed on the same workitem or loaded itself
     *    (likely because of eager mode in tests).
     */
    obj.$submit = function (formData) {
      obj.$$startLoad();
      return (
        $http({
          url: obj.$wi.submitUrl,
          method: 'POST',
          headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
          transformRequest: angular.identity,
          data: formData,
        })
          .then((response) => {
            // We want to keep the polling operation for 2 reasons:
            // * eager operation in tests
            // * so crossbar is not mission critical
            const asyncResult = response.data.asyncResult;
            if (!asyncResult) {
              // No result. Likely this means it did not finish. Just process
              // like a normal result, then reject since there is no nextItem.
              obj.$$handleSuccess(response);
              return $q.reject();
            } else if (asyncResult.result && angular.isDefined(asyncResult.failed)) {
              // This means an eager result with failure.
              return obj.$$handleFailure(asyncResult.result);
            } else if (asyncResult.result) {
              // This means an eager result without failure.
              return asyncResult.result;
            } else if (angular.isDefined(asyncResult.result)) {
              // This means an eager result but no workitem, finish process.
              return $q.resolve(undefined);
            }
            return obj.$$pollTaskById(asyncResult.taskId);
          }, obj.$$handleFailure)
          // Only finish loading if failure, otherwise we expect to continue loading.
          .catch((reason) => {
            obj.$$finishLoad();
            return $q.reject(reason);
          })
          .then((newId) => {
            if (!newId && obj.$$messageListener) {
              // End of process, clean up socket
              SbxWs.unregisterTopic(obj.$$messageKey);
              obj.$$resetLongLoadTimeout();
            }
            return newId;
          })
      );
    };

    /**
     * @ngdoc method
     * @name $newestWorkitemId
     * @methodOf sb.process.ProcessModel
     *
     * @description
     * Retrieve the "newest" workitem ID for the current user.
     *
     * @returns {promise} A promise that resolves with a `nextWorkitemId`.
     */
    obj.$newestWorkitemId = function () {
      const wizard = obj.$wi && obj.$wi.wizard;
      const cached = (wizard || []).find((step) => step.current);
      if (cached) {
        return obj.$resolveWorkItemId(cached.linkId);
      }
      return $http({ url: ProcessUrlInfo.processUrl() }).then(
        (resp) => resp.data.nextWi,
        $q.reject,
      );
    };

    /**
     * @ngdoc method
     * @name $canShowStakeholderInvite
     * @methodOf sb.process.ProcessModel
     *
     * @description
     * Determine whether the given stakeholder can have an invite button in
     * current workitem context.
     *
     * @param {object} stakeholder A stakeholder JSON object.
     *
     * @returns {promise} A promise that resolves with the result of backend
     * invite-ability check.
     */
    obj.$canShowStakeholderInvite = function (stakeholder) {
      const apiUrl =
        BackendLocation.entity(1) +
        `workitems/${obj.$wi.id}/can-show-stakeholder-invite`;
      return $http({
        method: 'GET',
        url: apiUrl,
        params: { stakeholderId: stakeholder.id },
      });
    };

    /**
     * @ngdoc method
     * @name $canShowStakeholdersInvite
     * @methodOf sb.process.ProcessModel
     * @description
     * Determine which of a list of stakeholders can have an invite button in
     * current workitem contex.
     *
     * @param { array } stakeholderIDs An array of stakeholder IDs as strings
     * @returns { promise } A promise that resolves to an object whose stakeholders
     * attribute contains a list of those stakeholder IDs that can be invited
     */
    obj.$canShowStakeholdersInvite = function (stakeholderIDs) {
      const apiURL =
        BackendLocation.entity(1) +
        `workitems/${obj.$wi.id}/can-show-stakeholders-invite`;
      return $http({
        method: 'POST',
        url: apiURL,
        data: { stakeholders: stakeholderIDs },
      });
    };
    return obj;
  },
]; // end ProcessModel

/**
 * @ngdoc controller
 * @name sb.process.controller:ProcessCtrl
 * @requires sb.process.ProcessModel
 * @requires sb.process.ProcessStatus
 * @requires sb.process.ProcessUrlInfo
 *
 * @description
 * Controller for the process view.
 */
export const ProcessCtrl = [
  '$scope',
  '$q',
  '$window',
  '$attrs',
  '$rootScope',
  '$location',
  '$confirm',
  'ProcessStatus',
  '$sbModalStack',
  'ProcessUrlInfo',
  'ProcessModel',
  'BackendLocation',
  'SerializeAndSubmitProcessForm',
  'SbxWs',
  'PromiseErrorCatcher',
  '$processModal',
  'SimpleHTTPWrapper',
  function (
    $scope,
    $q,
    $window,
    $attrs,
    $rootScope,
    $location,
    $confirm,
    ProcessStatus,
    $sbModalStack,
    ProcessUrlInfo,
    ProcessModel,
    BackendLocation,
    SerializeAndSubmitProcessForm,
    SbxWs,
    PromiseErrorCatcher,
    $processModal,
    SimpleHTTPWrapper,
  ) {
    const COMP_WI_WARNING_CONFIRM =
      'You have completed steps after this point in the workflow. Editing this ' +
      'section will require you to re-enter or confirm information in the ' +
      'subsequent sections.  Are you sure you would like to edit this page?';
    const COMP_WI_NOTICE =
      'You are viewing a previously completed section of the workflow.';
    const GOTO_FAILURE = 'Unable to revert process.';
    const COMP_WI_EDIT_BTN = 'Edit This Section';
    const COMP_WI_NAV_BTN = 'Go to Furthest Progress';
    const IS_PARENT_PROCESS = ProcessUrlInfo.isParentProcess();
    const pm = ProcessModel;
    const ps = ProcessStatus;
    let currentlyShowingSubProcess = false;
    let createdSubProcessModal = null;

    function handleNewWorkItemMessage(msg) {
      if (ProcessModel.$alert) {
        ProcessModel.$alert(msg);
      }
    }
    function navigateStep(gotoStep) {
      ProcessModel.$resolveWorkItemId(gotoStep.linkId)
        .then((workItemId) => {
          $location.path(`/${workItemId}`);
        })
        .catch(PromiseErrorCatcher);
    }
    function completedWIAction() {
      const $wi = pm.$wi,
        rejectBtn = $wi.isRoadBlocked ? undefined : COMP_WI_EDIT_BTN,
        action = ps.$setAction(
          COMP_WI_NOTICE,
          COMP_WI_NAV_BTN,
          'info-white',
          rejectBtn,
        );
      return action
        .then(
          () => {
            return pm.$newestWorkitemId().then((newestId) => {
              if (newestId) {
                $location.path(`/${newestId}`);
              } else {
                $window.location = ProcessUrlInfo.processUrl();
              }
              return $q.reject(true);
            }, $q.reject);
          },
          () =>
            $confirm({
              body: COMP_WI_WARNING_CONFIRM,
              alertType: 'warning',
              confirmPromise: () => pm.$goto().catch(() => $q.reject(GOTO_FAILURE)),
            }),
        )
        .catch((reject) => {
          if (!reject || reject === GOTO_FAILURE) {
            return completedWIAction();
          }
        });
    }
    function includesSummary() {
      return pm.$wi && (pm.$wi.wizard || pm.$wi.groups);
    }

    function setError(error) {
      if (angular.isString(error) && pm.$lastLoadResult === 410) {
        return ps.$setAction(error, 'Back to Workspace', 'danger').then(() => {
          $window.location.href = ProcessUrlInfo.dashboardUrl();
          return $q.reject(error);
        });
      } else if (angular.isString(error)) {
        ps.$setStatus(error, pm.$lastLoadResult === 419 ? 'info' : 'danger');
      }
      return $q.reject(error);
    }
    function checkForSub(prom) {
      const subProcId = ProcessUrlInfo.subProcessWiId();
      prom.then(() => {
        if (subProcId && !currentlyShowingSubProcess) {
          // Broadcast new sub process and the createdSubProcessModal,
          // so that manage subprocesses workitem control could track
          // status of this modal correctly and would not need to create
          // it's own.
          $rootScope.$broadcast('ProcessCtrl::newSubProcess', {
            subProcId: subProcId,
            subProcModal: createdSubProcessModal,
          });
        } else if (subProcId && subProcId !== currentlyShowingSubProcess) {
          $rootScope.$broadcast('processIframe::sendMessage', { navTo: subProcId });
          currentlyShowingSubProcess = subProcId;
        } else if (!subProcId) {
          $rootScope.$broadcast('ProcessCtrl::closeSubProcess');
          // The modal listens for this broadcasted message and closes itself,
          // so stop tracking it.
          createdSubProcessModal = null;
        }
      });
    }
    function handleProcessModalProgress(evt, progress) {
      const { subProcId, completed } = progress;
      const parentId = ProcessUrlInfo.wiId();
      if (subProcId && subProcId !== currentlyShowingSubProcess) {
        // Sub-process moved on to a new workitem
        currentlyShowingSubProcess = subProcId;
        $location.path(`/${parentId}/${subProcId}`);
      }
      if (!subProcId) {
        // Subprocess has closed
        currentlyShowingSubProcess = false;
        $location.path(`/${parentId}`);
        createdSubProcessModal = null;
        if (completed) {
          // Subprocess has completed.
          // Invalidate currently loaded parent process workitem, since it's
          // contents will be affected by completion of the sub-process.
          //
          // Clearing the loaded .$wi causes it to reload
          // when $locationChangeSuccess is triggered. If we
          // do not clear .$wi, the loader will see the workitem already
          // loaded and will just keep the old one.
          pm.$wi = undefined;
        }
      }
    }
    function navToMsg(evt) {
      const nav = evt.data && evt.data.navTo;
      if (nav) {
        $scope.$apply(() => {
          // Here we use $location.replace as not to affect history.
          $location.replace();
          $location.path(`/${nav}`);
        });
      }
    }
    function scrollDown() {
      $window.scrollBy(0, $window.innerHeight * 0.6);
    }
    function wizardGoto({ linkId }) {
      const { wizardPosition, wizard } = pm.$wi;
      const isCurrent = linkId === wizard[wizardPosition].linkId;
      if (!linkId || pm.$loading || isCurrent) {
        return;
      }
      pm.$resolveWorkItemId(linkId)
        .then((workItemId) => {
          $location.path(`/${workItemId}`);
        })
        .catch(PromiseErrorCatcher);
    }

    function confirmDiscardOrSave() {
      $window.parent.postMessage(
        {
          action: 'showConfirmDiscardOrSave',
        },
        '*',
      );
    }

    $scope.confirmDiscardOrSave = confirmDiscardOrSave;
    $scope.isBare = $attrs.sbProcessIsBare === 'true';
    $scope.restrictNext = $attrs.sbProcessRestrictNext;
    $scope.pm = pm;
    $scope.ps = ps;
    $scope.scrollDown = scrollDown;
    $scope.includesSummary = includesSummary;
    $scope.wiSubmit = (btnName) =>
      SerializeAndSubmitProcessForm(btnName).catch(PromiseErrorCatcher);
    $scope.navigateStep = navigateStep;
    $scope.closeStatus = ps.$setStatus.bind(ps);
    $scope.wizardGoto = wizardGoto;

    $scope.$on('$locationChangeStart', () => {
      let top = $sbModalStack.getTop();
      // Dismiss until we hit a modal that is exempt.
      while (top && !top.value.modalDomEl.hasClass(doNotDismissOnNavModalClass)) {
        $sbModalStack.dismiss(top.key);
        top = $sbModalStack.getTop();
      }
      $window.scrollTo(0, 0);
    });
    $scope.$on('$locationChangeSuccess', () => {
      const id = ProcessUrlInfo.wiId();
      const wiId = ProcessUrlInfo.subProcessWiId();
      const url = ProcessUrlInfo.processUrl();
      let topicSubscription;

      const abortContextSubprocess = () => {
        return SimpleHTTPWrapper(
          {
            method: 'DELETE',
            url: BackendLocation.context(1) + '/processes/' + wiId.split('-')[0],
          },
          'Could not discard the process.',
        );
      };

      pm.$load(id)
        .then(() => {
          const { newWorkItemTopic, sectionTitle } = pm.$wi;
          topicSubscription = SbxWs.listenTopic$(newWorkItemTopic).subscribe(
            (message) => handleNewWorkItemMessage(message),
          );
          if (sectionTitle) {
            $window.document.title = `${sectionTitle} | Fidelity Private Shares`;
          } else {
            $window.document.title = 'Fidelity Private Shares';
          }
          window.Appcues?.page();

          // Make modal listen to the url
          if (wiId) {
            const directURLProcessModalSvc = {
              $remove: () => {
                return abortContextSubprocess().then(() => {
                  $window.location.replace(url);
                });
              },
              $load: () => {
                // modal closed, nothing to do
              },
            };

            createdSubProcessModal = $processModal(
              $scope,
              {
                id: wiId,
                title: pm.$wi.sectionTitle,
              },
              directURLProcessModalSvc,
            );
          }
        }, setError)
        .finally(() => {
          if (!IS_PARENT_PROCESS && pm.$wi) {
            $window.parent.postMessage(
              {
                action: 'newWorkitem',
                payload: {
                  id: pm.$wi.id,
                },
              },
              '*',
            );
          }
          checkForSub($q.when());

          if (topicSubscription) {
            topicSubscription.unsubscribe();
          }
        })
        .then(() => {
          if (pm.$wi.completed) {
            return completedWIAction();
          }
        })
        .catch(PromiseErrorCatcher);
    });
    if (IS_PARENT_PROCESS) {
      $scope.$on('$processModal::processProgress', handleProcessModalProgress);
      checkForSub(
        $q((res) => {
          const off = $scope.$on('sbWiHtml::contentLoaded', () => {
            res();
            off();
          });
        }),
      );
    } else {
      $window.addEventListener('message', navToMsg, false);
      $scope.$on('$destroy', () => {
        $window.removeEventListener('message', navToMsg);
      });
    }
  },
]; // end ProcessCtrl

export const sbAnchorClick = [
  '$window',
  'ProcessModalUrlService',
  function ($window, ProcessModalUrlService) {
    return {
      restrict: 'A',
      link: function (scope, element, attrs) {
        element.click((event) => {
          if ($window.self !== $window.top && attrs.href) {
            event.preventDefault();
            event.stopPropagation();
            ProcessModalUrlService.postRedirectMainWindow(attrs.href);
          }
        });
      },
    };
  },
];
