import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { SbxAuthService } from '../sbx-auth.service';
import { Observable, Subject, catchError, map, of, take, takeUntil, tap } from 'rxjs';
import {
  AuthIdentifyResponse,
  AuthIdentifyResponseNextEnum,
  AuthSendOTPResponse,
} from '@shoobx/types/webapi-v2';
import { ActivatedRoute, Router } from '@angular/router';
import { SbxUrlService } from '@/core/url/url.service';
import { LoginVisitPurpose } from '../utils/enums/login-visit-purpose';

@Component({
  selector: 'sbx-auth-verification-code',
  templateUrl: './sbx-auth-verification-code.component.html',
  styleUrls: ['./sbx-auth-verification-code.component.scss'],
})
export class SbxAuthVerificationCodeComponent implements OnInit, OnDestroy {
  public codeForm: FormGroup;
  public message: string;
  public loading = false;
  public errorMessage: string;
  public method: string;

  private recoveryRequestCounter = 0;
  private destroy$: Subject<void> = new Subject();

  constructor(
    private readonly fb: FormBuilder,
    private readonly sbxAuthService: SbxAuthService,
    private readonly route: ActivatedRoute,
    protected readonly sbxUrlService: SbxUrlService,
    private readonly router: Router,
  ) {}

  public ngOnInit(): void {
    this.parseQueryParams();
    this.initCodeForm();
    this.sendRecoveryRequest();
  }

  public ngOnDestroy(): void {
    this.errorMessage = null;
    this.destroy$.next();
    this.destroy$.complete();
  }

  public sendRecoveryRequest(): void {
    if (this.recoveryRequestCounter > 0) {
      this.loading = true;
    }
    this.recoveryRequestCounter++;

    this.sbxAuthService
      .sendOTP({
        email: this.sbxAuthService.email,
        method: this.method,
      })
      .pipe(
        take(1),
        map((res: AuthSendOTPResponse) => res.message),
        tap((message: string) => {
          this.message = message;
          this.loading = false;
        }),
        catchError(() => this.handleIdentifyUserError()),
      )
      .subscribe();
  }

  public identifyUser(): void {
    this.loading = true;
    this.sbxAuthService
      .identifyUser({
        email: this.sbxAuthService.email,
        password: null,
        otp: this.codeForm.value.code,
      })
      .pipe(
        take(1),
        tap((res) => this.handleIdentifyUserSuccess(res)),
        catchError(() => this.handleIdentifyUserError()),
      )
      .subscribe();
  }

  private initCodeForm(): void {
    this.codeForm = this.fb.group({
      code: ['', Validators.required],
    });

    this.codeForm.valueChanges.pipe(takeUntil(this.destroy$)).subscribe(() => {
      this.errorMessage = null;
    });
  }

  private parseQueryParams(): void {
    this.route.queryParams
      .pipe(
        take(1),
        map((params) => params.method),
      )
      .subscribe((method: string) => {
        this.method = method;
      });
  }

  private handleIdentifyUserSuccess(response: AuthIdentifyResponse): void {
    if (response.error) {
      this.errorMessage = response.error;
      this.loading = false;
      return;
    }
    this.errorMessage = null;

    if (response.next === AuthIdentifyResponseNextEnum.Signup) {
      this.router.navigate(['/auth/choice'], {
        queryParams: { signupUrl: response.signupUrl, isVerified: true },
      });
    }
    if (response.next === AuthIdentifyResponseNextEnum.Completed) {
      this.router.navigate(['/auth/signup-complete']);
    }
    if (response.next === AuthIdentifyResponseNextEnum.Login) {
      this.router.navigate(['/auth/login'], {
        queryParams: { purpose: LoginVisitPurpose.recognized },
      });
    }
    if (response.next === AuthIdentifyResponseNextEnum.Abort) {
      this.router.navigate(['/auth/login'], {
        queryParams: { purpose: LoginVisitPurpose.abort },
      });
    }
    this.loading = false;
  }

  private handleIdentifyUserError(): Observable<never> {
    window.location.replace(`/spa/error?withoutRedirect=true`);
    return of();
  }
}
