import { SbxUrlService } from '@/core/url/url.service';
import { Component, EventEmitter, Input, Output } from '@angular/core';

export enum AlertType {
  critical = 'critical',
  info = 'info',
  success = 'success',
  warning = 'warning',
}

@Component({
  selector: 'sbx-auth-alert',
  templateUrl: './sbx-auth-alert.component.html',
  styleUrls: ['./sbx-auth-alert.component.scss'],
})
export class SbxAuthAlertComponent {
  @Input()
  type: AlertType = AlertType.info;

  @Output()
  close = new EventEmitter<void>();

  public alertType = AlertType;

  constructor(protected readonly sbxUrlService: SbxUrlService) {}

  public closeAlert(): void {
    this.close.emit();
  }
}
