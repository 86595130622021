import { Component, Inject, OnInit } from '@angular/core';
import { SbxUrlService } from '@/core/url/url.service';
import { ActivatedRoute, Params } from '@angular/router';
import { take, tap } from 'rxjs';

@Component({
  selector: 'sbx-auth-transition-status',
  templateUrl: './sbx-auth-transition-status.component.html',
  styleUrls: ['./sbx-auth-transition-status.component.scss'],
})
export class SbxAuthTransitionStatusComponent implements OnInit {
  public cameFrom: string;

  constructor(
    @Inject(Window) private readonly window: Window,
    protected readonly sbxUrlService: SbxUrlService,
    private readonly route: ActivatedRoute,
  ) {}

  public ngOnInit(): void {
    this.parseQueryParams();
  }

  private parseQueryParams(): void {
    this.route.queryParams
      .pipe(
        take(1),
        tap((params: Params) => {
          this.cameFrom = params?.camefrom;
        }),
      )
      .subscribe();
  }

  navigateToApplication(): void {
    this.window.location.assign(this.cameFrom);
  }
}
