import { Command, Plugin } from '@ckeditor/ckeditor5-core';
import { ButtonView } from '@ckeditor/ckeditor5-ui';
import { Inject, Injectable } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { SbxFormModalService } from '../../../sbx-form-modal/sbx-form-modal.service';
import icon from './theme/icons/bttn-sec-ref.svg';
import { BehaviorSubject, of } from 'rxjs';
import { FormlyTemplateOptions } from '@ngx-formly/core';
import { ISbxFormModalConfig } from '@/shared/sbx-form-modal/sbx-form-modal-config.type';

type RefPillsConfig = {
  references: Array<{
    value;
    ref;
    singleRef;
  }>;
  currentReference;
};

function getClassInstance(services) {
  class AddOrEditReferenceCommand extends Command {
    references: Array<any>;
    // eslint-disable-next-line @typescript-eslint/ban-types
    referenceNumbers: Object;
    // eslint-disable-next-line @typescript-eslint/ban-types
    currentReference: Object;
    // eslint-disable-next-line @typescript-eslint/ban-types
    target: Object;

    constructor(editor) {
      super(editor);
    }

    execute(options) {
      this.target = options.target;
      this.populateForm(
        this.references,
        this.referenceNumbers,
        this.currentReference,
        this.target,
      );
    }

    populateForm(references, referenceNumbers, currentReference, target) {
      const form = new FormGroup({});
      let section = referenceNumbers[currentReference] ? 'current' : 'other';
      let reference = referenceNumbers[currentReference]
        ? currentReference
        : references[0].value;
      let isSingle;
      let applyStyling;
      if (target) {
        section =
          target.getAttribute('data-reference-id') === currentReference
            ? 'current'
            : 'other';
        reference = target.getAttribute('data-reference-id');
        isSingle = target.getAttribute('data-is-single');
        applyStyling = target.getAttribute('data-apply-styling');
      }
      if (isSingle === undefined) {
        isSingle = 'false';
      }
      if (applyStyling === undefined) {
        applyStyling = 'false';
      }
      const model = {
        section: section,
        reference: reference,
        single: isSingle,
        applyStyling: JSON.parse(applyStyling),
      };
      const config: ISbxFormModalConfig = {
        backdropClass: 'custom-modal-backdrop',
        windowClass: 'custom-modal',
        data: {
          title: 'Select Reference',
          form: form,
          model: model,
          formFields: of(
            this.prepareFields(
              model.section,
              model.reference,
              references,
              referenceNumbers,
              currentReference,
            ),
          ),
          okButtonTitle: target ? 'Update' : 'Insert',
        },
      };

      const range = this.editor.model.document.selection.getFirstRange();

      services.sbxFormModalService
        .open(config)
        .result.then((res: any) => this.addReferenceFn(res, currentReference, range));
    }

    prepareRadioGroup(references, ref) {
      return references[ref]
        ? [
            {
              value: 'false',
              label:
                'Full section number' +
                (references[ref] ? ':' : '') +
                ' ' +
                references[ref].fullRef,
            },
            {
              value: 'true',
              label:
                'Final subsection number only' +
                (references[ref] ? ':' : '') +
                ' ' +
                references[ref].singleRef,
            },
          ]
        : [];
    }

    prepareFields(section, ref, defaultSections, references, currentReference) {
      ref = section === 'current' ? currentReference : ref;
      const sectionEnumVocab = references[currentReference]
        ? [
            {
              value: 'current',
              label: 'The current section',
            },
            {
              value: 'other',
              label: 'A different section',
            },
          ]
        : [
            {
              value: 'other',
              label: 'A different section',
            },
          ];

      const fieldGroup = [
        {
          key: 'section',
          data: {},
          type: 'enum-radios',
          templateOptions: {
            required: true,
            label: 'To which section are you referring?',
            enumVocab: sectionEnumVocab,
          },
        },
        {
          key: 'reference',
          data: {},
          type: 'enum-dropdown',
          templateOptions: {
            subfield: 1,
            required: true,
            label: 'Section',
            enumVocab: defaultSections,
          },
          hideExpression: (model) => model.section === 'current',
        },
        {
          key: 'single',
          data: {},
          type: 'enum-radios',
          templateOptions: {
            subfield: 1,
            required: true,
            label: 'Reference number format',
            enumVocab: this.prepareRadioGroup(references, ref),
          },
          expressionProperties: {
            'templateOptions.options': (model) => {
              return this.prepareRadioGroup(
                references,
                model.section === 'current' ? ref : model.reference,
              );
            },
          },
          hideExpression: (model, _, field) =>
            field.fieldGroup &&
            field.fieldGroup[0].templateOptions.options &&
            field.fieldGroup[0].templateOptions.options.length === 0,
        },
        {
          key: 'applyStyling',
          type: 'bool-checkbox',
          defaultValue: false,
          templateOptions: <FormlyTemplateOptions>{
            subfield: 1,
            label: 'Underline section reference in document',
            required: true,
          },
          hideExpression: (model, _, field) =>
            field.fieldGroup &&
            field.fieldGroup[0].templateOptions.options &&
            field.fieldGroup[0].templateOptions.options.length === 0,
        },
      ];

      return fieldGroup;
    }

    addReferenceFn(res, currentReference, range) {
      const editor = this.editor;
      const referenceId =
        res.result.section === 'current' ? currentReference : res.result.reference;
      const isSingle = res.result.single;
      const applyStyling = res.result.applyStyling;
      const reference = this.references.find((r) => r.value === referenceId);
      const innerHTML = isSingle === 'true' ? reference.singleRef : reference.ref;
      const tooltipContent = reference.title;

      editor.model.change((writer) => {
        const refPillElem = writer.createElement('refPills', {
          'data-reference-id': referenceId,
          'data-is-single': isSingle,
          'data-apply-styling': applyStyling,
          innerHTML: innerHTML,
          tooltipContent: tooltipContent,
        });
        writer.setSelection(range);
        editor.model.insertContent(refPillElem);
      });
    }
  }

  return class AddOrEditReference extends Plugin {
    init() {
      const editor = this.editor;
      const cmd = new AddOrEditReferenceCommand(this.editor);
      editor.commands.add('refPills', cmd);

      // Ckeditor config's "get" method returns the deep copy of the config.
      // Only original Behavior Subject emits values.
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      const refPills = editor.config._config.data.refPills as
        | undefined
        | BehaviorSubject<RefPillsConfig>;

      if (refPills) {
        refPills.subscribe((data) => {
          cmd.referenceNumbers = data.references.reduce((acc, ref) => {
            acc[ref.value] = {
              fullRef: ref.ref,
              singleRef: ref.singleRef,
            };
            return acc;
          }, {});
          cmd.references = data.references;
          cmd.currentReference = data.currentReference;
        });
      }
      editor.ui.componentFactory.add('addOrEditReference', (locale) => {
        const view = new ButtonView(locale);
        view.set({
          icon: icon,
        });
        view.iconView.template.attributes.class = ['ck-button-ref'];
        view.on('execute', () => {
          editor.execute('refPills', { target: null });
        });
        return view;
      });
    }
  };
}

@Injectable()
export class SbxReferencePillService {
  constructor(
    @Inject(SbxFormModalService) public sbxFormModalService: SbxFormModalService,
  ) {}

  getReferenceClass() {
    return getClassInstance({ sbxFormModalService: this.sbxFormModalService });
  }
}
