<div class="sbx-auth-password-recovery-page-content">
  <ng-container
    *ngTemplateOutlet="(availableMethods$ | async)?.length > 1 ? recoveryMethod : email"
  ></ng-container>
</div>

<ng-template #email>
  <sbx-auth-email-input></sbx-auth-email-input>
</ng-template>

<ng-template #recoveryMethod>
  <sbx-auth-recovery-method></sbx-auth-recovery-method>
</ng-template>
