<div
  class="sbx-auth-login-page-content"
  [style.visibility]="isContentVisible ? 'visible' : 'hidden'"
>
  <ng-container [ngSwitch]="purpose">
    <p class="sbx-auth-main-title" *ngSwitchCase="loginVisitPurposeEnum.recognized">
      It looks like you already have a Fidelity login
    </p>
    <p class="sbx-auth-main-title" *ngSwitchCase="loginVisitPurposeEnum.newUser">
      Sign up with an existing Fidelity login
    </p>
    <p class="sbx-auth-main-title" *ngSwitchCase="loginVisitPurposeEnum.existing">
      Log in with Fidelity to complete the login transition process
    </p>
    <p class="sbx-auth-main-title" *ngSwitchCase="loginVisitPurposeEnum.abort">
      You've already transitioned that account to a Fidelity login
    </p>
    <p class="sbx-auth-main-title" *ngSwitchDefault>
      Welcome to Fidelity Private Shares
    </p>
  </ng-container>

  <span class="sbx-auth-sub-title" *ngIf="purpose === loginVisitPurposeEnum.recognized"
    >Log in with your Fidelity credentials to continue.</span
  >
  <span class="sbx-auth-sub-title" *ngIf="purpose === loginVisitPurposeEnum.abort"
    >Please log in with Fidelity to access your account</span
  >

  <div class="dom-container" role="main">
    <div id="dom-sronly" aria-live="assertive"></div>
    <div id="dom-overlay"></div>
    <div id="dom-widget"></div>
  </div>

  <a class="sbx-auth-link" (click)="toggleTroubleOverlay()"
    >Having trouble logging in?</a
  >
</div>

<sbx-info-action-popup
  *ngIf="!purpose && isPopupVisible && isContentVisible"
  (action)="startTransition()"
  (close)="hidePopup()"
  title="Shoobx is now Fidelity Private Shares"
  actionButtonTitle="Transition your login"
>
  <p class="content">
    If you haven’t already, you’ll need to transition to a Fidelity login to access your
    account.
  </p>
</sbx-info-action-popup>

<sbx-auth-overlay
  *ngIf="isTroubleOverlayVisible"
  (close)="toggleTroubleOverlay()"
  title="Having trouble logging in?"
>
  <div class="sbx-auth-overlay-content">
    <p class="sbx-auth-sub-title">
      <b>You may need to transition to a Fidelity login</b>
    </p>
    <p class="sbx-auth-text-container">
      Shoobx has been acquired by Fidelity and is now Fidelity Private Shares. If you
      haven’t already, you’ll need to transition to a Fidelity login to access your
      account.
    </p>
    <button class="sbx-auth-primary-button" (click)="startTransition()">
      Transition your login
    </button>
    <p class="sbx-auth-sub-title"><b>Already transitioned your login?</b></p>
    <p class="sbx-auth-text-container">
      If you’ve already transitioned to a Fidelity login, make sure you are logging in
      with your Fidelity username and password.
    </p>
    <a href="{{ resetPasswordUrl }}" class="sbx-auth-link"
      >Reset Fidelity username or password</a
    >
  </div>
</sbx-auth-overlay>

<sbx-auth-alert
  *ngIf="isTransitionAlertVisible"
  [type]="alertTypeEnum.warning"
  (close)="toggleTransitionAlertVisibility()"
>
  <p class="sbx-auth-alert-title">Have you transitioned your login?</p>
  <p class="sbx-auth-alert-text-content">
    Shoobx is now Fidelity Private Shares. If you haven’t already, you’ll need to
    transition to a Fidelity login to access your account.
    <a class="sbx-auth-link" (click)="startTransition()">Transition your login.</a>
  </p>
</sbx-auth-alert>
