import { SbxUrlService } from '@/core/url/url.service';
import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'sbx-info-action-popup',
  templateUrl: './sbx-info-action-popup.component.html',
  styleUrls: ['./sbx-info-action-popup.component.scss'],
})
export class SbxInfoActionPopupComponent {
  @Input()
  title: string;

  @Input()
  actionButtonTitle: string;

  @Output()
  action = new EventEmitter<void>();

  @Output()
  close = new EventEmitter<void>();

  constructor(protected readonly sbxUrlService: SbxUrlService) {}

  public emitAction(): void {
    this.action.emit();
  }

  public closePopup(): void {
    this.close.emit();
  }
}
