import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable, map, take } from 'rxjs';

@Component({
  selector: 'sbx-auth-merge-page',
  templateUrl: './sbx-auth-merge-page.component.html',
  styleUrls: ['./sbx-auth-merge-page.component.scss'],
})
export class SbxAuthMergePageComponent implements OnInit {
  public duplicateHint$: Observable<string>;

  constructor(
    private readonly route: ActivatedRoute,
    private readonly router: Router,
  ) {}

  public ngOnInit(): void {
    this.parseQueryParams();
  }

  public mergeAndContinue(): void {
    this.router.navigate(['/auth/signup-complete'], {
      queryParams: { agreeToMerge: true },
    });
  }

  public cancel(): void {
    this.router.navigate(['/auth/login']);
  }

  private parseQueryParams(): void {
    this.duplicateHint$ = this.route.queryParams.pipe(
      take(1),
      map((params) => String(params.duplicateHint)),
    );
  }
}
