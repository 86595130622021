/**
 * @ngdoc directive
 * @name sb.lib.document.directive:sbDocumentPreview
 * @restrict E
 *
 * @description
 * Shows a PDF preview of a document. Preview will only show up when document
 * has been successfully generated.
 *
 * @element ANY
 * @param {string} documentId ID of a document in current entity.
 * @param {boolean} [hideDownloadButton=false] Toggles display of download button for pdf viewer
 * @param {string} [documentView='content'] The view of a document to show.
 *    View name is added to document url, and is expected to return PDF content.
 * @param {number} [documentSizeOffset=0] Number of pixels *smaller* the under-
 *    lying pdf frame should be.
 *
 * @example
   <!-- This will produce PDF preview widget with contents fetched from
        http://localhost/acme/documents/123/content -->
   <sb-document-preview document-id="'123'" document-view="'content'">
   </sb-document-preview>
 */
export function sbDocumentPreview() {
  return {
    restrict: 'E',
    template: require('./templates/document-preview.html'),
    scope: {
      documentId: '=',
      hideDownloadButton: '<?',
      documentView: '=',
      sizeOffset: '=documentSizeOffset',
      canDownload: '<?',
    },
    controller: [
      '$scope',
      'PromiseErrorCatcher',
      'EntityDocumentsService',
      'AppConfig',
      'AsyncTasks',
      '$q',
      function (
        $scope,
        PromiseErrorCatcher,
        EntityDocumentsService,
        AppConfig,
        AsyncTasks,
        $q,
      ) {
        $scope.ready = false;
        $scope.success = false;
        $scope.failed = false;
        $scope.doc = null;
        $scope.url = null;

        // Set doc view to 'content' if not provided
        if (!$scope.documentView) {
          $scope.documentView = 'content';
        }

        // Finally, fetch the document and wait until it is rendered
        EntityDocumentsService.$getDocument(
          $scope.documentId,
          AppConfig.currentEntity.name,
        )
          .$toPromise()
          .then((doc) => {
            $scope.doc = doc;
            if (doc.canDownloadWatermarked) {
              return $q((resolve) => {
                const endpoint = `${doc.location}watermark-doc`;
                AsyncTasks.startTaskEndpoint(endpoint).subscribe((res) => {
                  if (res.state === 'FAILED') {
                    resolve(false);
                  }

                  if (res.state === 'DONE') {
                    $scope.url = res.result;

                    resolve(true);
                  }
                });
              });
            }

            $scope.url = doc.href[$scope.documentView];

            return EntityDocumentsService.$waitForDocumentToRender(doc).$toPromise();
          })
          .then((done) => {
            if (done) {
              $scope.success = true;
            } else {
              $scope.failed = true;
            }
          })
          .catch(PromiseErrorCatcher)
          .finally(() => {
            $scope.ready = true;
          });
      },
    ], // end controller
  };
} // end sbDocumentPreview
