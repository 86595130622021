import '@fmr-ap152550/dom/lib/dom-preset-react.min.js';
import '@fmr-ap152550/dom/lib/dom-core.min.js';
import '@fmr-ap152550/dom/lib/dom-authenticators.min.js';

import {
  BUConfigType,
  domEventHandler,
  signin,
} from '@fmr-ap152550/dom/lib/dom-signin.min';

import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { LoginVisitPurpose } from '../utils/enums/login-visit-purpose';
import { take } from 'rxjs';
import { SbxAuthService } from '../sbx-auth.service';
import {
  AuthConfigResponse,
  AuthConfigResponseLoginTypeEnum,
  AuthPostLoginResponse,
} from '@shoobx/types/webapi-v2';
import { LegacyLoginVisitPurpose } from '../utils/enums/legacy-login-visit-purpose';
import { AlertType } from '../sbx-auth-alert/sbx-auth-alert.component';
import { SbxZoneService } from '@/core/zone';
import EventEmitter from 'events';

const INCORRECT_CREDENTIALS_TOPIC = 'Incorrect Username Or Password';

@Component({
  selector: 'sbx-auth-login-page',
  templateUrl: './sbx-auth-login-page.component.html',
  styleUrls: ['./sbx-auth-login-page.component.scss'],
})
export class SbxAuthLoginPageComponent implements OnInit, OnDestroy {
  public purpose: LoginVisitPurpose;
  public loginVisitPurposeEnum = LoginVisitPurpose;
  public isPopupVisible = true;
  public isTroubleOverlayVisible = false;
  public resetPasswordUrl: string;
  public alertTypeEnum = AlertType;
  public isTransitionAlertVisible = false;
  public isContentVisible = false;

  private domEventEmitter: EventEmitter;

  constructor(
    @Inject(Window) private readonly window: Window,
    @Inject(ActivatedRoute) private readonly route: ActivatedRoute,
    private readonly sbxAuthService: SbxAuthService,
    private readonly sbxZoneService: SbxZoneService,
    private readonly router: Router,
  ) {}

  public ngOnInit(): void {
    this.registerDomEventHandler();
    this.parseQueryParams();
  }

  public ngOnDestroy(): void {
    this.domEventEmitter.removeAllListeners();
  }

  public startTransition(): void {
    this.navigateWithReload(
      `legacy?purpose=${LegacyLoginVisitPurpose.startTransition}`,
    );
  }

  public hidePopup(): void {
    this.isPopupVisible = false;
  }

  public toggleTroubleOverlay(): void {
    this.isTroubleOverlayVisible = !this.isTroubleOverlayVisible;
  }

  public toggleTransitionAlertVisibility(): void {
    this.isTransitionAlertVisible = !this.isTransitionAlertVisible;
  }

  public parseQueryParams(): void {
    this.purpose = this.route.snapshot.queryParams?.purpose;
    const secToken = this.route.snapshot.queryParams?.sec_token;
    const camefrom = this.route.snapshot.queryParams?.camefrom;
    const authRedUrl = this.route.snapshot.queryParams?.AuthRedUrl;
    this.sbxAuthService
      .loginConfig(secToken, camefrom || this.stripNetLoc(authRedUrl))
      .subscribe({
        next: (response: AuthConfigResponse) => this.handleLoginConfigSuccess(response),
      });
  }

  public checkPurposeAndStartFlow(): void {
    // DEFAULT case
    if (!this.purpose || this.purpose === LoginVisitPurpose.abort) {
      this.sbxAuthService
        .postLogin()
        .pipe(take(1))
        .subscribe({
          next: (response: AuthPostLoginResponse) =>
            this.handlePostLoginSuccess(response),
          error: () => this.handlePostLoginError(),
        });
    }
    // FLOW: Triggered Match (Phone Number in Shoobx)
    // FLOW: Self-identified Match
    // FLOW: New User Registration
    if (
      this.purpose === LoginVisitPurpose.recognized ||
      this.purpose === LoginVisitPurpose.existing ||
      this.purpose === LoginVisitPurpose.newUser
    ) {
      this.navigateWithReload('signup-complete');
    }
  }

  public startCCLoginWidget(config: BUConfigType) {
    // Existence of global onDomFinish function is required by C&C login widget SDK.
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    this.window.onDomFinish = () => {
      this.checkPurposeAndStartFlow();
    };

    // Existence of global onDomCancel function is required by C&C login widget SDK.
    // This was added with DOM 1.8
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    this.window.onDomCancel = (data) => {
      this.navigateWithReload('/spa/auth/login'); // Reload the page to get rid of the widget
    };

    // In order for our browser testng infrastructure work (i.e. waitForPage
    // could detect that page has loaded), we need to run CC widget
    // initialization outside of angular.
    this.sbxZoneService.getZone().runOutsideAngular(() => {
      signin(config);
    });
  }

  private registerDomEventHandler(): void {
    this.domEventEmitter = domEventHandler();
    this.domEventEmitter.on(
      'CCLEventEmitter',
      (data: { event: { topic: string; type: string } }) => {
        // data received - event triggered from DOM
        // FIXME: Seems to be the only way to filter event is by topic.
        // Check if there is more reliable way to do that
        if (data.event.topic === INCORRECT_CREDENTIALS_TOPIC && !this.purpose) {
          this.sbxZoneService.getZone().run(() => {
            this.toggleTransitionAlertVisibility();
          });
        }
      },
    );
  }

  private handlePostLoginSuccess(response: AuthPostLoginResponse): void {
    if (response.conversionRequired) {
      this.navigateWithReload('choice?isUnassociated=true');
    } else {
      // TODO: Use proper service to obtain base url instead of blindly
      // assuming application is running under root url.
      this.window.location.assign(response.camefrom);
    }
  }

  private handlePostLoginError(): void {
    this.navigateWithReload('error');
  }

  private navigateWithReload(url: string): void {
    /* Navigate to other location with a full reload of the app

        We do this to unload the C&C login widget (aka DOM signin widget),
        because otherwise it stays in the document and keeps doing nasty things
        like loading and executing scipts from remote servers, opening
        websockets, etc.
        */
    this.window.location.assign(url);
  }

  private handleLoginConfigSuccess(response: AuthConfigResponse): void {
    if (response.externalLoginUrl) {
      this.navigateWithReload(response.externalLoginUrl);
      return;
    }
    if (response.loginType === AuthConfigResponseLoginTypeEnum.Login) {
      this.resetPasswordUrl = response.ccConfig.identityEndpoint;
      this.startCCLoginWidget(response.ccConfig as unknown as BUConfigType);
      this.isContentVisible = true;
    }
    if (response.loginType === AuthConfigResponseLoginTypeEnum.Signup) {
      this.router.navigate(['/auth/new-user']);
    }
  }

  private stripNetLoc(url?: string): string | null {
    if (!url) {
      return null;
    }

    const urlObj = new URL(url);
    return `${urlObj.pathname}${urlObj.search}`;
  }
}
