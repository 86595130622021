<!-- Optional beta flag for certain workflows -->
<p *ngIf="showBetaImage">
  <img id="beta-image" [src]="sbxUrlService.assetUrl('img/beta.png')" />
</p>
<!-- widthClass lets us do boostrap grid fun if desired - e.g., if we add some
     informational side box to the process intro page. By default, we are just col-lg-10 -->
<div [class]="'row ' + widthClass">
  <fieldset>
    <!-- "This workflow will help you do XYZ in Fidelity Private Shares."
          innerHTML rather than the {{ }} lets us actually interpret
          the text as such and properly display links, e.g.! -->
    <p
      id="workflowDescriptionText"
      class="no-break"
      [innerHTML]="workflowDescriptionText"
    ></p>
    <span
      *ngIf="workflowDescriptionHelpText"
      id="workflowDescriptionHelpText"
      class="field-help sbx-text-muted no-break"
      [attr.data-content]="workflowDescriptionHelpText"
    >
      <i class="fa fa-question-circle"></i>
    </span>

    <!-- There might also be additional information in italics under that. -->
    <p *ngIf="workflowDescriptionExtraText" id="workflowDescriptionExtraText">
      <i [innerHTML]="workflowDescriptionExtraText"></i>
    </p>

    <!-- For cases with a single, big title like "How would you like to proceed? -->
    <div *ngIf="showHowToProceedText" id="howToProceedText" class="emphasis-title">
      {{ howToProceedText }}
    </div>

    <!-- Now we iterate over the big radios (usually there are just two, for
         guided and upload process, or however the form fields are named. -->
    <ng-container id="workflowOptions" *ngFor="let option of options; last as isLast">
      <!-- Other cases, like Add Employee, do the big, grey text as option titles themselves. -->
      <div *ngIf="option.titleText" class="emphasis-title" name="option-title-text">
        {{ option.titleText }}
      </div>

      <!-- The actual radio for a main process option, like "Create Documents"
             or "Upload Documents". The model is the actual form field that it
             is hooked up to. -->
      <sbx-radio
        [name]="formName + '-' + option.formField"
        [value]="option.fieldValue"
        [label]="option.radioText"
        [model]="sbmodel.$formData[formName][option.formField]"
        [disabled]="
          !!option.grayedOutInfoText ||
          option.disabledForSupport ||
          getFormField(option.formField)?.templateOptions.readOnly
        "
        labelClass="process-type-label"
        (modelChange)="sbmodel.$formData[formName][option.formField] = $event"
      >
      </sbx-radio>
      <span *ngIf="option.grayedOutInfoText" name="grayed-out-info-text" class="label">
        {{ option.grayedOutInfoText }}
      </span>
      <!-- Optionally, we can give the radio some additional description text. -->
      <div id="sbx-process-intro-option-details">
        <div
          *ngIf="option.descriptionText"
          class="workitem-details extra-pad"
          name="option-desc-text"
        >
          <p *ngIf="option.descriptionTextBolded">
            <b [innerHTML]="option.descriptionText"></b>
          </p>
          <p
            *ngIf="!option.descriptionTextBolded"
            [innerHTML]="option.descriptionText"
          ></p>
        </div>

        <div class="workitem-details extra-pad">
          <!-- Optionally, the radio can have chlid checkboxes (e.g., include
                 offer letter and include ECA in add employee -->
          <ng-container *ngFor="let subCheckbox of option.subCheckboxes">
            <p>
              <span class="checkbox-wrapper">
                <sbx-checkbox
                  [model]="sbmodel.$formData[formName][subCheckbox.formField]"
                  [readOnly]="
                    sbmodel.$formData[formName][option.formField] !== option.fieldValue
                  "
                  [label]="!!subCheckbox.checkboxText ? subCheckbox.checkboxText : getFormField(subCheckbox.formField).templateOptions.label"
                  (change)="sbmodel.$formData[formName][subCheckbox.formField] = $event"
                  [name]="formName + '-' + subCheckbox.formField"
                >
                </sbx-checkbox>
                <span
                  *ngIf="
                    !!subCheckbox.checkboxHelp ||
                    !!getFormField(subCheckbox.formField).templateOptions.help
                  "
                  class="field-help sbx-text-muted"
                  [attr.data-content]="
                    !!subCheckbox.checkboxHelp
                      ? subCheckbox.checkboxHelp
                      : getFormField(subCheckbox.formField).templateOptions.help
                  "
                >
                  <i class="fa fa-question-circle"></i>
                </span>
              </span>
            </p>
          </ng-container>
          <!-- Optionally, the radio can have chlid radios (e.g., for 83(b),
                 whether to sign electronically or not -->
          <ng-container *ngFor="let subRadio of option.subRadios">
            <p>
              <ng-container *ngFor="let subRadioValue of subRadio.subRadioValues">
                <sbx-radio
                  [model]="sbmodel.$formData[formName][subRadio.formField]"
                  [value]="subRadioValue.value"
                  [label]="subRadioValue.text"
                  [disabled]="
                    sbmodel.$formData[formName][option.formField] !== option.fieldValue
                  "
                  (modelChange)="
                    sbmodel.$formData[formName][subRadio.formField] = $event
                  "
                  [name]="formName + '-' + subRadio.formField"
                >
                </sbx-radio>
              </ng-container>
            </p>
          </ng-container>
          <!-- Instead, it might have descriptive bullet points, which in
                 turn might have informational popovers. -->
          <ul *ngIf="option.descriptionBullets" name="option-description-bullets">
            <li
              *ngFor="let bullet of option.descriptionBullets"
              name="option-description-bullet"
            >
              {{ bullet.text
              }}<span
                *ngIf="bullet.helpText"
                class="field-help sbx-text-muted"
                [attr.data-content]="bullet.helpText"
              >
                <i class="fa fa-question-circle"></i>
              </span>
              <!-- Said bullets may themselves have sub-bullets -->
              <ul *ngIf="bullet.subBullets" name="option-description-sub-bullets">
                <li
                  *ngFor="let subBullet of bullet.subBullets"
                  name="option-description-sub-bullet"
                >
                  {{ subBullet.text
                  }}<span
                    *ngIf="subBullet.helpText"
                    class="field-help sbx-text-muted"
                    [attr.data-content]="subBullet.helpText"
                  >
                    <i class="fa fa-question-circle"></i>
                  </span>
                </li>
              </ul>
            </li>
          </ul>
        </div>
        <!-- end workitem-details -->

        <div
          *ngIf="option.postDescriptionText"
          class="workitem-details extra-pad"
          name="option-post-desc-text"
        >
          <p>{{ option.postDescriptionText }}</p>
        </div>
      </div>
      <!-- end sbx-process-intro-option-details, i think -->

      <hr *ngIf="!isLast" class="or-divider" />
    </ng-container>

    <p>&nbsp;</p>
    <p *ngIf="finalDescriptionText" id="finalDescriptionText">
      <font color="#97aaaa">{{ finalDescriptionText }}</font>
    </p>
  </fieldset>
</div>
