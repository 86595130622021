const BOARD_MINUTES_TEMPLATE_NAME = 'board-minutes';

/**
 * @ngdoc component
 * @name sb.process.component:sbProcessInfo
 *
 * @description
 * Displays process info articles, specified in model.
 *
 * @param {object} [model] process sidebar model.
 */
export const sbProcessInfo = {
  controllerAs: 'vm',
  template: require('./templates/info.html'),
  bindings: {
    model: '<',
  },
};

/**
 * @ngdoc component
 * @name sb.process.component:sbProcessInfoArticle
 *
 * @description
 * This displays a collapsable, process info (proces sidebar/aside) "article" for display.
 *
 * @param {boolean} [initOpenValue=false] The initial value of the open state (truthy means open).
 *
 * @example
   <sb-process-info-article>
     <heading>
       <i class="fa fa-folder"></i>
     </heading>
     <content>
       <ul class="list-unstyled">
         <li ng-repeat="folder in folders">{{ folder.title }}</li>
       </ul>
     </content>
   </sb-process-info-article>
 */
export const sbProcessInfoArticle = {
  controllerAs: 'vm',
  template: require('./templates/info-article.html'),
  transclude: {
    heading: 'heading',
    content: 'content',
  },
  bindings: {
    initOpenValue: '<?',
  },
  controller: [
    function () {
      function $onInit() {
        this.open = Boolean(this.initOpenValue);
      }
      this.$onInit = $onInit.bind(this);
    },
  ],
}; // end sbProcessInfoArticle

/**
 * @ngdoc component
 * @name sb.process.component:sbProcessInfoDocuments
 *
 * @description
 * This displays a `sbProcessInfoArticle` for documents.
 *
 * @param {array<Document>} documents The array of review and edit documents.
 */
export const sbProcessInfoDocuments = {
  controllerAs: 'vm',
  template: require('./templates/info-documents.html'),
  bindings: {
    documents: '<',
  },
  controller: [
    '$q',
    '$scope',
    'PromiseErrorCatcher',
    'BackendLocation',
    'ProcessUrlInfo',
    'ProcessStatus',
    'ReviewAndEditDocumentsModel',
    '$editTermsModal',
    '$selectEditResolutionModal',
    function (
      $q,
      $scope,
      PromiseErrorCatcher,
      BackendLocation,
      ProcessUrlInfo,
      ProcessStatus,
      ReviewAndEditDocumentsModel,
      $editTermsModal,
      $selectEditResolutionModal,
    ) {
      function openDocEditModal(clickedDoc) {
        const { docModel, apiUrl } = this;
        docModel
          .$loadDocumentFully(clickedDoc.get('id'))
          .catch((error) => {
            ProcessStatus.$setStatus(error, 'danger');
            return $q.reject(error);
          })
          .then((loadedDoc) => {
            if (loadedDoc.get('$editableAsResolution')) {
              $selectEditResolutionModal({
                doc: loadedDoc,
                isWorkitem: false,
                hasVoting:
                  loadedDoc.get('templateBaseName') === BOARD_MINUTES_TEMPLATE_NAME,
              });
            } else {
              $editTermsModal(loadedDoc, docModel, apiUrl, null, true);
            }
          })
          .catch(PromiseErrorCatcher);
      }
      function $onInit() {
        this.apiUrl = `${BackendLocation.entity(
          1,
        )}processes/${ProcessUrlInfo.processId()}/review_and_edit/`;
        this.docModel = ReviewAndEditDocumentsModel(this.apiUrl);
        $scope.$watch(
          () => this.documents,
          (nv) => {
            this.docModel.$saveDocs({ documents: nv || [] });
          },
        );
      }
      this.openDocEditModal = openDocEditModal.bind(this);
      this.$onInit = $onInit.bind(this);
    },
  ],
}; // end sbProcessInfoDocuments

/**
 * @ngdoc component
 * @name sb.process.component:sbProcessInfoStakeholders
 *
 * @description
 * This displays a `sbProcessInfoArticle` for stakeholders.
 *
 * @param {Array<stakeholder>} stakeholders An array of stakeholders to list (standard stakeholder jsonify).
 */
export const sbProcessInfoStakeholders = {
  controllerAs: 'vm',
  template: require('./templates/info-stakeholders.html'),
  bindings: {
    stakeholders: '<',
  },
  controller: [
    'PromiseErrorCatcher',
    'ProcessModel',
    '$stakeholderInviteModal',
    function (PromiseErrorCatcher, ProcessModel, $stakeholderInviteModal) {
      function $onInit() {
        /*
        Set info for each stakeholder on whether they can have an invite button
        here.

        We in turn look up the result for each stakeholder that we display,
        with or without an invite button, in the template.
      */
        this.canShowStakeholderInvite = {};

        ProcessModel.$canShowStakeholdersInvite(this.stakeholders.map((el) => el.id))
          .then((canShowStakeholders) => {
            canShowStakeholders.data.stakeholders.forEach((shID) => {
              const stakeholder = this.stakeholders.find((sh) => sh.id === shID);
              if (stakeholder) {
                this.canShowStakeholderInvite[shID] =
                  stakeholder.canBeUpdated && stakeholder.invited;
              }
            });
          })
          .catch(PromiseErrorCatcher);
      }

      function inviteStakeholder(stakeholder) {
        const wiid = ProcessModel.$wi.id;
        $stakeholderInviteModal(stakeholder, wiid)
          .then(({ $wasNew, $stakeholder }) => {
            if ($wasNew) {
              ProcessModel.$setStakeholder($stakeholder);
            }
          })
          .catch(PromiseErrorCatcher);
      }
      this.$onInit = $onInit.bind(this);
      this.inviteStakeholder = inviteStakeholder.bind(this);
    },
  ],
}; // end sbProcessInfoStakeholders
