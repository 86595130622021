import { Plugin } from '@ckeditor/ckeditor5-core';
import CustomParaStyleEditing from './customParaStyleEditing';
import CustomParaStyleUI from './customParaStyleUI';

export default class CustomParaStyle extends Plugin {
  /**
   * @inheritDoc
   */
  static get requires() {
    return [CustomParaStyleEditing, CustomParaStyleUI];
  }
  /**
   * @inheritDoc
   */
  static get pluginName() {
    return 'CustomParaStyle';
  }
}
