import { Component, Inject } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { LoginVisitPurpose } from '../utils/enums/login-visit-purpose';
import { LegacyLoginVisitPurpose } from '../utils/enums/legacy-login-visit-purpose';
import { AlertType } from '../sbx-auth-alert/sbx-auth-alert.component';
import { SbxAuthService } from '../sbx-auth.service';
import { map, take, tap } from 'rxjs';
import { AuthConfigResponse } from '@shoobx/types/webapi-v2';
import { BUConfigType } from '@fmr-ap152550/dom/lib/dom-signin.min';

@Component({
  selector: 'sbx-auth-choice-page',
  templateUrl: './sbx-auth-choice-page.component.html',
  styleUrls: ['./sbx-auth-choice-page.component.scss'],
})
export class SbxAuthChoicePageComponent {
  public signupUrl: string;
  public isPopupVisible = true;
  public isUnassociated: boolean;
  public isVerifiedAlertVisible = true;
  public alertTypeEnum = AlertType;
  public resetPasswordUrl: string;

  constructor(
    private readonly route: ActivatedRoute,
    private readonly router: Router,
    @Inject(Window) private readonly window: Window,
    private readonly sbxAuthService: SbxAuthService,
  ) {}

  public ngOnInit(): void {
    this.parseQueryParams();
    this.getLoginConfig();
  }

  public login(): void {
    this.router.navigate(['/auth/login'], {
      queryParams: { purpose: LoginVisitPurpose.existing },
    });
  }

  public backToLogin(): void {
    this.router.navigate(['/auth/login']);
  }

  public signup(): void {
    this.window.location.assign(this.signupUrl);
  }

  public hidePopup(): void {
    this.isPopupVisible = false;
  }

  public navigateToPasswordRecovery(): void {
    this.window.location.assign(this.resetPasswordUrl);
  }

  public completeTransition(): void {
    this.router.navigate(['/auth/legacy'], {
      queryParams: { purpose: LegacyLoginVisitPurpose.completeTransition },
    });
  }

  public toggleVerifiedAlertVisibility(): void {
    this.isVerifiedAlertVisible = !this.isVerifiedAlertVisible;
  }

  private parseQueryParams(): void {
    this.route.queryParams.pipe(take(1)).subscribe((queryParams: Params) => {
      this.signupUrl = queryParams.signupUrl;
      this.isUnassociated = queryParams.isUnassociated === 'true';
      this.isVerifiedAlertVisible = queryParams.isVerified === 'true';
    });
  }

  private getLoginConfig(): void {
    this.sbxAuthService
      .loginConfig()
      .pipe(
        take(1),
        map(
          (response: AuthConfigResponse) =>
            response.ccConfig as unknown as BUConfigType,
        ),
        tap((config: BUConfigType) => {
          this.resetPasswordUrl = config.identityEndpoint;
        }),
      )
      .subscribe();
  }
}
