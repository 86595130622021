import { Component, Inject, OnInit } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import {
  AuthConvertRequest,
  AuthConvertResponse,
  AuthConvertResponseNextEnum,
} from '@shoobx/types/webapi-v2';
import { concatMap, map, take } from 'rxjs';
import { SbxAuthService } from '../sbx-auth.service';
import { LoginVisitPurpose } from '../utils/enums/login-visit-purpose';

@Component({
  selector: 'sbx-auth-signup-complete-page',
  templateUrl: './sbx-auth-signup-complete-page.component.html',
  styleUrls: ['./sbx-auth-signup-complete-page.component.scss'],
})
export class SbxAuthSignupCompletePageComponent implements OnInit {
  constructor(
    private readonly sbxAuthService: SbxAuthService,
    private readonly router: Router,
    @Inject(Window) private readonly window: Window,
    private readonly route: ActivatedRoute,
  ) {}

  public ngOnInit(): void {
    this.convertUser();
  }

  private convertUser(): void {
    this.route.queryParams
      .pipe(
        take(1),
        map((queryParams: Params) => this.parseQueryParams(queryParams)),
        concatMap((params: AuthConvertRequest) => this.sbxAuthService.convert(params)),
      )
      .subscribe({
        next: (response: AuthConvertResponse) =>
          this.handleConvertUserSuccess(response),
        error: () => this.handleConvertUserError(),
      });
  }

  private handleConvertUserSuccess(response: AuthConvertResponse): void {
    if (response.next === AuthConvertResponseNextEnum.Completed) {
      this.window.location.assign(response.camefrom);
    }
    if (response.next === AuthConvertResponseNextEnum.Converted) {
      this.router.navigate(['/auth/transition-status'], {
        queryParams: { camefrom: response.camefrom },
      });
    }
    if (response.next === AuthConvertResponseNextEnum.Consent) {
      this.router.navigate(['/auth/agreement']);
    }
    if (response.next === AuthConvertResponseNextEnum.Login) {
      this.router.navigate(['/auth/login'], {
        queryParams: { purpose: LoginVisitPurpose.recognized },
      });
    }
    if (response.next === AuthConvertResponseNextEnum.Merge) {
      const duplicateHint = response.duplicateHint;
      this.router.navigate(['/auth/merge'], { queryParams: { duplicateHint } });
    }
  }

  private handleConvertUserError(): void {
    window.location.replace(`/spa/error?withoutRedirect=true`);
  }

  private parseQueryParams(queryParams: Params): AuthConvertRequest {
    if (queryParams?.agreeToMerge) {
      return { agreeToMerge: Boolean(queryParams?.agreeToMerge) };
    }
    if (queryParams?.agreeToTerms) {
      return { agreeToTerms: Boolean(queryParams?.agreeToTerms) };
    }
    return {};
  }
}
