import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { SbxInfoActionPopupComponent } from './sbx-info-action-popup/sbx-info-action-popup.component';
import { SbxButtonModule } from '@/shared/sbx-button/sbx-button.module';
import { SbxIconModule } from '@/shared/sbx-icon/sbx-icon.module';
import { SbxAuthLegacyLoginPageComponent } from './sbx-auth-legacy-login-page/sbx-auth-legacy-login-page.component';
import { SbxAuthTransitionStatusComponent } from './sbx-auth-transition-status/sbx-auth-transition-status.component';
import { SbxAuthChoicePageComponent } from './sbx-auth-choice-page/sbx-auth-choice-page.component';
import { SbxAuthService } from './sbx-auth.service';
import { SbxAuthLoginPageComponent } from './sbx-auth-login-page/sbx-auth-login-page.component';
import { SbxAuthSignupCompletePageComponent } from './sbx-auth-signup-complete-page/sbx-auth-signup-complete-page.component';
import { SbxAuthMergePageComponent } from './sbx-auth-merge-page/sbx-auth-merge-page.component';

import { SbxAuthPasswordRecoveryPageComponent } from './sbx-auth-password-recovery-page/sbx-auth-password-recovery-page.component';
import { SbxAuthRecoveryMethodComponent } from './sbx-auth-password-recovery-page/sbx-auth-recovery-method/sbx-auth-recovery-method.component';
import { SbxAuthVerificationCodeComponent } from './sbx-auth-verification-code/sbx-auth-verification-code.component';
import { SbxAuthEmailInputComponent } from './sbx-auth-password-recovery-page/sbx-auth-email-input/sbx-auth-email-input.component';
import { SbxAuthNewUserPageComponent } from './sbx-auth-new-user-page/sbx-auth-new-user-page.component';
import { SbxAuthAgreementPageComponent } from './sbx-auth-agreement-page/sbx-auth-agreement-page.component';
import { SbxAuthOverlayComponent } from './sbx-auth-overlay/sbx-auth-overlay.component';
import { SbxAuthAlertComponent } from './sbx-auth-alert/sbx-auth-alert.component';

@NgModule({
  imports: [
    RouterModule,
    CommonModule,
    ReactiveFormsModule,
    SbxButtonModule,
    SbxIconModule,
  ],
  declarations: [
    SbxInfoActionPopupComponent,
    SbxAuthLegacyLoginPageComponent,
    SbxAuthTransitionStatusComponent,
    SbxAuthLoginPageComponent,
    SbxAuthChoicePageComponent,
    SbxAuthSignupCompletePageComponent,
    SbxAuthMergePageComponent,
    SbxAuthPasswordRecoveryPageComponent,
    SbxAuthRecoveryMethodComponent,
    SbxAuthVerificationCodeComponent,
    SbxAuthEmailInputComponent,
    SbxAuthNewUserPageComponent,
    SbxAuthAgreementPageComponent,
    SbxAuthOverlayComponent,
    SbxAuthAlertComponent,
  ],
  providers: [SbxAuthService],
})
export class SbxAuthModule {}
