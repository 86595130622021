<img
  [src]="sbxUrlService.assetUrl('img/fidelity/svg/transition-successful.svg')"
  alt="transition-successful"
/>
<p class="sbx-auth-main-title">You've transitioned to a Fidelity login</p>
<button
  class="sbx-auth-primary-button"
  type="primary"
  (click)="navigateToApplication()"
>
  Continue
</button>
