import { Injectable, Inject } from '@angular/core';
import { SbxHttpClient } from '@/core/http';
import {
  Captable,
  CaptableChildren,
  CaptableOptions,
  DownloadResponse,
} from '@shoobx/types/webapi-v2';
import { AsyncTasks } from '../../js/lib/tasks/service';

@Injectable({ providedIn: 'root' })
export class SbxCaptableService {
  CAPTABLE_LIMIT = 50;

  constructor(
    @Inject(SbxHttpClient) public sbxHttpClient: SbxHttpClient,
    @Inject(AsyncTasks) public asyncTasks,
  ) {}

  fetchCaptable(options: CaptableOptions) {
    return this.sbxHttpClient
      .entity('2')
      .get<Captable>('captable', {
        params: {
          options,
          limit: this.CAPTABLE_LIMIT,
        },
      })
      .toPromise();
  }

  fetchCaptableChildren(
    options: CaptableOptions,
    lineType: string,
    lineId: string,
    offset?: number,
  ) {
    return this.sbxHttpClient
      .entity('2')
      .get<CaptableChildren>('captable/children', {
        params: {
          options,
          lineType,
          lineId,
          offset: offset === undefined ? 0 : offset + this.CAPTABLE_LIMIT,
          limit: this.CAPTABLE_LIMIT,
        },
      })
      .toPromise();
  }

  async export(options: CaptableOptions): Promise<string> {
    const data = await this.sbxHttpClient
      .entity('2')
      .get<DownloadResponse>('captable/export', {
        params: {
          options,
        },
      })
      .toPromise();

    if (data.mode === 'file') {
      return data.file.downloadUrl;
    }
    return new Promise((resolve) =>
      this.asyncTasks.resultOfId(data.taskId).subscribe((res) => {
        if (res.state === 'FAILED') {
          resolve(null);
        }

        if (res.state === 'DONE') {
          resolve(res.result.url);
        }
      }),
    );
  }
}
