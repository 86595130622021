import {
  Component,
  Input,
  Output,
  EventEmitter,
  OnInit,
  OnDestroy,
  Inject,
  ViewChild,
  NgZone,
  ViewEncapsulation,
  SimpleChanges,
  OnChanges,
} from '@angular/core';
import { Downgrade } from '../downgrade';
import { ClassicEditor } from '@ckeditor/ckeditor5-editor-classic';
import { Essentials } from '@ckeditor/ckeditor5-essentials';
import { Paragraph } from '@ckeditor/ckeditor5-paragraph';
import { Bold, Italic, Underline } from '@ckeditor/ckeditor5-basic-styles';
import CustomParaStyle from './plugins/customParaStyle/customParaStyle';
import CustomSpanStyle from './plugins/customSpanStyle/customSpanStyle';
import CustomElement from './plugins/CustomElement/CustomElement';
import { List, ListProperties } from '@ckeditor/ckeditor5-list';
import { SbxTermPillService } from './plugins/AddOrEditTerm/AddOrEditTerm';
import { SbxReferencePillService } from './plugins/AddOrEditReference/AddOrEditReference';
import { SbxExhibitPillService } from './plugins/AddOrEditExhibit/AddOrEditExhibit';
import { SbxInsertClauseService } from './plugins/insertClause/InsertClause';
import { SbxSoftBreakService } from './plugins/SoftBreak/SoftBreak';
import { PasteFromOffice } from '@ckeditor/ckeditor5-paste-from-office';
import { RefPill, AnchorPill, TermPill, StaticPill, ExhibitPill } from './plugins/Pill';
import { SbxModalService } from '../sbx-modal/sbx-modal.service';
import { Observable, isObservable } from 'rxjs';
import { AppConfig } from '@/core/config/app.config';

export enum FeatureTypes {
  InsertClause = 'insertClause',
  TermPills = 'termPills',
  RefPills = 'refPills',
  ExhibitPills = 'exhibitPills',
  SoftBreak = 'softBreak',
  CustomSubElement = 'customSubElement',
  CustomTableElement = 'customTableElement',
  AnchorPills = 'anchorPills',
  StaticPills = 'staticPills',
  ParagraphStyles = 'enableParaStyle',
  SpanStyles = 'enableSpanStyle',
  FontStyles = 'enableFontStyle',
  ListStyles = 'enableListStyle',
}

interface IPlugin {
  [key: string]: unknown;
}

// Plugins that have data source
const PLUGINS = [
  FeatureTypes.InsertClause,
  FeatureTypes.TermPills,
  FeatureTypes.RefPills,
  FeatureTypes.ExhibitPills,
];

const SELECTOR = 'sbx-editor';
@Downgrade.Component('ngShoobx', SELECTOR)
@Component({
  selector: SELECTOR,
  templateUrl: './sbx-editor.component.html',
  styleUrls: ['sbx-editor.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class SbxEditorComponent implements OnInit, OnChanges, OnDestroy {
  @ViewChild('editor', { static: true }) editor;

  @Input() value = '';
  @Input() readOnly = false;
  @Input() features: { [key in FeatureTypes]?: Observable<IPlugin> | boolean } = {};
  @Output() valueChange: EventEmitter<string> = new EventEmitter<string>();

  editorInstance = null;

  constructor(
    @Inject(AppConfig) private appConfig: AppConfig,
    @Inject(SbxTermPillService) public sbxTermPillService: SbxTermPillService,
    @Inject(SbxReferencePillService)
    public sbxReferencePillService: SbxReferencePillService,
    @Inject(SbxExhibitPillService) public sbxExhibitPillService: SbxExhibitPillService,
    @Inject(SbxInsertClauseService)
    public sbxInsertClauseService: SbxInsertClauseService,
    @Inject(SbxModalService) public sbxModalService: SbxModalService,
    @Inject(SbxSoftBreakService) public sbxSoftBreakService: SbxSoftBreakService,
    @Inject(NgZone) public ngZone: NgZone,
  ) {}

  ngOnInit() {
    this.ngZone.runOutsideAngular(async () => {
      const { toolbar, ...config } = this.generateEditorConfig();
      this.editorInstance = await ClassicEditor.create(this.editor.nativeElement, {
        licenseKey: this.appConfig.config.ckeditorLicenseKey,
        initialData: this.value || '',
        toolbar: this.readOnly ? [] : toolbar,
        ...config,
      });

      if (this.isPluginEnabled(FeatureTypes.ListStyles)) {
        this.cleanupListStylesToolbar();
      }

      if (this.readOnly) {
        this.editorInstance.enableReadOnlyMode('');
      } else {
        this.editorInstance.disableReadOnlyMode('');
      }

      function updateEditorContent(editor, formattedData) {
        editor.model.change((writer) => {
          const root = editor.model.document.getRoot();
          writer.setSelection(root, 'in');
          const viewFragment = editor.data.processor.toView(formattedData);
          const modelFragment = editor.data.toModel(viewFragment);
          editor.model.deleteContent(editor.model.document.selection);
          editor.model.insertContent(modelFragment);
        });
      }

      this.editorInstance.model.document.on('change:data', () => {
        const data = this.editorInstance.getData();
        // Make data copy to diff with original data after we mutate formattedData
        let formattedData = data;

        // XXX: decouple this by moving to plugin
        if (this.isPluginEnabled(FeatureTypes.ExhibitPills)) {
          formattedData = this.sbxExhibitPillService.formatPill(formattedData);
        }

        // XXX: decouple this by moving to plugin
        if (this.isPluginEnabled(FeatureTypes.InsertClause)) {
          formattedData = this.sbxInsertClauseService.formatClause(formattedData);
        }

        if (formattedData !== data) {
          updateEditorContent(this.editorInstance, formattedData);
        }

        this.valueChange.emit(formattedData);
      });

      this.subscribeToFeaturesDataUpdates();
    });
  }

  ngOnChanges({ value: { previousValue, currentValue } }: SimpleChanges) {
    if (
      previousValue !== currentValue &&
      this.editorInstance &&
      this.editorInstance.getData() !== currentValue
    ) {
      this.editorInstance.setData(currentValue);
    }
  }

  ngOnDestroy() {
    if (this.editorInstance) {
      this.editorInstance.destroy();
    }
  }

  generateEditorConfig() {
    const plugins = [];
    const toolbar = [];
    const customItems = [];
    const config = {
      plugins,
      toolbar,
      data: this.getData(),
      list: undefined,
    };

    // Setup essential plugins and toolbar items
    plugins.push(Essentials, Paragraph, PasteFromOffice);
    toolbar.push('undo', 'redo');

    if (this.isPluginEnabled(FeatureTypes.ParagraphStyles)) {
      plugins.push(CustomParaStyle);
      toolbar.push('|', 'customParaStyle');
    }

    if (this.isPluginEnabled(FeatureTypes.SpanStyles)) {
      plugins.push(CustomSpanStyle);
      toolbar.push('|', 'customSpanStyle');
    }

    if (this.isPluginEnabled(FeatureTypes.FontStyles)) {
      plugins.push(Bold, Italic, Underline);
      toolbar.push('|', 'bold', 'italic', 'underline');
    }

    if (this.isPluginEnabled(FeatureTypes.ListStyles)) {
      plugins.push(List);
      plugins.push(ListProperties);
      toolbar.push('|', 'bulletedList', 'numberedList');
      config.list = {
        properties: {
          styles: {
            useAttribute: true,
          },
          startIndex: true,
        },
      };
    }

    // Add seperator if any of plugins are enabled
    if (
      Object.keys(this.features).some((feature: FeatureTypes) => {
        if (!this.features[feature]) {
          return false;
        }

        return PLUGINS.includes(feature);
      })
    ) {
      toolbar.push('|');
    }

    if (this.isPluginEnabled(FeatureTypes.InsertClause)) {
      plugins.push(this.sbxInsertClauseService.getInsertClauseClass());
      toolbar.push('insertClause');
    }

    if (this.isPluginEnabled(FeatureTypes.TermPills)) {
      plugins.push(TermPill, this.sbxTermPillService.getTermClass());
      // XXX: Disabled until terms improvements are made.
      // See issue #3004 for reasons for disabling
      // toolbar.push('addOrEditTerm');
    }

    if (this.isPluginEnabled(FeatureTypes.RefPills)) {
      plugins.push(RefPill, this.sbxReferencePillService.getReferenceClass());
      toolbar.push('addOrEditReference');
    }

    if (this.isPluginEnabled(FeatureTypes.ExhibitPills)) {
      plugins.push(ExhibitPill, this.sbxExhibitPillService.getExhibitClass());
      toolbar.push('addOrEditExhibit');
    }

    if (this.isPluginEnabled(FeatureTypes.SoftBreak)) {
      plugins.push(this.sbxSoftBreakService.getSoftBreakClass());
    }

    if (this.isPluginEnabled(FeatureTypes.CustomSubElement)) {
      customItems.push({ tag: 'sub', inline: true, editable: true });
    }

    if (this.isPluginEnabled(FeatureTypes.CustomTableElement)) {
      // XXX: section element is needed, because otherwise it will pass down props to table
      customItems.push(
        {
          tag: 'section',
          editable: true,
          attributes: { id: true, class: true },
          schema: {
            isObject: true,
            allowWhere: '$block',
            allowContentOf: '$root',
            allowAttributes: ['id', 'class'],
          },
        },
        {
          tag: 'table',
          editable: true,
          attributes: { id: true, class: true },
          schema: {
            isObject: true,
            allowWhere: '$block',
            allowAttributes: ['id', 'class'],
          },
        },
        {
          tag: 'tbody',
          editable: true,
          schema: {
            allowIn: 'table',
          },
        },
        {
          tag: 'tr',
          editable: true,
          schema: {
            allowIn: 'tbody',
          },
        },
        {
          tag: 'td',
          editable: true,
          schema: {
            allowIn: 'tr',
            allowWhere: '$block',
            allowContentOf: '$root',
          },
        },
      );
    }

    if (this.isPluginEnabled(FeatureTypes.AnchorPills)) {
      plugins.push(AnchorPill);
    }

    if (this.isPluginEnabled(FeatureTypes.StaticPills)) {
      plugins.push(StaticPill);
    }

    if (customItems.length) {
      plugins.push(CustomElement);
      // eslint-disable-next-line dot-notation
      config['CustomElement'] = { items: customItems };
    }

    return config;
  }

  cleanupListStylesToolbar() {
    // Be careful: lower-roman has the character U+2013 "–" in it
    const dropdownItemsToKeep = ['decimal', 'lower–roman', 'lower-latin'];
    const dropdownViews = this.editorInstance.ui.view.toolbar.items.filter((item) => {
      return item.class === 'ck-list-styles-dropdown';
    });
    dropdownViews.forEach((dropdownView) => {
      dropdownView.once('change:isOpen', () => {
        const items = dropdownView.panelView.children.get(0).stylesView.children;
        const itemsToRemove = items.filter((dropdownItem) => {
          return !dropdownItemsToKeep.includes(dropdownItem.tooltip.toLowerCase());
        });
        itemsToRemove.forEach((e) => e.element.remove());

        if (items.length === itemsToRemove.length) {
          dropdownView.buttonView.arrowView.element.remove();
        }
      });
      dropdownView.fire('change:isOpen');
    });
  }

  getData() {
    const data = {};

    PLUGINS.forEach((key) => {
      if (this.isPluginEnabled(key)) {
        data[key] = this.features[key];
      }
    });

    return data;
  }

  subscribeToFeaturesDataUpdates() {
    PLUGINS.forEach((key) => {
      if (this.isPluginEnabled(key) && isObservable(this.features[key])) {
        // XXX: We cast it to Observable because TS compiler doesn't respect isObservable check
        // eslint-disable-next-line no-extra-parens
        (this.features[key] as Observable<IPlugin>).subscribe((data) => {
          this.editorInstance.config.set(key, { data: () => data });
          this.editorInstance.setData(this.editorInstance.getData());
        });
      }
    });
  }

  isPluginEnabled(key: FeatureTypes) {
    return Boolean(this.features[key]);
  }
}
