/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

/** Returns the task ID of the celery task just started */
export interface AsyncTaskResponse {
  /** ID of the task started or null when result is already available (eager mode) */
  taskId?: string | null;
  /** Result of the task when in eager mode or task immediately finished */
  result?: any;
}

/** Returns status and message */
export interface GenericMessageResponse {
  /** Short status info */
  status?: string;
  /** User readable message */
  message?: string;
}

/** use this with httpexceptions.HTTPBadRequest, 400 status */
export interface Error {
  title?: string;
  /** simple message to show to the user */
  message?: string;
  code?: number;
  /** optional, unset on 4xx errors */
  ticket_id?: string;
}

/** Form error response, use it with httpexceptions.HTTPUnprocessableEntity, 422 status */
export interface GenericFormErrors {
  title?: string;
  /** Form field error mapping, special entry __form__! */
  message?: Record<string, string[]>;
  code?: number;
}

/** use this with httpexceptions.HTTPNotFound, 404 status */
export interface GenericNotFoundError {
  title?: string;
  /** Simple message to show to the user. */
  message?: string;
  code?: number;
}

export interface GenericCreateForm {
  /** form fields */
  description: object;
  name: string;
  title: string;
}

export interface GenericViewForm {
  /** form fields */
  description: object;
  /** form data */
  model: object;
  name: string;
  title?: string;
  /** @default null */
  topMessageText?: string | null;
  /** @default null */
  topInfoMessageText?: string | null;
  /** @default null */
  topMessageTemplateContext?:
    | {
        key?: string;
        value?: string;
      }[]
    | null;
  /** @default null */
  bottomMessageText?: string | null;
}

export interface GenericUpdateForm {
  /** form fields */
  description: object;
  /** form data */
  model: object;
  name: string;
  title?: string;
  /** @default null */
  topMessageText?: string | null;
  /** @default null */
  topInfoMessageText?: string | null;
  /** @default null */
  topMessageTemplateContext?:
    | {
        key?: string;
        value?: string;
      }[]
    | null;
  /** @default null */
  bottomMessageText?: string | null;
}

export interface GenericStatusResponse {
  status: string;
}

export interface GenericCreateResponse {
  status: string;
  id: string;
}

export interface GenericDeleteResponse {
  id: string;
  status?: string | null;
  message?: string | null;
}

export interface GenericTable {
  /** if true the first row is a header */
  headerRow?: boolean;
  /** if true the first column is a header */
  headerColumn?: boolean;
  /** if true the last row is a total */
  totalRow?: boolean;
  /** if true the last column is a total */
  totalColumn?: boolean;
  columnTypes?: GenericTableColumnTypesEnum[];
  /** list of rows, including header and total */
  rows?: string[][];
}

export interface PickupFile {
  entityId: string;
  id: string;
  downloadUrl?: string;
  /** @format date-time */
  created?: string;
  /** @format date-time */
  expires?: string;
}

export interface DownloadResponse {
  /** file or async */
  mode?: string;
  file?: PickupFile;
  taskId?: string | null;
}

export interface Entity {
  id: string;
  title: string;
  url?: string;
}

export interface Document {
  entityId: string;
  id: string;
  title: string;
  url?: string;
  downloadUrl?: string;
  status?: string;
  isSigned?: boolean;
  isCertified?: boolean;
  tags?: string[];
  expandedTags?: string[];
  types?: string[];
}

export type MaybeDocument = {
  data?: Document;
} | null;

export interface Stakeholder {
  entityId: string;
  id: string;
  title: string;
  pictureUrl?: string | null;
  ephemeral?: boolean;
  teamStakeholder?: boolean;
  initialized?: boolean;
  invited?: boolean;
  canBeReminded?: boolean;
  canBeInvited?: boolean;
  canBeUpdated?: boolean;
  canBeArchived?: boolean;
  fullName?: string;
  approvalStatus?: string;
  hasInviteEmail?: boolean;
  isHollow?: boolean;
  isGuest?: boolean;
  archived?: boolean;
  initials?: string | null;
}

export interface Process {
  entityId?: string;
  id?: string;
  title?: string;
  isFinished?: boolean;
  /** @format date-time */
  started?: string | null;
  /** @format date-time */
  finished?: string | null;
}

export interface ListingPage {
  /** Default page size, number of items to be displayed on a page */
  size?: number;
  /** Number of items after filtering */
  filteredItemCount?: number;
  /** Number of total items before filtering */
  totalItemCount?: number | null;
  /** Available sort options */
  sorts?: ListingSortFields;
  /** Current sort */
  sort?: string;
  /** Available filters */
  filters?: ListingFilterFields;
  /** Current filter */
  filter?: object;
  /** Available actions */
  actions?: ListingAction[];
}

/** One row of the data table */
export type ListingRow = Record<string, object>;

export interface ListingData {
  /** The current page number */
  page?: number;
  /** Number of items after filtering */
  filteredItemCount?: number;
  /** Number of total items before filtering */
  totalItemCount?: number | null;
  /** The tabular data to display */
  data?: ListingRow[];
  /** Available sort options, because those can change */
  sorts?: ListingSortFields;
  /** Current sort */
  sort?: string;
  /** Optional message to show on page load */
  message?: string;
  /** Available actions */
  actions?: ListingAction[];
}

export interface ListingFilterFieldOption {
  /** Can be any value - string, number, boolean, array or object. */
  value?: any;
  /** Label shown to the user. */
  label?: string;
}

export interface ListingFilterField {
  /** Name / identifier of the field. */
  name?: string;
  /** Label shown to the user. */
  label?: string;
  /** Widget type of the field */
  fieldType?: ListingFilterFieldFieldTypeEnum;
  required?: boolean;
  /** Options of fieldType dropdown, checklist, switchlist */
  options?: ListingFilterFieldOption[] | null;
}

/** Description of filter options */
export interface ListingFilterFields {
  fields?: ListingFilterField[];
}

export interface ListingFilterDataField {
  name?: string;
  value?: string;
}

export interface ListingFilterData {
  fields?: ListingFilterDataField[];
}

export interface ListingSortField {
  value: string;
  /** Label shown to the user. */
  label: string;
}

export interface ListingSortFields {
  fields?: ListingSortField[];
}

export interface ListingAction {
  title?: string;
  link?: string;
  icon?: string;
}

export interface UserIOFormDefinition {
  uploadUrl?: string | null;
  updateUrl?: string | null;
  fields?: {
    data?: any;
    defaultValue?: any;
    templateOptions?: object;
    key?: string;
    type?: string;
  }[];
}

export interface BillingTicket {
  id: string;
  /** formatted completed date */
  completedDate?: string;
  title?: string;
  pending?: boolean;
  completed?: boolean;
  scheduled?: boolean;
  canCancel?: boolean;
  canFullRefund?: boolean;
  canMarkAsPaid?: boolean;
  canMarkAsPaidContinue?: boolean;
  description?: string | null;
  hasInvoice?: boolean;
  dueDate?: string;
  /** formatted money */
  total?: string;
  entityId?: string;
  entityTitle?: string | null;
  procData?: BillingTicketProcInfo;
  orderInfo?: BillingTicketOrderInfo;
}

export interface BillingTicketOrderInfo {
  status?: string;
  refunds?: boolean;
  /** formatted money */
  refundedAmount?: string | null;
  refundReason?: string | null;
}

export interface BillingTicketProcInfoOutstandingItem {
  title?: string;
  fullName?: string;
  email?: string | null;
}

export interface BillingTicketProcInfo {
  processId?: string;
  status?: string;
  /** outstanding workitems with participant info */
  outstanding?: BillingTicketProcInfoOutstandingItem[] | null;
}

/** Since the form is config defined, no chance to define the properties */
export type RefundData = object;

export interface RefundResponse {
  status: string;
  error?: boolean;
  success?: boolean;
  /** form errors */
  formErrors?: object | null;
}

export interface CustomerInvoiceData {
  status?: string;
}

export interface CustomerInvoiceResponse {
  status: string;
  error?: boolean;
  success?: boolean;
  /** form errors */
  formErrors?: object | null;
}

export interface CancelChargeOptions {
  remove: boolean;
  advanceOrderProcess: boolean;
}

export interface CancelChargeResponse {
  status: string;
  error?: boolean;
  success?: boolean;
  /** form errors */
  formErrors?: object | null;
}

export interface ProductInfoParams {
  name?: string;
  data?: {
    product_name?: string;
    deferred?: boolean;
    /** @format decimal */
    price?: number | null;
    /** @format decimal */
    discount?: number | null;
    additionalContext?: object;
    fieldsToReload?: string[];
  };
}

export interface ProductInfoResult {
  data?: {
    title?: string;
    description?: string;
    /** @format decimal */
    price?: number;
  };
}

export interface DownloadInvoiceResponse {
  downloadUrl?: string;
}

export interface DocumentSearchResults {
  results: {
    docId: string;
    title: string;
    /** @format date */
    effectiveDate?: string | null;
    url: string;
  }[];
  total: number;
  /** Set to true if there are more results than returned */
  more: boolean;
}

export interface DocumentCard {
  id: string;
  title: string;
  /** @format date */
  effectiveDate?: string | null;
  type?: DocumentCardTypeEnum;
  displayType?: DocumentCardDisplayTypeEnum;
  canView: boolean;
  shouldDisplayStatus: boolean;
  status?: string | null;
  /** total number of customizations */
  customizations: number;
  prominentMetadata: {
    key: string;
    value: string;
  }[];
}

export interface DocumentReference {
  documentCard: DocumentCard;
  canDownload: boolean;
  canUpload: boolean;
  canManage: boolean;
}

export interface ReferenceGroup {
  groupName: string;
  refs: DocumentReference[];
}

export interface DocumentReferences {
  references: ReferenceGroup[];
  backreferences: ReferenceGroup[];
  canManageReferences?: boolean;
}

export interface CheckpointData {
  reviewers: {
    legal?: {
      entityName?: string;
      reviewers?: Stakeholder[];
    };
    approvers?: Stakeholder[];
    unanimous?: boolean;
  };
  approval_required: boolean;
  should_alert_counsel: boolean;
  has_approvers: boolean;
}

export interface EmployeeSearchResult {
  results: {
    id: string;
    initials: string;
    pictureUrl?: string | null;
    fullName: string;
  }[];
  more: boolean;
  total: number;
}

/** Deliberately less info returned than with StakeholderCard, do not expose all the info to employees */
export type EmployeeCard = {
  id: string;
  fullName: string;
  firstName?: string | null;
  lastName?: string | null;
  email?: string | null;
  initials?: string | null;
  pictureUrl?: string | null;
  position?: string | null;
  startDate?: string | null;
  workPhone?: string | null;
  link?: string | null;
};

export interface GetProfileLogsResponse {
  hasMore?: boolean;
  items?: {
    created?: string;
    creator?: object;
    profileId?: string;
    profilePath?: string;
    topicTitle?: string;
    schemaTitle?: string;
    fieldTitle?: string;
    previousValue?: string | null;
    updatedValue?: string | null;
  }[];
}

export interface SettingsProfileMetadataSchemaDescription {
  id: string;
  title: string;
}

export interface SettingsProfileTypeTopicDescriptionBasic {
  id: string;
  title: string;
}

export interface SettingsProfileTypeTopicDescription {
  id: string;
  title: string;
  infoMessage?: string | null;
  schemas: string[];
}

/** Dict of field_name info where the value is defined */
export interface SettingsProfileDataDefinedBy {
  path?: string[];
  title?: string;
}

export interface SettingsProfileEditForm {
  profile: SettingsProfile;
  topic: SettingsProfileTypeTopicDescription;
  /** List of form information per schema */
  schemas: {
    /** Metadata schema name/id */
    id: string;
    /** Metadata schema title */
    title: string;
    /** Metadata form fields */
    form: object;
    /** Metadata form data */
    data: object;
    /** List of overridden fields */
    overridden: string[];
    /** Dict of field_name info where the value is defined */
    definedBy: SettingsProfileDataDefinedBy;
  }[];
}

export interface LightweightProfileIcon {
  /** one of IFontAwesomeIconTypes */
  icon?: string;
  /** Optional tooltip text, can have HTMLs */
  popup?: string | null;
  /** Optional link */
  link?: string | null;
}

export interface LightweightProfileField {
  title?: string;
  subfield?: number;
  /**
   * Simple= title and string value
   * Table= LightweightProfileTable
   * I guess there might be more to come
   */
  type?: LightweightProfileFieldTypeEnum;
  value?: string | null;
  link?: string | null;
  /** Generic table description with data */
  table?: LightweightProfileTable;
}

export interface LightweightProfileRow {
  values?: (string | null)[];
  canUpdate?: boolean;
  canDelete?: boolean;
}

/** Generic table description with data */
export type LightweightProfileTable = {
  /** if true the first row is a header */
  headerRow?: boolean;
  /** if true the first column is a header */
  headerColumn?: boolean;
  /** if true the last row is a total */
  totalRow?: boolean;
  /** if true the last column is a total */
  totalColumn?: boolean;
  canCreateRow?: boolean;
  createButtonLabel?: string | null;
  showRowActions?: boolean;
  columnTypes?: LightweightProfileTableColumnTypesEnum[];
  /** list of rows, including header and total */
  rows?: LightweightProfileRow[];
};

export interface LightweightProfileSection {
  name?: string;
  title?: string | null;
  subtitle?: string | null;
  description?: string | null;
  /** link to a tab, see Key documents/Stock plan documents */
  descriptionLink?: string | null;
  /**
   * Table= a single table
   * Fields= a bunch of fields
   */
  type?: LightweightProfileSectionTypeEnum;
  /** top level = 0 */
  subsection?: number;
  /** name of the parent section when subsection != 0 */
  parentName?: string | null;
  collapsible?: boolean;
  canDelete?: boolean;
  canUpdate?: boolean;
  /** Generic table description with data */
  table?: LightweightProfileTable;
  fields?: LightweightProfileField[];
}

export interface LightweightProfileTabView {
  title?: string | null;
  titleLink?: string | null;
  /** Show the edit button? Edit button opens edit modal. */
  canUpdate?: boolean;
  /** Show the create section button? */
  canCreateSection?: boolean;
  createSectionButtonTitle?: string | null;
  sections?: LightweightProfileSection[];
}

export interface LightweightProfileTab {
  /** Unique ID of the menu item */
  name?: string;
  title?: string;
}

export interface LightweightProfileTabs {
  items?: LightweightProfileTab[];
}

export interface UploadCreateResponse {
  status: string;
  upload_id: string;
  title: string;
}

export interface RelationshipCard {
  id?: string;
  fullName?: string;
  pictureUrl?: string | null;
  parentTitle?: string | null;
  link?: string;
  representatives?: object[] | null;
}

export interface RelationshipSearchResult {
  relationships?: {
    total?: number;
    /** Set to true if there are more results than returned */
    more?: boolean;
    results?: StakeholderCard[];
  };
}

export type StakeholderCard = {
  id?: string;
  foreignEntityId?: string | null;
  email?: string | null;
  fullName: string;
  firstName?: string | null;
  lastName?: string | null;
  initials?: string | null;
  pictureUrl?: string | null;
  teamStakeholder?: boolean;
  stake?: boolean;
  parentTitle?: string | null;
  parentForeignEntityId?: string | null;
  link?: string | null;
  missingEmail?: boolean;
  ephemeral?: boolean;
  relationType?: string | null;
  contactFirstName?: string | null;
  contactLastName?: string | null;
  contactEmail?: string | null;
  /** Items of StakeholderCard */
  representatives?: object[] | null;
  /** @default false */
  terminated?: boolean | null;
  /**
   * Is specifically granted access temporarily removed?
   * @default false
   */
  suppressed?: boolean | null;
};

export interface StakeholderSearchResult {
  individuals?: {
    total?: number;
    /** Set to true if there are more results than returned */
    more?: boolean;
    results?: StakeholderCard[];
  };
  teams?: {
    total?: number;
    /** Set to true if there are more results than returned */
    more?: boolean;
    results?: StakeholderCard[];
  };
  stakes?: {
    total?: number;
    /** Set to true if there are more results than returned */
    more?: boolean;
    results?: StakeholderCard[];
  };
}

export interface StakeholderObject {
  entity_name?: string | null;
  isFund?: boolean | null;
  fund_name?: string | null;
  first_name: string;
  middle_name?: string | null;
  last_name: string;
  suffix?: string | null;
  email?: string | null;
  sh_type: string;
  merge_to?: string | null;
  /** @default true */
  check_similar?: boolean;
}

export interface StakeholderEditResponse {
  status: string;
  error?: boolean;
  updated?: boolean;
  /** form errors */
  formErrors?: object | null;
}

export interface InviteResponse {
  status: string;
  email_sent: boolean;
}

export interface ActivityLogResponse {
  totalItems: number;
  data: {
    timestamp?: string;
    text?: string;
  }[];
}

export interface StakeholderLockResponse {
  success?: boolean;
}

export interface UserDirectoryStakeholder {
  stakeholder?: StakeholderCard;
  url?: string;
  roleTitles?: string[];
  accountStatus?: string;
  accountStatusExtra?: string;
  designatedAccess?: string[];
  workPhone?: string;
  cellPhone?: string;
  position?: string;
  canEditStakeholderInfo?: boolean;
  canInviteStakeholder?: boolean;
  canEditRoles?: boolean;
  archived?: boolean;
  canBeArchived?: boolean;
  invited?: boolean;
  isInitialized?: boolean;
  hasHoldings?: boolean;
}

export interface SPAconfigResponse {
  appVersion?: string;
  ravenInfo?: RavenInfo;
  entityInfo?: EntityInfo;
  /** EndpointInfo's keyed by name */
  endpointInfos?: EndpointInfos;
  sideMenu?: SideMenuItem[];
  stripePublicAPIKey?: string;
  ckeditorLicenseKey?: string;
  pdftronLicenseKey?: string;
  webSocket?: WebSocketInfo;
}

export interface WebSocketInfo {
  WS_TRANSPORTS?:
    | {
        type?: string;
        url?: string;
      }[]
    | null;
  WS_REALM?: string;
  WS_TICKET?: string | null;
  notificationsTopic?: string | null;
}

export interface RavenInfo {
  userId?: string | null;
  url?: string | null;
  release: string;
}

/** EndpointInfo's keyed by name */
export type EndpointInfos = Record<
  string,
  {
    context?: string;
    entity?: string;
    root?: string;
    onbehalf?: string | null;
  }
>;

export interface EntityInfoResponse {
  appVersion?: string;
  /** EndpointInfo's keyed by name */
  endpointInfos?: EndpointInfos;
  entityInfo?: EntityInfo;
  userInfo?: UserInfoResponse;
  sideMenu?: SideMenuItem[];
  redirectUrl?: string | null;
}

export interface EntityInfo {
  name?: string;
  title?: string;
  url?: string;
  logoUrl?: string | null;
  themeCss?: string | null;
  onboardingMode?: boolean;
  partnerLogoUrl?: string | null;
  paymentTier?: string;
  status?: string;
  createdOnboardingUser?: boolean;
  showZendesk?: boolean;
}

export interface StakeInfo {
  id?: string;
  name?: string;
  title?: string;
  url?: string | null;
  address?: string | null;
  logoUrl?: string | null;
  partnerLogoUrl?: string | null;
  isGuest?: boolean;
}

export interface LensInfo {
  id?: string;
  /** Gotta keep this in sync with shoobx.app.ui.lens @registerLens registrations! */
  name?: LensInfoNameEnum;
  title?: string;
  css?: string;
  onbehalf?: string;
  affiliate?: boolean;
  profile?: UserProfile;
}

export interface UserProfile {
  id?: string;
  firstName?: string;
  fullName?: string;
  stakeholderId?: string;
  login?: string;
  email?: string | null;
  contactEmail?: string | null;
  profileUrl?: string | null;
  pictureUrl?: string | null;
  asEntity?: string | null;
}

export interface UserInfoResponse {
  appVersion?: string;
  ravenInfo?: RavenInfo;
  webSocket?: WebSocketInfo;
  stakes?: StakeInfo[];
  currentStake?: StakeInfo;
  lenses?: LensInfo[];
  currentLens?: string | null;
  profile?: UserProfile;
}

export interface EntityIdNameTitle {
  id?: string;
  name?: string;
  title?: string;
}

export interface UserBareStake {
  entity?: EntityIdNameTitle;
  entityHasLogo?: boolean;
  entityLogoUrl?: string | null;
}

export interface StakesSearchResponse {
  results?: UserBareStake[];
}

export interface UserStake {
  entity?: EntityIdNameTitle;
  entityHasLogo?: boolean;
  entityLogoUrl?: string | null;
  lenses?: LensInfo[];
  notificationsWSh?: NotificationWSh[];
  hasStake?: boolean;
}

export interface NotificationWSh {
  notifications?: Notification[];
  sh?: {
    id?: string;
    fullName?: string;
  };
}

export interface Notification {
  type?: string;
  workitemName?: string;
  processName?: string;
  link?: string;
  title?: string;
  content?: string;
}

export interface LandingPageResponse {
  activeUserStakes?: UserStake[];
  haveInvestments?: boolean;
  hasPIEAccess?: boolean;
  inactiveUserStakes?: UserStake[];
  isInitiated?: boolean;
  noActives?: boolean;
  notBlockedEntsWDriver?: {
    entity?: EntityIdNameTitle;
    procDriver?: {
      fullName?: string;
    };
  }[];
  tombstoneEntsWPid?: {
    entity?: EntityIdNameTitle;
    pid?: string;
  }[];
  user?: {
    fullName?: string;
  };
  hasMore?: boolean;
  hasInactives?: boolean;
  totalActive?: number;
}

export interface GuestsToRemoveRequest {
  /** Remove guest stakes */
  guestsToRemove?: {
    entID?: string;
    pid?: string;
  };
}

export interface SideMenuItem {
  id?: string;
  icon?: string;
  title?: string;
  position?: SideMenuItemPositionEnum;
  linksMenuMode?: SideMenuItemLinksMenuModeEnum;
  sections?: SideMenuItemSection[] | null;
}

export interface SideMenuItemSection {
  title?: string | null;
  items?: SideMenuItemSectionItem[];
}

export interface SideMenuItemSectionItem {
  title?: string;
  link?: string;
  /** @default null */
  target?: string | null;
}

export interface GetDomainFormResponse {
  data?: object;
  actions?: object[];
  description?: UserIOFormDefinition;
}

export interface ActivityLogsResponse {
  items?: ActivityLogs[];
  hasMore?: boolean;
}

export interface ActivityLogs {
  allProcesses?: boolean;
  processNames?: string[];
  schemaName?: string;
  oldValue?: string | null;
  newValue?: string;
  stakeholder?: StakeholderCard;
  timestamp?: string;
  actionTaken?: string;
}

export interface UserAccessLogResponse {
  items?: StakeholderProperties[];
  hasMore?: boolean;
}

export interface StakeholderProperties {
  user?: StakeholderCard;
  accessChange?: string;
  timestamp?: string;
  modifiedBy?: {
    title?: string;
    firstName?: string;
    lastName?: string;
    fullName?: string;
    pictureUrl?: string;
  };
}

export interface BankingPanelData {
  has_banking_info: boolean;
  balance_history: {
    /** @format date */
    date: string;
    amount: number;
  }[];
}

export type BillingPanelData = object;

export interface ChecklistItem {
  /** State of the task */
  state: string;
  /** Link to the corresponding available process */
  link?: string;
  /** Title of the task */
  title: string;
  /** Process definition ID of the corresponding process */
  processDefId: string;
  /** The title(s) of the process(es) that the currently unavailable process is reliant on */
  reliantProcesses?: string[];
  /** The person(s) currently working on the process */
  currentPerformers?: string[];
}

export interface ChecklistPanelData {
  items: ChecklistItem[];
  layout?: ChecklistPanelDataLayoutEnum;
  infoText?: string;
}

export interface CompanyProfilePanelData {
  logoData: {
    hasLogo: boolean;
    logoUrl?: string;
    companyName: string;
  };
  flavor: string;
  menuList?: object[];
}

export interface LightweightProfilePanelData {
  menuList?: object[];
}

export interface OnboardClientData {
  link?: string;
}

export interface ContentPanelData {
  content: string;
}

export interface CounselPartnerPanelData {
  counselLogo?: string;
  counselRelationshipManagementLink?: string;
  contactName?: string;
  contactInitials?: string;
  contactPicture?: string;
  contactEmail?: string;
  adHTML?: string;
  certified?: boolean;
}

export interface CounselPanelData {
  firmName?: string;
  firmLink?: string;
  clientName: string;
  clientNumber?: string;
  menuList?: object[];
}

export interface DashboardPanelAppearance {
  /** Panel name */
  name: string;
  /** Panel frontend component */
  component?: string | null;
  /** Web API endpoint to retrieve panel configuration */
  configEndpoint?: string | null;
  /** Panel width */
  width?: DashboardPanelAppearanceWidthEnum;
  /** weight for panel ordering */
  weight?: number | null;
  title?: string | null;
  link?: string | null;
  linkTarget?: string | null;
  actionGroups?: string[] | null;
}

export interface DashboardPanelConfiguration {
  actionTitle?: string | null;
  actionIcon?: string | null;
  actionGroups?: string[] | null;
  activeProcesses?: number | null;
  /** alert message */
  alert?: {
    alertTitle?: string | null;
    alertType?: string | null;
    alertData?: any[] | null;
    hideByDefault?: boolean | null;
  };
}

export interface DashboardResponse {
  /** Main panels */
  mainPanels?: DashboardPanelAppearance[];
  /** Right column panel */
  rightColumnPanel?: DashboardPanelAppearance;
  /** redirect to URL to A. switch lens if current dashboard has no panels B. there's an unpaid invoice */
  redirectUrl?: string;
  /** The purpose of lensID is to signal the frontend that the lens changed. The frontend has to show the new lens. Returned panels are for this new lens! Lookup the lens title and onbehalf in userInfo/lenses. */
  lensID?: string;
  /** Message to show on top of the form, can be HTML */
  statusMessage?: string;
  /** Message type for above message */
  statusMessageType?: DashboardResponseStatusMessageTypeEnum;
}

export interface MyDocumentsPanelData {
  total?: number;
  link?: string;
}

export type DataRoomPanelData = object;

export interface EmployeeChecklistPanelData {
  steps?: ChecklistItem[];
}

export interface EmployeeDirectoryPanelData {
  manager?: StakeholderCard;
  recentHire?: StakeholderCard;
  birthdays?: (StakeholderCard & {
    birthday?: string;
  })[];
}

export interface FirmFundsPanelData {
  nofunds: boolean;
  funds: {
    entity_title: string;
    members: object[];
  }[];
  flavor?: string;
  menuList?: object[];
}

export interface EquityStructurePanelData {
  captree?: {
    id: string;
    name: string;
    children: {
      id: string;
      name: string;
      type: string;
      color: string;
      children: {
        type: string;
        title: string;
        shares: number;
        cse?: number;
        percentage?: number | null;
        count?: number;
        other?:
          | {
              type: string;
              class?: string;
              amount: number;
            }[]
          | null;
      }[];
    }[];
  };
  funding?: {
    type: string;
    title?: string | null;
    color?: string | null;
    amount: number;
  }[];
}

export interface MyEquityGrantInfo {
  type: string;
  /** Grant date in human-readable format */
  date: string;
  active: boolean;
  /** @format url */
  url?: string;
  total_shares: string;
  vested_shares: string;
  unvested_shares: string;
  vested_options: string;
  unvested_options: string;
  vested_rsu?: string;
  unvested_rsu?: string;
  vested_shares_percent: string;
  unvested_shares_percent: string;
  vested_options_percent: string;
  unvested_options_percent: string;
  vested_rsu_percent?: string;
  unvested_rsu_percent?: string;
}

export interface MyEquityGrantTotals {
  vested_shares: string;
  unvested_shares: string;
  vested_options: string;
  unvested_options: string;
  vested_rsu?: string;
  unvested_rsu?: string;
  vested_shares_percent: string;
  unvested_shares_percent: string;
  vested_options_percent: string;
  unvested_options_percent: string;
  vested_rsu_percent?: string;
  unvested_rsu_percent?: string;
}

export interface MyEquityPanelData {
  numGrants: number;
  grants: MyEquityGrantInfo[];
  totals: MyEquityGrantTotals;
  history: {
    /** @format date */
    date?: string;
    vested_shares: number;
    unvested_shares: number;
    vested_options: number;
    unvested_options: number;
  }[];
}

export interface MyEquitySmallPanelData {
  numGrants: number;
  grants: MyEquityGrantInfo[];
  totals: MyEquityGrantTotals;
}

export interface InvestmentPanelData {
  investments: {
    type: string;
    title: string;
    /** @format date */
    date?: string | null;
    url: string;
  }[];
}

export interface DealRoomData {
  /** Name of person running the EF workflow */
  workflowOwner: string;
  /** ID of EF workflow */
  processId: string;
  investorsData?: {
    investor?: {
      /** Name of investor */
      name?: string;
      /** Have documents been released to investors? */
      released?: boolean;
    };
    /**
     * Amount of investment
     * @format decimal
     */
    amount?: number;
    /** @format decimal */
    postDealOwnership?: number;
    /** Contact information for investor entity */
    contact?: {
      /** Name of contact person */
      name?: string;
      /** Email address for contact person */
      email?: string;
    };
    /** HTML string representing signature block */
    signatureBlock?: string;
  }[];
  conversionsData?: {
    investor?: {
      /** Name of investor */
      name?: string;
      /** Have documents been released to investors? */
      released?: boolean;
    };
    /**
     * Amount of investment
     * @format decimal
     */
    amount?: number;
    /**
     * Interest on conversion
     * @format decimal
     */
    interest?: number;
    contact?: {
      /** Name of contact person */
      name?: string;
      /** Email address for contact person */
      email?: string;
    };
    /** HTML string representing signature block */
    signatureBlock?: string;
  }[];
  docSummary?: {
    num_documents: number;
    num_uploaded: number;
    num_customized_documents: number;
    num_customized_sections: number;
    num_investors: number;
    num_signatures: number;
    /** Optional string containing URL for downloading EF documents */
    downloadAllDocuments?: string;
    docDetails?: {
      document: {
        /** Number of signatures in the document */
        signed?: number;
        /** Number of signature spots in the document */
        signatures?: number;
        /** Has document been released for signing */
        released?: boolean;
        /** Title of document */
        title?: string;
      };
      customizations: {
        /** Was the document body uploaded? */
        uploaded?: boolean;
        /** How many sections were customized? */
        customized?: number;
      };
      download: {
        /** Document ID */
        id?: string;
        /** Are there any customized sections? */
        customized?: boolean;
        /** Allow downloading change history? */
        showChangeHistory?: boolean;
      };
    }[];
  };
  overview?: {
    /**
     * Amount of new investment
     * @format decimal
     */
    newInvestment?: number;
    newStockClass?: {
      /** String representing names of all new stock classes */
      allStockClasses?: string;
      classDetails?: {
        /** Stock class name */
        name: string;
        authorizedShares: number;
        liquidationPreference: {
          class_name?: string;
          liquidation_multiplier?: number;
          liquidation_pref_cap_amount?: number;
          preference?: string;
        };
        dilute: {
          antidilution?: string;
          className?: string;
          dilute_full_rachet_adj_end?: boolean;
          dilute_full_rachet_deemed_min?: number;
          dilute_weighted_scope?: string;
        };
      }[];
    };
    /** @format decimal */
    postMoneyValuation?: number;
    /** @format decimal */
    postMoneySIP?: number;
    boardChanges?: {
      /** Did the board change? */
      boardChanged?: boolean;
      /** New board size */
      boardSize?: number;
      /** How many board seats added/removed? */
      boardSizeChange?: number;
      addedDirectorsNumber?: number;
      addedDirectors?: string[];
      removedDirectorsNumber?: number;
      removedDirectors?: string[];
    };
  };
}

export interface StockCertificate {
  certificateNumberStr?: string;
  certificateNumber?: number;
  certificate?: Document;
  shares?: number;
  /** @format date */
  issuanceDate?: string | null;
}

export interface StockCertificatesPanelData {
  stockCertificates: StockCertificate[];
}

export interface BoardAndStockholderPanelData {
  reportMenuList?: {
    iconName: string;
    linkText: string;
    linkUrl: string;
  }[];
  documentList?: {
    type: string;
    date: string;
    url: string;
    signers: {
      fullName: string;
      hasSigned: boolean;
    }[];
  }[];
}

export interface StockholderPanelData {
  reportMenuList?: {
    iconName: string;
    linkText: string;
    linkUrl: string;
  }[];
  documentList?: {
    type: string;
    date: string;
    url: string;
    signers?: {
      fullName: string;
      hasSigned: boolean;
    }[];
  }[];
}

export interface EmployeeTasksPanelData {
  ticketCount: {
    stakeholder?: StakeholderCard;
    count?: number;
  }[];
  total: number;
}

export interface HumanResourcesPanelData {
  employee_count: number;
  incoming_hires: number;
  open_offers: number;
  headcount_history: {
    /** @format date */
    date: string;
    fulltime: number;
    parttime: number;
    consultant: number;
    ft_added: number;
    pt_added: number;
    co_added: number;
    losses: number;
  }[];
}

export interface HumanResourcesSmallPanelData {
  employees: {
    shId: string;
    name: string;
    email?: string | null;
    position?: string | null;
  }[];
}

export interface FirmPanelData {
  guestName: string;
  hostName: string;
  guestLink?: string;
}

export interface PayrollPanelData {
  /** Link for payroll provider logo */
  providerLogo: string;
  /** Link for provider's public website */
  providerWebsite: string;
  /** Link for payroll provider provider's client login */
  providerLogin: string;
}

export interface GustoPanelData {
  /** Link for payroll provider logo */
  providerLogo: string;
  /** Link for provider's public website */
  providerWebsite: string;
  /** Link for payroll provider provider's client login */
  providerLogin: string;
}

export interface WorkflowToDoItem {
  blocking?: boolean | null;
  title?: string;
  url?: string | null;
  assigneeTitles?: (string | null)[];
  date?: string;
}

/** This is an optimized/skinned down version of ProcessToDoResult To avoid loading/sending any unnecessary object/data to be able to make the right "My tasks" / "Workflows" sidebar See also definitions/ProcessToDoResult */
export interface WorkflowToDo {
  canViewStatus: boolean;
  workitems?: WorkflowToDoItem[] | null;
  title?: string | null;
  involved: boolean;
  blocked: boolean;
  pid: string;
  participants: StakeholderCard[];
  finished: boolean;
  num_participants: number;
  infoAvailable: boolean;
}

export interface ActiveWorkflowsPanelData {
  canStartAction?: boolean;
  canViewAll?: boolean;
  num_my?: number;
  myworkflows?: WorkflowToDo[];
  num_all?: number;
  workflows?: WorkflowToDo[];
}

export interface AdminSettingsPanelData {
  menuItems: {
    iconName: string;
    linkText: string;
    linkUrl: string;
  }[];
}

export interface DirectoryPanelData {
  canCreateUser?: boolean;
  createUserLink?: string;
  menuList?: object[];
  menuMaxLength?: number;
}

export interface MyProfilePanelData {
  avatarUrl?: string | null;
  fullName?: string;
  stakeholderId?: string;
  menuList?: object[];
  menuMaxLength?: number;
}

export interface SupportPanelData {
  menuList?: object[];
  tickets?: SupportTicket[];
}

export interface CompanyRelationshipPanelData {
  activeRelations?: object[];
  menuList?: object[];
  maxMenuLength?: number;
}

export interface OnboardingPanelData {
  enabled: boolean;
  status: string;
  percent: number;
  restartPercent: number;
  processes: OnboardingProcessState[];
  finishProcess: OnboardingProcessState;
}

export interface OnboardingProcessState {
  name?: string;
  id?: string;
  status?: string;
  title?: string;
  info?: string;
  priority?: number;
  icon?: string;
  url?: string;
  doneOnce?: boolean;
  prerequisites?: string[];
  activeEditor?: string;
}

export interface TailoredProcessPanelData {
  processCount?: number;
  processAvailableCount?: number;
  processLink?: string;
}

export interface PdfForm {
  title: string;
  enabled: boolean;
}

export type TextTemplate = object;

/** A dictionary from field name to value. */
export type AcroFormData = object;

export interface PromiseReportItem {
  granteeId?: string;
  granteeName?: string;
  /** @format date */
  date?: string | null;
  /** @format date */
  commencementDate?: string | null;
  documentId?: string;
  documentTitle?: string;
  shares: number;
  grantType?: string | null;
  /** @format string */
  status: PromiseReportItemStatusEnum;
  /** @format date */
  resolutionDate?: string | null;
  resolutionDocumentId?: string | null;
  resolutionDocumentTitle?: string | null;
  resolutionStakeholderId?: string | null;
  resolutionStakeholderName?: string | null;
  data?: PromiseReportItemData;
}

export interface PromiseReportItemData {
  isGranteeTeam?: boolean;
  uploadDate?: string | null;
  uploadedByShId?: string | null;
  uploadedByTitle?: string | null;
  reason?: string | null;
  vestingScheduleId?: string | null;
  vesting?: {
    vestingPeriod?: string;
    vestingFrequency?: string;
    cliff?: string;
    cliffDuration?: string;
    accelerationClause?: string;
    earlyExercise?: string;
  } | null;
}

export interface PromiseReportData {
  total?: number;
  page?: number;
  teamPromisesAllowed?: boolean;
  items: PromiseReportItem[];
}

export interface ExerciseReportItem {
  stakeholder: Stakeholder;
  certNum?: number | null;
  /** @format date */
  date: string;
  document: Document;
  shares: number;
  sharesWithheld?: number;
  grantType?: string | null;
  /** @format decimal */
  pricePerShare?: number | null;
  documentLink?: string | null;
  certificate?: Document;
  certificateLink?: string | null;
  isActive?: string;
  exerciseId?: string;
  /** @format decimal */
  fmvAtExercise?: number | null;
  /** @format decimal */
  fmv3921?: number | null;
  fmv3921str?: string | null;
  /** @format decimal */
  fmvImplied?: number | null;
  fmvImpliedStr?: string | null;
}

export interface ExercisesReportData {
  total: number;
  items: ExerciseReportItem[];
}

export interface ExerciseFMVForm {
  /** @format decimal */
  fmv?: number;
}

export interface RepurchaseReportItem {
  stakeholder: Stakeholder;
  document: Document;
  documentLink?: string | null;
  /** @format date */
  date?: string;
  oldCertNum?: string;
  newCertNum?: string | null;
  /** @format decimal */
  pricePerShare?: number | null;
  shares: number;
  oldCertificateUrl?: string | null;
  newCertificateUrl?: string | null;
}

export interface RepurchasesReportData {
  total: number;
  items: RepurchaseReportItem[];
}

export interface TransferReportItem {
  stakeholder: Stakeholder;
  toStakeholder?: Stakeholder;
  document: Document;
  documentLink?: string | null;
  /** @format date */
  date?: string;
  oldCertNum?: string;
  newCertNum?: string | null;
  shares: number;
  oldCertificateUrl?: string | null;
  newCertificateUrl?: string | null;
  transferType?: string;
}

export interface TransfersReportData {
  total: number;
  items: TransferReportItem[];
}

export interface Track83BReportItem {
  stakeholder: Stakeholder;
  document: Document;
  documentLink?: string | null;
  /** @format date */
  date?: string;
  /** @format date */
  transactionDate?: string | null;
  status: string;
  isInProgress?: boolean | null;
  workitemTitle?: string | null;
  exercised?: number;
  unvested?: number;
  file83bDoc?: Document;
  file83bLink?: string | null;
  signatureType?: string;
}

export interface Track83BReportData {
  total: number;
  items: Track83BReportItem[];
}

export interface ForfeitureReportItem {
  stakeholder: Stakeholder;
  document: Document;
  documentLink?: string | null;
  /** @format date */
  date?: string;
  shares: number;
  grantAcceptanceStatus?: string;
  cancellationType?: string;
}

export interface ForfeituresReportData {
  total: number;
  items: ForfeitureReportItem[];
}

export interface EquityTransactionsTabsConfig {
  /** Flag indicating whether to show exercises tab. */
  exercises?: boolean;
  /** Flag indicating whether to show forfeitures tab. */
  forfeitures?: boolean;
  /** Flag indicating whether to show repurchases tab. */
  repurchases?: boolean;
  /** Flag indicating whether to show track83b tab. */
  track83b?: boolean;
  /** Flag indicating whether to show transfers tab. */
  transfers?: boolean;
}

export interface EquityTransactionsConfigResponse {
  tabsConfig?: EquityTransactionsTabsConfig;
}

export interface YearDetails {
  year: number;
  status: YearDetailsStatusEnum;
  hasNonTimeVesting?: boolean;
  /** @format decimal */
  aggregatedIsoValue?: number | null;
  /** @format decimal */
  availableIso?: number | null;
}

export interface Iso100KReportDetailed {
  years: YearDetails[];
  numIsoGranted: number;
  pendingGrants: number;
  promisedGrants: number;
}

export interface Iso100KReportItem {
  stakeholderId: string;
  fullName: string;
  currentYear?: YearDetails;
  futureYears?: YearDetails[] | null;
  previousYears?: YearDetails[] | null;
}

export interface Iso100KReport {
  /** Employees approaching the 100K limit */
  approaching: number;
  /** Employees that reached the limit */
  reached: number;
  /** ISO security holders */
  isoHolders: number;
  /** Total report entries */
  total: number;
  page: number;
  items: Iso100KReportItem[];
}

export interface EquityLot {
  entityId?: string;
  id?: number;
  title?: string;
  ownerId?: string;
  documentId?: string;
  /** @format date */
  openDt?: string;
  basis?: number;
  basisFmt?: string;
  sharesCommodityId?: string | null;
  moneyCommodityId?: string | null;
  sharesReceived?: number;
}

export interface EquityLotTransaction {
  entityId?: string;
  /** @format date */
  dt?: string;
  description?: string;
  lotId?: number | null;
  /** Equity ledger transaction ID.  Can be null for transactions not originating from ledger (like tax events). */
  txnId?: number | null;
  /** Unique report entry ID for this transaction. */
  reportEntryId?: string;
  ownerId?: string | null;
  operation?: string;
  optionsDelta?: number | null;
  optionsKind?: string | null;
  sharesDelta?: number | null;
  sharesCommodityId?: string | null;
  moneyCommodityId?: string | null;
  moneyDelta?: number | null;
  moneyDeltaFmt?: string;
  cost?: number | null;
  costFmt?: string;
  gain?: number | null;
  gainFmt?: string;
  documentId?: string;
}

export interface EquityTransactionsResult {
  lots?: EquityLot[];
  transactions?: EquityLotTransaction[];
  entities?: Entity[];
  documents?: Document[];
  stakeholders?: Stakeholder[];
  transactionOptions?: {
    entityId?: string;
    reportEntryId?: string;
    /** Whether 83(b) can be filed for this "exercise" transaction */
    can83b?: boolean;
  }[];
  withTaxes?: boolean;
  taxes?: {
    entityId?: string;
    reportEntryId?: string;
    taxes?: HoldingsTaxes;
  }[];
}

export interface HoldingsChart {
  /** Data columns, in format [label, value, value, value...]. */
  columns?: any[][];
  /** Name of the column describing X axis. */
  xAxis?: string;
  /** Groups for data stacking. */
  groups?: string[][];
  /** Labels color map */
  colors?: Record<string, string>;
}

export interface HoldingsPieChart {
  /** Pie slice values, in format [key, value]. */
  values?: {
    key?: string;
    value?: number;
  }[];
  /** Pie color map of key -> color */
  colors?: Record<string, string>;
  /** Pie slice titles of key -> title */
  legend?: Record<string, string>;
}

export type HoldingsTaxes = {
  /** Tax events grouped by document. */
  events?: {
    documentId?: string;
    eventIds?: string[];
    icon?: HoldingsTaxesIconEnum;
  }[];
  /** One of TaxableEventStatus values. */
  status?: string;
  /** @format decimal */
  totalTax?: number | null;
  totalTaxFmt?: string;
};

export interface HoldingsGrantTransaction {
  /** @format date */
  date?: string;
  type?: HoldingsGrantTransactionTypeEnum;
  name?: string;
  description?: string;
  shares?: number | null;
  options?: number | null;
  optionsKind?: string | null;
  /** Shares and options commodity name. */
  commodity?: string | null;
  /** @format decimal */
  money?: number | null;
  /** @format decimal */
  cost?: number | null;
  /** @format decimal */
  gain?: number | null;
  moneyCommodity?: string | null;
  /** Document for this transaction. */
  documentId?: string | null;
  canFile83b?: boolean;
  deadline83bPassed?: boolean;
  /** @format date */
  deadline83b?: string | null;
  certificateDocumentId?: string | null;
  certificateNumber?: number | null;
  transactionDocDeferred?: boolean;
  taxes?: HoldingsTaxes;
}

export interface HoldingsInvestmentTransaction {
  /** @format date */
  date?: string;
  type?: HoldingsInvestmentTransactionTypeEnum;
  name?: string;
  description?: string;
  shares?: {
    stockClass?: string;
    delta?: number;
    certificateDocumentId?: string | null;
    certificateNumber?: number | null;
  }[];
  options?: {
    stockClass?: string;
    delta?: number;
    optionsKind?: string | null;
  }[];
  money?: {
    /** @format decimal */
    delta?: number | null;
    /** @format decimal */
    cost?: number | null;
    /** @format decimal */
    gain?: number | null;
    commodity?: string | null;
  }[];
  /** Document for this transaction. */
  documentId?: string | null;
  transactionDocDeferred?: boolean;
  taxes?: HoldingsTaxes;
}

export interface HoldingsGrantRepricing {
  /** @format date */
  date?: string;
  /** @format decimal */
  perSharePrice?: number;
  approvalDocumentId?: string;
}

export interface HoldingsEquityGrant {
  /** @format date */
  date?: string;
  type?: string;
  active?: boolean;
  status?: HoldingsEquityGrantStatusEnum;
  documentId?: string;
  agreementDocId?: string | null;
  agreementDocType?: string | null;
  stockClass?: string | null;
  /** Shares issued. */
  shares?: number;
  /** Total shares owned. */
  sharesOwned?: number;
  /** @format decimal */
  price?: number;
  /** @format decimal */
  cost?: number;
  vestingCommencement?: string | null;
  vestingFrequency?: string | null;
  vestingPeriod?: string | null;
  vestingCliff?: string | null;
  customVestingText?: string | null;
  hasVestingRestriction?: boolean;
  /** Shares subject to vesting, as specified by grant. */
  sharesSubjectToVesting?: number | null;
  sharesVested?: number;
  sharesUnvested?: number;
  /** sharesVested + sharesUnvested */
  totalVesting?: number;
  sharesForfeited?: number;
  canExercise?: boolean;
  acceptanceInProgress?: boolean;
  exerciseInProgress?: boolean;
  grantSupportsExercise?: boolean;
  isEarlyExerciseAvailable?: boolean;
  sharesExercisable?: number;
  sharesExercised?: number;
  /** sharesExercised + sharesExercisable */
  totalExercising?: number;
  rsuType?: string | null;
  rsuSettled?: number;
  rsuUnsettled?: number;
  settlementType?: string | null;
  rsuTerminationClause?: string | null;
  /** rsuSettled + rsuUnsettled */
  rsuTotal?: number;
  /**
   * Cash amount received from settled RSUs
   * @format decimal
   */
  settledCash?: number | null;
  /**
   * Post Termination Exercise deadline.
   * @format date
   */
  deadlinePTE?: string | null;
  deadlinePTEPassed?: boolean;
  granteeId?: string | null;
  granteeName?: string | null;
  transactions?: HoldingsGrantTransaction[];
  repricings?: HoldingsGrantRepricing[];
}

export interface HoldingsInvestment {
  /** @format date */
  date?: string;
  status?: HoldingsInvestmentStatusEnum;
  type?: HoldingsInvestmentTypeEnum;
  documentId?: string;
  agreementDocId?: string | null;
  agreementDocType?: string | null;
  holdings?: {
    stockClass?: string;
    shares?: number;
  }[];
  /** @format decimal */
  amount?: number;
  stockholderId?: string;
  stockholderName?: string;
  folderURL?: string | null;
  folderId?: string | null;
  transactions?: HoldingsInvestmentTransaction[];
}

export interface HoldingsConvertibleInstrument {
  /** @format date */
  date?: string | null;
  type?: string;
  status?: HoldingsConvertibleInstrumentStatusEnum;
  documentId?: string;
  agreementDocId?: string | null;
  agreementDocType?: string | null;
  isConverted?: boolean;
  /** @format date */
  conversionDate?: string | null;
  /** @format date */
  maturityDate?: string | null;
  /** @format decimal */
  amount?: number;
  discount?: string;
  interest?: string;
  /** @format decimal */
  valuationCap?: number;
  investorId?: string | null;
  investorName?: string;
  folderURL?: string | null;
  folderId?: string | null;
  transactions?: HoldingsInvestmentTransaction[];
}

export interface HoldingsEquityGrants {
  grants?: HoldingsEquityGrant[];
}

/** Vesting schedule.  Contains share or ISO/NQO share split vesting, or RSU vesting/disbursement schedule. */
export interface HoldingsVestingSchedule {
  type?: HoldingsVestingScheduleTypeEnum;
  events?: {
    /** Number of the vesting event. */
    period?: number | null;
    /** @format date */
    date?: string;
    /** Vested shares (ISO shares for ISO-NQO splits, RSU shares for RSUs) */
    shares?: number | null;
    sharesNQO?: number | null;
    sharesDistributed?: string | null;
    runningTotal?: number | null;
    taxes?: HoldingsTaxes;
    status?: HoldingsVestingScheduleStatusEnum;
  }[];
}

export interface HoldingsEquity {
  ownerFullName?: string;
  ownerId?: string;
  chart?: HoldingsChart;
  grants?: HoldingsEquityGrants;
  warrants?: HoldingsEquityGrants;
  investments?: HoldingsInvestments;
  sharesOwned?: number;
  outstandingOptions?: number;
  restrictedStockUnits?: number;
  hasOutstandingPromises?: boolean | null;
  /** HTML for partner branding section */
  branding?: string | null;
  hasUnreleasedGrants?: boolean | null;
  withTaxes?: boolean;
}

export interface HoldingsGrantDetails {
  grant?: HoldingsEquityGrant;
  documents?: DocumentCard[];
  /** Vesting schedule.  Contains share or ISO/NQO share split vesting, or RSU vesting/disbursement schedule. */
  vestingSchedule?: HoldingsVestingSchedule;
  chart?: HoldingsPieChart;
  tabsConfig?: HoldingDetailsTabsConfig;
  withTaxes?: boolean;
}

export interface HoldingsInstrumentDetails {
  instrument?: HoldingsConvertibleInstrument;
  documents?: DocumentCard[];
  tabsConfig?: HoldingDetailsTabsConfig;
  withTaxes?: boolean;
}

export interface HoldingsInvestmentDetails {
  investment?: HoldingsInvestment;
  documents?: DocumentCard[];
  tabsConfig?: HoldingDetailsTabsConfig;
  withTaxes?: boolean;
}

export interface HoldingsInvestments {
  commonShares?: HoldingsInvestment[];
  /** Chart for commonShares */
  commonSharesChart?: HoldingsChart;
  preferredShares?: HoldingsInvestment[];
  convertibleInstruments?: HoldingsConvertibleInstrument[];
  /** Chart for convertibleInstruments and preferredShares */
  investmentsChart?: HoldingsChart;
  /** @format decimal */
  total?: number;
}

export interface HoldingDetailsTabsConfig {
  /** Flag to show overview tab. */
  overview?: boolean;
  /** Flag to show transactions tab. */
  transactions?: boolean;
  /** Flag to show vesting schedule tab. */
  vestingSchedule?: boolean;
  /** Flag to show documents tab. */
  documents?: boolean;
}

export interface EquitySLTCompEntry {
  stakeholderId?: string;
  stakeholderName?: string;
  options?: number;
  rsu?: number;
  sharesHeld?: number;
  sharesUnvested?: number;
  transferred?: number;
  vested?: number;
  unvested?: number;
  cashSettled?: number;
  /** @format decimal */
  ownershipPct?: number | null;
  /** @format decimal */
  vestedNetValue?: number | null;
  /** @format decimal */
  unvestedNetValue?: number | null;
  /** @format decimal */
  optionsNetValue?: number | null;
  /** @format decimal */
  rsuNetValue?: number | null;
  /** @format decimal */
  sharesHeldNetValue?: number | null;
  /** @format decimal */
  sharesUnvestedNetValue?: number | null;
  /** @format decimal */
  transferredNetValue?: number | null;
  /** @format decimal */
  cashSettledNetValue?: number | null;
  vestingIn1?: number;
  vestingIn2?: number;
  vestingIn3?: number;
  vestingIn4?: number;
  vestingIn5?: number;
  /** @format decimal */
  vestingIn1NetValue?: number | null;
  /** @format decimal */
  vestingIn2NetValue?: number | null;
  /** @format decimal */
  vestingIn3NetValue?: number | null;
  /** @format decimal */
  vestingIn4NetValue?: number | null;
  /** @format decimal */
  vestingIn5NetValue?: number | null;
  totalGranted?: number;
  /** @format decimal */
  totalGrantedNetValue?: number | null;
}

export interface EquitySLTCompReport {
  entityId?: string;
  /** @format date */
  date?: string;
  today?: boolean;
  totalCSE?: number | null;
  /** @format decimal */
  basis?: number | null;
  items?: EquitySLTCompEntry[];
  totalItems?: number;
}

export interface CertificateAcceptance {
  relevant?: boolean;
  processId?: string | null;
  accepted?: boolean;
  /** @format date */
  acceptedOn?: string | null;
}

export interface StockLedgerItem {
  certificateNumberStr?: string;
  certificateNumber?: number | null;
  certificateId?: string;
  certificateUrl?: string;
  stakeholderTitle?: string;
  shares?: number;
  issuanceBasis?: string;
  status?: string;
  /** @format date */
  issuanceDate?: string | null;
  references?: number;
  missing?: boolean;
  paper?: boolean;
  acceptance?: CertificateAcceptance;
  active?: boolean;
  managed?: boolean;
}

export interface StockLedgerData {
  total: number;
  canEdit: boolean;
  items: StockLedgerItem[];
}

export interface LedgerStockClass {
  color?: number;
  name?: string;
}

export interface StockLedgerConfig {
  classes?: LedgerStockClass[];
}

export interface CertificateNumberForm {
  certificateNumber?: number | null;
}

export type VestingSchedule = object;

export interface VestingSchedules {
  data?: VestingSchedule[];
}

export interface UtilizationReportConfiguration {
  plans?: Document[];
  fiscalYearEnd?: number | null;
}

export interface UtilizationCategoryItem {
  /** @format string */
  title: UtilizationCategoryItemTitleEnum;
  utilized?: number | null;
  reserved?: number | null;
}

export interface UtilizationOnDate {
  /** @format date */
  date: string;
  used: number;
  reserved: number;
}

export interface UtilizationReportingPeriodData {
  start: UtilizationOnDate;
  end: UtilizationOnDate;
  categories: UtilizationCategoryItem[];
}

export interface UtilizationReportData {
  plan?: Document;
  data: UtilizationReportingPeriodData[];
}

export interface Holders {
  type: string;
  title: string;
  shares: number;
  cse?: number;
  percentage?: number | null;
  count?: number;
  other?:
    | {
        type: string;
        class?: string;
        amount: number;
      }[]
    | null;
}

export interface StockClasses {
  id: string;
  name: string;
  type: string;
  color: string;
  children: Holders[];
}

export type EquityStructureConfigData = {
  id: string;
  name: string;
  children: StockClasses[];
} | null;

export interface Constraint701GrantInfo {
  grantId?: string;
  shares?: number;
  /** @format decimal */
  exercisePrice?: number;
  isPendingApproval?: boolean;
  isAdditional?: boolean;
  isPromised?: boolean;
  /** @format decimal */
  value?: number;
  ownerName?: string;
  docLink?: string;
  excluded?: boolean;
  grantDate?: string;
}

export interface Constraint701GrantInfoList {
  /** @format decimal */
  totalValue?: number;
  totalShares?: number;
  grantInfos?: Constraint701GrantInfo[];
}

export interface IndividualConstraintInfo {
  valueType?: string;
  /** @format decimal */
  threshold?: number | null;
  title?: string;
  label?: string;
}

export interface Constraints701 {
  individualConstraints?: IndividualConstraintInfo[];
}

export interface Constraint701Data {
  totalOutstandingShares?: number;
  constraints?: Constraints701;
  reportStartDate?: string;
  reportEndDate?: string;
  totalGrantShares?: number;
  /** @format decimal */
  totalGrantValue?: number;
  grants?: Constraint701GrantInfoList;
  pendingGrants?: Constraint701GrantInfoList;
  additionalGrants?: Constraint701GrantInfoList;
  promisedGrants?: Constraint701GrantInfoList;
}

export interface Constraint701DataAndOptionForm {
  reportData?: Constraint701Data;
  optionForm?: GenericUpdateForm;
}

export interface Constraint701GrantsPageData {
  total?: number;
  grantInfos?: Constraint701GrantInfo[];
}

export interface ValuationAction {
  title: string;
  icon: string;
  url?: string | null;
  type: string;
}

export interface ValuationReportItem {
  /** @format date */
  valuationDate?: string | null;
  /** @format decimal */
  pricePerShare?: number | null;
  /** @format decimal */
  postSplitPrice?: number | null;
  documentId?: string;
  type?: string;
  source?: string;
  status?: string;
  consentId?: string | null;
  noDiscrepancies?: number;
  actions?: ValuationAction[];
}

export type ValuationReportCurrentFmv = {
  /** @format decimal */
  fmv: number;
  /** @format date */
  valuationDate: string;
  /** @format date */
  expirationDate: string;
} | null;

export type ValuationReportProcessInfo = {
  processId?: string | null;
  /** @format date */
  startedDate?: string | null;
};

export interface ValuationReportResponse {
  processPermission?: boolean;
  splits?: boolean;
  current?: ValuationReportCurrentFmv;
  requestValuation?: ValuationReportProcessInfo;
  items: ValuationReportItem[];
}

export interface ValuationDiscrepancyItem {
  stakeholderName?: string;
  documentUrl?: string;
  documentTitle?: string;
  /** @format decimal */
  fmvAtExercise?: number | null;
  /** @format decimal */
  fmv?: number | null;
}

export interface ValuationDiscrepanciesResponse {
  items: ValuationDiscrepancyItem[];
}

export interface DocpileDraftSummary {
  document?: MaybeDocument;
  id?: number;
  docId?: string | null;
  title?: string | null;
  status?: string;
}

export interface DocpileBundleSummary {
  id?: number;
  title?: string;
  /** @format date */
  dt?: string | null;
  documentId?: string | null;
  status?: string;
  shares?: number | null;
  amount?: string | null;
  valid?: boolean;
  errors?: string[];
}

export interface DocpileSummary {
  bundles?: DocpileBundleSummary[];
  drafts?: DocpileDraftSummary[];
}

export interface DocpileFlushResponse {
  entityId?: string;
  sessionId?: string;
  flushedDocIds?: string[];
}

export interface DocpileDiscardResponse {
  entityId?: string;
  sessionId?: string;
}

export interface DocpileBundleGetResponse {
  entityId?: string;
  sessionId?: string;
  form?: any;
  data?: any;
  valid?: boolean;
  bundleErrors?: string[];
  note?: string;
}

export interface DocpileBundlePostResponse {
  entityId?: string;
  sessionId?: string;
  success?: boolean;
  formErrors?: any;
  bundleErrors?: string[];
  docpile?: DocpileSummary;
}

export interface DocpileBeginResponse {
  entityId?: string;
  sessionId?: string | null;
  docpile?: DocpileSummary;
}

export interface DocpileBundleDiscardResponse {
  docpile?: DocpileSummary;
}

export interface DocpileBundleRestoreResponse {
  docpile?: DocpileSummary;
}

export interface SettingsProfile {
  id: string;
  settingsType: string;
  title: string;
  isDefault?: boolean;
  /** Parent profile id */
  parentId?: string | null;
  creatorId?: string | null;
  /** Profile metadata containing the settings */
  metadata?: object;
}

export interface SettingsProfilePathItem {
  id?: string;
  title?: string;
}

export interface SettingsProfileAndPath {
  profile?: SettingsProfile;
  path?: SettingsProfilePathItem[];
}

export interface EmployeeForms {
  id: string;
  title?: string;
  resourceId?: string;
  instruction?: string;
  onboarding?: boolean;
  onboardingRequired?: boolean;
  employeeGroup?: string;
  enabled?: boolean;
  relatedDocumentation?: string;
  /** @format date */
  updatedDate?: string;
}

export interface ContingencyForms {
  id: string;
  title?: string;
  resourceId?: string;
  instruction?: string;
  required?: boolean;
  employeeGroup?: string;
  enabled?: boolean;
  relatedDocumentation?: string;
  /** @format date */
  updatedDate?: string;
}

export interface CompanyPolicy {
  id: string;
  active: boolean;
  title: string;
  description?: string;
  /** @format date */
  effectiveDate?: string;
  type?: {
    id?: string;
    title?: string;
  };
  appliesTo: {
    id?: string;
    title?: string;
  }[];
}

export interface CompanyPolicyForm {
  upload_id?: string;
  title: string;
  description?: string | null;
  /** @format date */
  effective_date: string;
  policy_type: string;
  employee_groups?: string[];
}

export interface CompanyInfoDocument {
  docId: string;
  title: string;
  groupIds: string[];
  active?: boolean;
  size?: number | null;
  sizeStr?: string | null;
  partOfWelcomePacket: boolean;
  partOfNewHireSetup: boolean;
}

export interface CompanyInfoDocumentResults {
  documents?: CompanyInfoDocument[];
  message?: string | null;
  success?: boolean;
}

export interface StatusChangeResponse {
  success?: boolean;
  message?: string | null;
}

export interface CompanyInfoDocumentCreateForm {
  upload_id: string;
  title: string;
  groupIds: string[];
  partOfWelcomePacket?: boolean;
  partOfNewHireSetup?: boolean;
}

export interface CompanyInfoDocumentEditForm {
  upload_id?: string;
  title: string;
  groupIds: string[];
  partOfWelcomePacket?: boolean;
  partOfNewHireSetup?: boolean;
}

export interface Ticket {
  title?: string;
  description?: string;
  deadline?: string;
  type?: string;
  assigneeIds?: object[];
  groupId?: object;
}

export interface HRLimitedGroupInfo {
  id?: string;
  title?: string;
  isFullCompanyAccess?: boolean;
  isFullHRAccess?: boolean;
}

export interface HRProfileManagerInfo {
  stakeholder?: Stakeholder;
  managedGroups?: SettingsProfileAndPath[];
  hrSecurityGroups?: HRLimitedGroupInfo[];
  editAccessUrl?: string;
}

export interface HRProfileManagersResponse {
  customProfilesInUse?: boolean;
  managers?: HRProfileManagerInfo[];
}

export interface HRProfilePropertiesResponse {
  properties?: object;
  derivedManagers?: HRProfileManagerInfo[];
}

export interface EmployeeReportItem {
  id?: string;
  fullName?: string;
  /** @format date */
  start_date?: string;
  /** @format date */
  termination_date?: string;
  /** @format date */
  offer_date?: string;
  cost_center?: string | null;
  viewDocuments?: boolean;
}

export interface EmployeeLink {
  url?: string;
  title?: string;
  icon?: string;
}

export interface EmployeeDocuments {
  fullName?: string;
  links?: EmployeeLink[];
}

export interface EmployeeReport {
  canAdd?: boolean;
  hrDataRoomEnabled?: boolean;
  advancedHrEnabled?: boolean;
  defaultFolder?: string;
  page?: number;
  totalItems?: number;
  data?: EmployeeReportItem[];
}

export interface IPItem {
  fullname?: string;
  id?: string;
  /** @format date */
  date?: string | null;
  inventions?: string | null;
  patents?: string | null;
  non_company_related?: string | null;
  position?: string | null;
  company_related?: string | null;
  /** True if inventions, patents, non_company_related has content */
  has_exclusions?: boolean;
  shid?: string;
  card?: DocumentCard;
}

export interface IPReport {
  page?: number;
  totalItems?: number;
  hasMore?: boolean;
  items?: IPItem[];
}

export interface NDAReport {
  page?: number;
  totalItems?: number;
  hasMore?: boolean;
  items?: {
    id?: string;
    /** @format date */
    date?: string | null;
    duration?: string;
    type?: string;
    extPartId?: string;
    extPartyName?: string;
    card?: DocumentCard;
  }[];
}

export interface ExportOptionsFormData {
  data?: {
    title?: string;
    formFields?: {
      key?: string;
      type?: string;
      templateOptions?: {
        label?: string;
        required?: boolean;
        enumVocab?: {
          value?: boolean;
          label?: string;
        }[];
      };
    }[];
    model?: {
      includeTerminated?: boolean;
    };
  };
}

/** Flags indicating which tabs to show. */
export type HrAdminTabsConfig = Record<string, boolean>;

export interface HrAdminTabsConfigResponse {
  /** Flags indicating which tabs to show. */
  tabsConfig?: HrAdminTabsConfig;
}

export interface Captable {
  /**
   * As of date for which data is generated.
   * @format date
   */
  dt?: string;
  /** Settings for limiting the data for summary view */
  defaultOwnershipSettings?: CaptableOwnershipSummarySettings;
  /** Display direction. Defines what columns should be or should not be visible when captable is displayed. */
  display?: CaptableDisplay;
  options?: CaptableOptions;
  /** List of captable lines with the reference to the parent line. */
  root?: CaptableChildren;
  details?: CaptableChildren[];
  /** When false, the captable is being updated; returned data might be incomplete. */
  uptodate?: boolean;
}

export interface CaptableUnavailable {
  /** Reason why captable is not available */
  reason?: string;
}

/** List of captable lines with the reference to the parent line. */
export interface CaptableChildren {
  /** Unique identifier for the captable line */
  parent?: CaptableLineRef;
  total?: number;
  offset?: number;
  lines?: CaptableLine[];
}

/** Display direction. Defines what columns should be or should not be visible when captable is displayed. */
export interface CaptableDisplay {
  detailedModeAvailable?: boolean;
  summarySettingsAvailable?: boolean;
  classDisplay?: CaptableStockClassDisplay[];
  debtDisplay?: CaptableDebtDisplay;
  totalCSEDisplay?: boolean;
}

/** Display properties for a stock class column. Indicates which sub-columns has to be shown for this stock class. */
export interface CaptableStockClassDisplay {
  name?: string;
  /** @format decimal */
  conversionRatio?: number | null;
  shares?: boolean;
  options?: boolean;
  warrants?: boolean;
  rsu?: boolean;
  cse?: boolean;
  percent?: boolean;
}

export interface CaptableDebtDisplay {
  bridgeNote?: boolean;
  safe?: boolean;
}

/** Settings for limiting the data for summary view */
export interface CaptableOwnershipSummarySettings {
  /** @format decimal */
  ownershipThreshold?: number;
  /** @format decimal */
  convertibleThreshold?: number;
}

/** Unique identifier for the captable line */
export interface CaptableLineRef {
  type?: string;
  id: string;
}

export interface CaptableLineClassHoldings {
  stockClass?: string;
  shares?: number;
  options?: number;
  warrants?: number;
  rsu?: number;
  /** Common Shares Equivalent */
  cse?: number;
  /**
   * Percent of total holdings for this class in range [0-100). If value is not provided, raised amount should be displayed.
   * @format decimal
   */
  percent?: number | null;
  /**
   * number of dollars raised for this class.
   * @format decimal
   * @default null
   */
  raised?: number | null;
}

export interface CaptableLineDebt {
  /** @format decimal */
  bridgeNote?: number;
  /** @format decimal */
  safe?: number;
}

export interface CaptableDocumentInfo {
  /**
   * @format date
   * @default null
   */
  effectiveDate?: string | null;
  /** @default null */
  ownerId?: string | null;
  /** @default false */
  grant?: boolean;
  /** @default false */
  missingDocument?: boolean;
  /** @default false */
  pendingGrant?: boolean;
  /** @default false */
  promisedStock?: boolean;
  /** @default false */
  equityReleased?: boolean;
}

export interface CaptableLine {
  /** Unique identifier for the captable line */
  ref: CaptableLineRef;
  hasChildren: boolean;
  title?: string;
  url?: string | null;
  classHoldings?: Record<string, CaptableLineClassHoldings>;
  debt?: CaptableLineDebt;
  totalCSE?: number;
  /**
   * Total ownership percent in range between 0 and 100. If this value is not provided, totalRaised should be displayed instead.
   * @format decimal
   */
  totalPercent?: number | null;
  /** @format decimal */
  totalRaised?: number | null;
  docInfo?: CaptableDocumentInfo;
}

export interface CaptableOptions {
  /** @default "full" */
  mode?: CaptableOptionsModeEnum;
  /**
   * @format date
   * @default null
   */
  dt?: string | null;
  /**
   * @format date
   * @default null
   */
  minDt?: string | null;
  /** @default null */
  stakeholderId?: string | null;
  /** @default true */
  includeAllOutstandingShares?: boolean;
  /** @default true */
  includeUngrantedPlanShares?: boolean;
  /** @default true */
  includeOptionsWarrants?: boolean;
  /** @default false */
  includePending?: boolean;
  /** @default false */
  includePromises?: boolean;
  /** @default true */
  groupByParent?: boolean;
  /** @default false */
  hasFunds?: boolean;
}

export interface CaptableLineDetails {
  entityId?: string;
  actions?: CaptableLineAction[];
}

export interface CaptableLineAction {
  id?: string;
  type?: string;
  title?: string;
  titleNote?: string;
  description?: string;
  url?: string;
  enabled?: boolean;
  disableReason?: string;
}

export interface ProcessAbortInfo {
  id?: string;
  title?: string;
  canDiscard?: boolean;
  processesToBeDiscarded?: string[];
  documentsToBeDiscarded?: DocumentCard[];
  blockingProcesses?: string[];
}

export interface ProcessAbortResponse {
  success?: boolean;
  canDiscard?: boolean;
  message?: string | null;
  nextURL?: string | null;
}

/** See also definitions/WorkflowToDo */
export interface ProcessToDoResult {
  pid?: string;
  canViewHistory?: boolean;
  canViewStatus?: boolean;
  /** @format date-time */
  started?: string;
  startedStr?: string;
  startedAgo?: string;
  /** @format date-time */
  finished?: string | null;
  finishedStr?: string | null;
  finishedAgo?: string;
  title?: string;
  processDriver?: StakeholderCard;
  workitems?: ProcessToDoResultWorkitem[];
  involved?: boolean;
  blocked?: boolean;
  infoAvailable?: boolean;
  participants?: StakeholderCard[] | null;
  /** Abort url for the process. Null if abort is not possible. */
  abortUrl?: string | null;
}

/** Todo results for the same workitem grouped into the single object with a list of assignees. */
export interface ProcessToDoResultWorkitem {
  blocking?: boolean;
  url?: string;
  doable?: boolean;
  canRemind?: boolean;
  /** @format date-time */
  date?: string;
  dateAgo?: string;
  dateStr?: string;
  assigneeTitles?: string[];
  title?: string;
  wiUid?: string;
  wiPid?: string;
}

export interface ProcessHistoryEntry {
  title?: string;
  user?: string;
  /** This is not just a date, but a string, can have a Started prefix */
  date?: string;
}

export interface ProcessHistoryResponse {
  title?: string;
  status?: string;
  /** @format date-time */
  lastModified?: string;
  lastModifiedStr?: string;
  items?: ProcessHistoryEntry[];
}

export interface ProcessesByIdItem {
  process?: Process;
  driver?: Stakeholder;
  canViewStatus?: boolean;
  participants?: Stakeholder[];
}

export interface ProcessesByIdResponse {
  data?: ProcessesByIdItem[];
}

export interface ProcessCompanyActionItem {
  description?: string;
  group?: string;
  hidden?: boolean;
  icon?: string;
  link?: string;
  title?: string;
  weight?: number;
}

export interface ProcessCompanyActions {
  data?: ProcessCompanyActionItem[];
}

/** Result is either challenge false and a message or challenge true and challengeMethods filled */
export interface PasswordResetSuccess {
  challenge?: boolean;
  message?: string | null;
  challengeMethods?: PasswordResetChallengeMethod[] | null;
}

/** Result is a message */
export interface PasswordChallengeSuccess {
  message?: string | null;
}

export interface PasswordResetChallengeMethod {
  id?: string;
  title?: string;
}

/** Result is a token to use with password change */
export interface PasswordResetVerifySuccess {
  sessionToken?: string;
}

/** Result is */
export interface TwoFactorChallengeMethods {
  message?: string | null;
  challengeMethods?: TwoFactorChallengeMethod[] | null;
}

export interface TwoFactorChallengeMethod {
  id?: string;
  title?: string;
}

/** Result is a message */
export interface TwoFactorChallengeSuccess {
  message?: string | null;
}

export interface IndividualCounselInfo {
  fullName: string;
  email?: string;
  address?: string;
  contact?: object;
}

export interface TeamCounselInfo {
  firmName: string;
  firmAddress?: string;
  logo?: string;
  stakeholders?: IndividualCounselInfo[];
}

export interface CounselInfo {
  hasCounsel: boolean;
  isTeam?: boolean;
  changeCounselLink?: string | null;
  team?: TeamCounselInfo;
  individual?: IndividualCounselInfo;
}

export interface CounselRemoveResponse {
  status: string;
  redirectUrl?: string | null;
}

export interface ShareholderHoldings {
  /** @format decimal */
  value?: number;
  valueStr?: string;
}

export interface ShareholderVotingShareholders {
  id?: string;
  name?: string;
  data?: Record<string, ShareholderHoldings>;
  /** @format decimal */
  convertedShares?: number;
  convertedSharesStr?: string;
}

/** Contains selected shareholders, shareholders and thresholds */
export interface ShareholderVoting {
  selectedShareholders?: string[];
  shareholders?: ShareholderVotingShareholders[];
  stockClasses?: string[];
  thresholds?: Record<string, number>;
}

export interface StakeholderTableData {
  selectedItems: string[];
  items: StakeholderTableRow[];
  columns: string[][];
  securityTicketID?: string;
}

export interface StakeholderTableRow {
  id: string;
  item: string;
  classes?: string[];
  holdings?: string;
  investor?: string;
  stakeholder?: string;
  contactEmail?: string;
}

export interface CommonStack {
  type?: string;
  name?: string;
  prefix?: string;
  preferred?: boolean;
  color?: string;
}

export interface Stack {
  type?: string;
  name?: string;
  color?: string;
  prefLabel?: string;
  liquidationMultiplier?: string;
  /** @format decimal */
  liquidationCap?: number;
  prefix?: string;
  preferred?: boolean;
}

export interface LiquidationStackDataGet {
  stack: Stack[][];
  common: CommonStack[];
  message?: string;
}

export interface StackName {
  name: string;
}

export interface LiquidationStackDataPost {
  stack: StackName[][];
  common: StackName[];
}

export interface FundraisingReportProcessAction {
  available?: boolean;
  title?: string;
  icon?: string | null;
  description?: string | null;
  url?: string;
}

export interface FundraisingReportProcessActions {
  actions?: FundraisingReportProcessAction[];
}

export interface FundraisingReportSummaryTotals {
  /** @format decimal */
  totalCashRaised?: number;
  /** @format decimal */
  equityFinancings?: number;
  /**
   * Conversions that are included in equityFinancings
   * @format decimal
   */
  conversions?: number;
  /** @format decimal */
  convertibles?: number;
  /** @format decimal */
  commonStockPurchases?: number;
  /** @format decimal */
  valuationPostMoney?: number | null;
}

export interface FundraisingReportSummary {
  totals?: FundraisingReportSummaryTotals;
  processActions?: FundraisingReportProcessAction[];
  fundraisingEvents?: FundraisingReportSummaryEvent[];
}

export interface FundraisingReportSummaryAction {
  url?: string;
  icon?: string;
  title?: string;
}

/** Instructions for the front end on what actions to show for the line. */
export interface FundraisingReportSummaryActionConfig {
  canViewInvestorInfo?: boolean | null;
  canViewInvestments?: boolean | null;
  canViewAgreement?: boolean | null;
  canViewFinancingBinder?: boolean | null;
  canStartAdditionalClosingEquity?: boolean | null;
  canViewDetails?: boolean | null;
  canViewDealRoom?: boolean | null;
  canViewNotesSchedule?: boolean | null;
  canStartAdditionalClosingNote?: boolean | null;
  canCancelNote?: boolean | null;
  /** The id of the folder in the Data Room where the invesmentDoc lives.  In the case of Equity Financings and Bridge Notes that's a separate folder for the whole deal called "Series A - 1 January, 1970" or "Bridge Note - 1 January, 1970". */
  investmentDocFolderId?: string | null;
}

/** Key value items that describe the financing events on the summary tab. */
export interface FundraisingReportSummaryEventDetail {
  key?: string;
  /** Money, number of shares, status strings, depending on the type. */
  value?: any;
  type?: FundraisingReportSummaryEventDetailTypeEnum;
}

export interface FundraisingReportSummaryEvent {
  title?: string;
  investmentType?: FundraisingReportSummaryEventInvestmentTypeEnum;
  investmentDocId?: string | null;
  color?: string;
  /** @format date */
  date?: string;
  /** @format decimal */
  amount?: number;
  /** Converted or Open for convertibles, null for others. */
  status?: string | null;
  details?: FundraisingReportSummaryEventDetail[];
  /** Instructions for the front end on what actions to show for the line. */
  actionConfig?: FundraisingReportSummaryActionConfig;
}

/** Instructions for the front end on what actions to show for the line. */
export interface FundraisingReportEquityActionConfig {
  canViewInvestorInfo?: boolean | null;
  canViewInvestments?: boolean | null;
  canViewAgreement?: boolean | null;
  canViewFinancingBinder?: boolean | null;
  canStartAdditionalClosingEquity?: boolean | null;
  canViewDetails?: boolean | null;
  canViewDealRoom?: boolean | null;
  /** The id of the folder in the Data Room where the invesmentDoc lives.  In the case of Equity Financings and Bridge Notes that's a separate folder for the whole deal called "Series A - 1 January, 1970" or "Bridge Note - 1 January, 1970". */
  investmentDocFolderId?: string | null;
}

export interface FundraisingReportEquityTotals {
  /** @format decimal */
  equity?: number;
  /**
   * Conversions that are included in the equity total
   * @format decimal
   */
  conversions?: number;
  /** @format decimal */
  valuation?: number | null;
}

export interface FundraisingReportEquityRef {
  id: string;
  type?: string;
}

export interface FundraisingReportEquityLine {
  ref: FundraisingReportEquityRef;
  hasChildren: boolean;
  title?: string;
  /** @format date */
  date?: string;
  /** @format decimal */
  amount?: number;
  /** List of number of shares by class */
  shares?: {
    stockClass?: string;
    shares?: number;
  }[];
  /** List of issue prices by class */
  issuePrice?:
    | {
        stockClass?: string;
        /** @format decimal */
        issuePrice?: number;
      }[]
    | null;
  /** List of certificate docs by class. */
  certificates?: {
    stockClass?: string;
    certificate?: Document;
    certificateNumber?: string | null;
  }[];
  spaId?: string | null;
  /** Instructions for the front end on what actions to show for the line. */
  actionConfig?: FundraisingReportEquityActionConfig;
}

/** List of fundraising report equity lines with the reference to the parent line. */
export interface FundraisingReportEquityChildren {
  parent?: FundraisingReportEquityRef;
  total?: number;
  offset?: number;
  rows?: FundraisingReportEquityLine[];
}

export interface FundraisingReportEquity {
  /** List of fundraising report equity lines with the reference to the parent line. */
  root?: FundraisingReportEquityChildren;
  details?: FundraisingReportEquityChildren[];
  totals?: FundraisingReportEquityTotals;
  processActions?: FundraisingReportProcessAction[];
}

export interface FundraisingReportConvertiblesTotals {
  /** @format decimal */
  total?: number;
  /** @format decimal */
  open?: number;
}

/** Instructions for the front end on what actions to show for the line. */
export interface FundraisingReportConvertiblesActionConfig {
  canViewInvestorInfo?: boolean | null;
  canViewInvestments?: boolean | null;
  canViewAgreement?: boolean | null;
  canViewFinancingBinder?: boolean | null;
  canViewNotesSchedule?: boolean | null;
  canStartAdditionalClosingNote?: boolean | null;
  canCancelNote?: boolean | null;
  /** The id of the folder in the Data Room where the invesmentDoc lives.  In the case of Equity Financings and Bridge Notes that's a separate folder for the whole deal called "Series A - 1 January, 1970" or "Bridge Note - 1 January, 1970". */
  investmentDocFolderId?: string | null;
}

export interface FundraisingReportConvertiblesLine {
  ref: FundraisingReportConvertiblesRef;
  hasChildren: boolean;
  shareholder?: StakeholderCard;
  status?: string;
  investmentType?: string;
  /** @format date */
  closingDate?: string | null;
  /** @format decimal */
  raised?: number;
  /** @format decimal */
  discount?: number | null;
  /** @format decimal */
  valuationCap?: number | null;
  sharesIssued?:
    | {
        stockClass?: string;
        shares?: number;
      }[]
    | null;
  /** @format decimal */
  issuePrice?: number | null;
  /** @format date */
  issueDate?: string | null;
  agreement?: Document;
  /** Instructions for the front end on what actions to show for the line. */
  actionConfig?: FundraisingReportConvertiblesActionConfig;
}

export interface FundraisingReportConvertiblesRef {
  id: string;
  type?: string;
}

/** List of fundraising report convertibles lines with the reference to the parent line. */
export interface FundraisingReportConvertiblesChildren {
  parent?: FundraisingReportConvertiblesRef;
  total?: number;
  offset?: number;
  rows?: FundraisingReportConvertiblesLine[];
}

export interface FundraisingReportConvertiblesTree {
  /** List of fundraising report convertibles lines with the reference to the parent line. */
  root?: FundraisingReportConvertiblesChildren;
  details?: FundraisingReportConvertiblesChildren[];
  totals?: FundraisingReportConvertiblesTotals;
  processActions?: FundraisingReportProcessAction[];
}

export interface FundraisingReportCommonTotals {
  /** @format decimal */
  common?: number;
  /** @format decimal */
  optionExercises?: number;
  /** @format decimal */
  netRepurchases?: number;
}

/** Instructions for the front end on what actions to show for the line. */
export interface FundraisingReportCommonActionConfig {
  canViewInvestorInfo?: boolean | null;
  canViewAgreement?: boolean | null;
}

export interface FundraisingReportCommonEvent {
  shareholder?: StakeholderCard;
  /** @format date */
  date?: string;
  /** @format decimal */
  raised?: number;
  sharesIssued?: number;
  /** @format decimal */
  issuePrice?: number;
  certificate?: Document;
  certificateNumber?: string | null;
  /** Instructions for the front end on what actions to show for the line. */
  actionConfig?: FundraisingReportCommonActionConfig;
}

export interface FundraisingReportCommon {
  totals?: FundraisingReportCommonTotals;
  events?: FundraisingReportCommonEvent[];
}

export interface FundraisingReportInvestorsItem {
  name?: string;
  primaryContact?: string | null;
  email?: string;
  address?: string;
}

export interface FundraisingReportInvestors {
  items?: FundraisingReportInvestorsItem[];
}

export interface FundraisingReportInvestmentsItem {
  certificateNr?: string;
  certificate?: Document;
  name?: string;
  /** @format date */
  date?: string;
  stockClass?: string;
  /** @format decimal */
  amount?: number;
  shares?: number;
}

export interface FundraisingReportInvestments {
  /** Title of the investment document */
  title?: string;
  items?: FundraisingReportInvestmentsItem[];
}

export interface FundraisingReportNoteScheduleEntry {
  note?: Document;
  number?: number;
  name?: string;
  /** @format date */
  date?: string;
  /** @format decimal */
  amount?: number;
  status?: string;
  /** The process Id of the process responsible for pending notes. We're not showing pending notes yet, but we'll need this if we do. */
  processId?: string;
}

export interface FundraisingReportNoteSchedule {
  items?: FundraisingReportNoteScheduleEntry[];
  /** Name of the NPA document */
  title?: string;
}

export interface Asc718OptionsResult {
  model?: object;
  description?: object;
}

export interface Asc718GrantRecord {
  grantee?: string;
  grant_id?: string;
  /** @format date */
  date?: string;
  shares?: number;
  expenses?:
    | {
        /** @format date */
        expenseDate?: string;
        expenseAmount?: number;
      }[]
    | null;
  expected_term?: number | null;
  interest_rate?: number | null;
  volatility?: number | null;
  fair_value?: number | null;
  excluded?: boolean | null;
  cost_center?: string | null;
  document_link?: string;
}

export interface Asc718GrantsResult {
  formDescription?: object;
  totalItems?: number;
  grants?: Asc718GrantRecord[];
}

export interface Asc718ReportsResult {
  results?: {
    docId?: string;
    title?: string;
    date?: string;
    reportStartDate?: string;
    reportEndDate?: string;
    documentLink?: string;
    forfeitureRate?: number;
    numGrants?: number;
    expense?: number;
  }[];
}

export interface PeerCompanyTicker {
  token?: string;
  title?: string;
  warning?: string;
}

export interface AuthConfigResponse {
  /** Configuration for Core&Common Login widget */
  ccConfig?: Record<string, string>;
  /** Type of the log in to perform. "login" is a regular login, "signup" is used when a brand new user without established credentials accessing the login page. */
  loginType?: AuthConfigResponseLoginTypeEnum;
  /**
   * URL of an external login page. Used when loginType is "external".
   * @default null
   */
  externalLoginUrl?: string | null;
}

export interface AuthPostLoginResponse {
  /** When true - Fidelity user id wasn't found, ask user to identify themselves. When false - login was successful. */
  conversionRequired?: boolean;
  /** @default null */
  camefrom?: string | null;
  /**
   * When not empty, login was not successful and user needs to re-login.
   * @default null
   */
  error?: string | null;
}

export interface AuthSignupConfigResponse {
  /** Url to redirect the identified user to if they choose to "Signup" instead of "Log in". When not provided, that means that the identiied user can be matched to existing Fidelity member and needs to log in with their existing password. */
  signupUrl?: string | null;
  /**
   * Fidelity tracking id used for Fidelity API calls. For debugging purposes only.
   * @default null
   */
  fidTrackingId?: string | null;
  /**
   * Error code returned from the membership API. For debugging only.
   * @default null
   */
  membershipApiFailureCode?: string | null;
  /**
   * When true - user is already signed up in Fidelity and cannot sign up again. signupUrl will be empty. When false - user must follow signupUrl to sign up.
   * @default false
   */
  alreadySignedUp?: boolean;
}

export interface AuthIdentificationMethod {
  /** Identification method id */
  method?: string;
  /** Human-readable description of a method */
  description?: string;
}

export interface AuthIdentificationMethodsResponse {
  methods?: AuthIdentificationMethod[];
}

export interface AuthSendOTPRequest {
  email?: string;
  method?: string;
}

export interface AuthSendOTPResponse {
  /** Message to show to the user */
  message?: string;
}

export interface AuthIdentifyRequest {
  /** Shoobx Email */
  email: string;
  /**
   * Shoobx Password
   * @default null
   */
  password?: string | null;
  /**
   * One-Time-Password
   * @default null
   */
  otp?: string | null;
}

export interface AuthIdentifyResponse {
  /**
   * When identification successful, the next step to for the user to take
   * @default null
   */
  next?: AuthIdentifyResponseNextEnum;
  /**
   * Url to redirect user to if they choose to "Signup" instead of "Log in"
   * @default null
   */
  signupUrl?: string | null;
  /**
   * Error description to show to the user, if identification fails
   * @default null
   */
  error?: string | null;
  /**
   * Fidelity tracking id used for Fidelity API calls. For debugging purposes only.
   * @default null
   */
  fidTrackingId?: string | null;
  /**
   * Error code returned from the membership API. For debugging only.
   * @default null
   */
  membershipApiFailureCode?: string | null;
}

export interface AuthConvertRequest {
  /**
   * Set to True when consent to merge stakeholders was obtained from the user
   * @default false
   */
  agreeToMerge?: boolean;
  /**
   * Set to True when consent to terms of use was obtained from the user
   * @default false
   */
  agreeToTerms?: boolean;
}

export interface AuthConvertResponse {
  next?: AuthConvertResponseNextEnum;
  /** @default null */
  camefrom?: string | null;
  /**
   * Obfuscated email of the user that is was already associated with logged Fidelity member
   * @default null
   */
  duplicateHint?: string | null;
}

export interface GustoCompany {
  /** Company ID */
  companyId: string;
  /** Name of the company */
  name?: string;
  /** Trade name of the company */
  tradeName?: string;
}

export type GustoConnectAuthorizedCompanyForm = object;

export type GustoProvisionCompanyForm = object;

export interface GustoSetup {
  /** Is Shoobx authorized to access Gusto companies on some user's behalf? */
  isAuthorized?: boolean;
  /** Can current user manage Gusto settings? */
  canManageSettings?: boolean;
  /** Can current user view stakeholder profile pages? */
  canViewStakeholderProfile?: boolean;
  /** Company ID connected to this entity */
  companyId?: string | null;
  /** Gusto website URL */
  websiteURL?: string;
  /** Gusto application URL */
  appURL?: string;
}

export interface GustoEmployee {
  employeeId?: string | null;
  uuid?: string | null;
  stakeholderId?: string | null;
  firstName?: string;
  lastName?: string;
  stakeholder?: StakeholderCard;
  warnings?: string[] | null;
  startDate?: string | null;
  terminationDate?: string | null;
  terminationDateISO?: string | null;
  shouldTerminateShoobx?: boolean;
  shouldTerminateGusto?: boolean;
  synchronized?: boolean;
}

export interface GustoSyncStatus {
  /** Is Shoobx authorized to access a Gusto company connected to this entity? */
  hasCompanyAccess?: boolean;
  lastSyncTimestamp?: string | null;
  lastSyncAgo?: string | null;
  isSynchronizing?: boolean;
  /** UI notification topic */
  syncTopic?: string | null;
}

export interface GustoEmployeesResponse {
  setup?: GustoSetup;
  status: GustoSyncStatus;
  employees: GustoEmployee[];
}

export interface GustoLogEntry {
  stakeholder?: StakeholderCard;
  description?: string;
  timestamp?: string;
}

export interface GustoLogResponse {
  items?: GustoLogEntry[];
  hasMore?: boolean;
}

export interface GustoClaimAccountResponse {
  /** redirect user to this URL to claim a new account */
  accountClaimURL?: string | null;
  /** if true, account is already claimed for this email */
  accountAlreadyExists?: boolean;
  message?: string | null;
}

export interface GustoEmployeeDiffField {
  name?: string;
  title?: string;
  shoobxValue?: string;
  gustoValue?: string;
  /** When synchronizing, prefer gustoValue, shoobxValue, values match or values cannot be synced automatically? */
  prefer?: GustoEmployeeDiffFieldPreferEnum;
}

export interface GustoEmployeeDiff {
  fields?: GustoEmployeeDiffField[];
  employeeId?: string | null;
  stakeholderId?: string | null;
}

export interface GustoConnectEmployeeForms {
  gustoForm?: {
    description?: UserIOFormDefinition;
    data?: object;
  } | null;
  shoobxForm?: {
    description?: UserIOFormDefinition;
    data?: object;
  } | null;
  employeeId?: string | null;
  stakeholderId?: string | null;
  gustoFullName?: string | null;
  shoobxFullName?: string | null;
}

export interface GustoConnectEmployee {
  stakeholderId?: string | null;
  shoobxData?: object | null;
  employeeId?: string | null;
  gustoData?: object | null;
  /**
   * User reviewed differences between employees and confirmed connection.
   * @default false
   */
  confirmed?: boolean;
}

export interface GustoConnectEmployeeResponse {
  employeeId?: string | null;
  stakeholderId?: string | null;
  needsConfirmation?: boolean;
  /** Confirmation modal dialog title. */
  confirmTitle?: string | null;
  /** Confirmation modal dialog body text. */
  confirmBody?: string | null;
}

/** Form error response */
export interface GustoFormErrors {
  title?: string;
  /** Forms mapping */
  message?: Record<string, Record<string, string>>;
  code?: number;
}

export interface EntityRelationshipType {
  name?: string;
  type?: string;
  isSource?: boolean;
  isTarget?: boolean;
  startDate?: string | null;
  teamSHId?: string | null;
  primaryContactName?: string | null;
  primaryContactEmail?: string | null;
  memberCount?: number;
  designatedAccess?: string[];
  dataroomAccess?: string[];
  status?: string;
  invited?: boolean;
  diligence?: boolean;
  canEditNameorEmail?: boolean;
  canInvite?: boolean;
  canManageTeam?: boolean;
  canSuspendGrantedAccess?: boolean;
  canRestoreGrantedAccess?: boolean;
  canTerminateRelationship?: boolean;
  canChangeCounselSettings?: boolean;
  canRegisterNewLegalCounsel?: boolean;
}

export interface EntityRelationshipCard {
  entityId?: string | null;
  hasLogo?: boolean;
  relUrl?: string | null;
  title?: string;
  logoUrl?: string | null;
  types?: EntityRelationshipType[];
}

export interface EntityRelationshipActionResponse {
  access?: boolean | null;
  redirectUrl?: string | null;
}

export interface SupportTicketComment {
  user?: string;
  body?: string;
  created?: string;
}

export interface SupportTicket {
  id: string;
  title?: string;
  entityId?: string;
  entityTitle?: string;
  assignee?: string | null;
  status?: string;
  created?: string | null;
  dueDate?: string | null;
  /** completed date */
  completed?: string | null;
  delawareNumber?: string;
  supportProcessUrl?: string | null;
  comments?: SupportTicketComment[];
  canAccept?: boolean;
  canReassign?: boolean;
}

export type AddSupportTicketCommentForm = object;

export interface AddSupportTicketCommentResponse {
  comments?: SupportTicketComment[];
  cancel?: boolean;
}

export interface CommunicationReminderSuccess {
  emails?: string;
}

export interface CommunicationsAccessLog {
  document?: Document;
  /** @format date */
  accessed?: string;
}

export interface CommunicationRecipient {
  stakeholder?: Stakeholder;
  emailAddress?: string;
  seen?: boolean;
  /** @format date */
  accessed?: string | null;
  accessLogs?: CommunicationsAccessLog[] | null;
}

export interface InvestorCommunication {
  title: string;
  id: string;
  /** @format date */
  dt?: string;
  documents?: Document[] | null;
  emailSubject?: string | null;
  emailBody?: string | null;
  recipients?: CommunicationRecipient[] | null;
  recipientCount?: number;
  accessCount?: number;
}

export interface InvestorCommunications {
  total: number;
  items: InvestorCommunication[];
}

export interface StakeholderApprovalSettings {
  enabled?: boolean;
  verifiedDomain?: string | null;
  approvers?: StakeholderCard[] | null;
  externalApprovers?: StakeholderCard[] | null;
  entityRelationshipApprovers?: StakeholderCard[] | null;
}

export type StakeholderApprovalForm = object;

export interface StakeholderApprovalSaveResult {
  status?: string;
}

export interface RelationshipNotificationSettings {
  recipients?: StakeholderCard[] | null;
  entityRelationshipApprovers?: StakeholderCard[] | null;
}

export interface GetSignatureFontsResponse {
  fonts?: string[];
}

export interface SignatureUpdate {
  /** Keep this in sync with interfaces.SignatureMethod */
  method?: SignatureUpdateMethodEnum;
  encoded?: string | null;
  /** base64 encoded PNG???? */
  file?: string | null;
  font?: string | null;
}

export interface CardBillingDetails {
  cards?: CreditCard[];
  extraFormData?: {
    billingContact?: object | null;
    billingAddress?: {
      street1?: string;
      street2?: string;
      postal_code?: string;
      city?: string;
    } | null;
  };
  billingAddressHTML?: string;
  billingName?: string;
  billingCode?: string | null;
  ccRecipients?: string[];
}

export interface CreditCard {
  externalId?: string;
  holderName?: string;
  lastFourDigits?: string;
  expDate?: string;
  isPrimary?: boolean;
  brand?: string;
}

export interface CcRecipientForm {
  ccRecipient?: string;
}

export interface CardExternalIdForm {
  externalId?: string;
}

export interface CardIdForm {
  cardId?: string;
}

export interface BillingInfoForm {
  billingAddress?: {
    street1?: string;
    street2?: string;
    postal_code?: string;
    city?: string;
  };
}

export interface Legend1933 {
  isOverridden: boolean;
  legend: string;
}

export interface ReadonlyLegend {
  legend: string;
  parent: string;
}

export interface LegendsGroup {
  name: string;
  legends: string[];
  readonlyLegends: ReadonlyLegend[];
  hash: string;
  metadataPath: string;
  id: string;
}

export interface LegendGroups {
  groups: LegendsGroup[];
}

export interface Legend {
  legend: string;
}

export interface PreviewLegends {
  taskId: string;
}

export interface PaymentsEnabled {
  isEnabled?: boolean;
}

export interface Health {
  /** Health status */
  status: HealthStatusEnum;
  /** Health status description */
  message: string;
}

export interface HealthChecker {
  /** Name of the utility */
  name: string;
  /** Docstring of utility with that name */
  description: string;
}

export interface HealthCheckers {
  healthCheckers: HealthChecker[];
}

export interface TaxReportItem {
  eventId?: string;
  stakeholderId?: string;
  stakeholderFullName?: string;
  documentId?: string;
  eventType?: TaxReportItemEventTypeEnum;
  /** @format date */
  eventDate?: string;
  /** @format decimal */
  amountPaid?: number | null;
  paymentType?: string | null;
  /** @format decimal */
  totalTax?: number | null;
  shares?: number | null;
  /** @format decimal */
  pricePerShare?: number | null;
  /** @format decimal */
  fmvAtEvent?: number | null;
  grantId?: string | null;
  grantType?: string | null;
  /** @format date */
  grantDate?: string | null;
  /** 83(b) Election Filing title */
  status83b?: TaxReportItemStatus83BEnum;
  /** 83(b) Election Filing sub-title */
  substatus83b?: TaxReportItemSubstatus83BEnum;
}

export interface TaxReport {
  page?: number;
  totalItems?: number;
  hasMore?: boolean;
  items?: TaxReportItem[];
}

export interface TaxEventSummary {
  title?: string;
  taxes?: {
    key: string;
    value: string;
  }[];
  asOf?: string | null;
}

export interface TaxesSummary {
  title?: string;
  details?: {
    key: string;
    value: string;
  }[];
  events?: TaxEventSummary[];
  totalTaxTitle?: string;
  totalTaxAmount?: string;
  asOf?: string | null;
  indicated83b?: boolean;
  earlyExercise?: boolean;
  hasTaxes?: boolean;
  processed?: boolean;
  missingInfo?: boolean;
  unsupportedCountry?: boolean;
  calculated?: boolean;
  manualEntry?: boolean;
  errors?: boolean;
}

/** Text= left align, Number= right align the cell value */
export enum GenericTableColumnTypesEnum {
  T = 'T',
  N = 'N',
}

/** Widget type of the field */
export enum ListingFilterFieldFieldTypeEnum {
  Date = 'date',
  String = 'string',
  Tree = 'tree',
  EnumDropdown = 'enum-dropdown',
  Checklist = 'checklist',
  Switchlist = 'switchlist',
}

export enum DocumentCardTypeEnum {
  Generated = 'generated',
  Uploaded = 'uploaded',
  Executed = 'executed',
}

export enum DocumentCardDisplayTypeEnum {
  Generated = 'generated',
  Uploaded = 'uploaded',
  Deferred = 'deferred',
  Pending = 'pending',
  Certificate = 'certificate',
}

/**
 * Simple= title and string value
 * Table= LightweightProfileTable
 * I guess there might be more to come
 */
export enum LightweightProfileFieldTypeEnum {
  S = 'S',
  T = 'T',
}

/**
 * Text= left align,
 * Number= right align the cell value
 * Icon= show the given icon, format: <icon-ID>?<tooltip-text>
 * ID= hidden column for row ID
 */
export enum LightweightProfileTableColumnTypesEnum {
  T = 'T',
  N = 'N',
  I = 'I',
  ID = 'ID',
}

/**
 * Table= a single table
 * Fields= a bunch of fields
 */
export enum LightweightProfileSectionTypeEnum {
  T = 'T',
  F = 'F',
}

/** Gotta keep this in sync with shoobx.app.ui.lens @registerLens registrations! */
export enum LensInfoNameEnum {
  MyShoobx = 'MyShoobx',
  Company = 'Company',
  Administration = 'Administration',
  Counsel = 'Counsel',
  Investor = 'Investor',
  ShoobxSupport = 'ShoobxSupport',
}

export enum SideMenuItemPositionEnum {
  Top = 'top',
  Bottom = 'bottom',
}

export enum SideMenuItemLinksMenuModeEnum {
  Submenu = 'submenu',
  Collapsing = 'collapsing',
}

export enum ChecklistPanelDataLayoutEnum {
  Normal = 'normal',
  Flex = 'flex',
}

/** Panel width */
export enum DashboardPanelAppearanceWidthEnum {
  Single = 'single',
  Full = 'full',
}

/** Message type for above message */
export enum DashboardResponseStatusMessageTypeEnum {
  Info = 'info',
  Success = 'success',
  Warning = 'warning',
  Danger = 'danger',
}

/** @format string */
export enum PromiseReportItemStatusEnum {
  UNFULFILLED = 'UNFULFILLED',
  PENDING = 'PENDING',
  FULFILLED = 'FULFILLED',
  CANCELLED = 'CANCELLED',
}

export enum YearDetailsStatusEnum {
  Ok = 'ok',
  Approaching = 'approaching',
  Reached = 'reached',
}

export enum HoldingsTaxesIconEnum {
  Info = 'info',
  Error = 'error',
}

export enum HoldingsGrantTransactionTypeEnum {
  Issuance = 'Issuance',
  Exercise = 'Exercise',
  Settlement = 'Settlement',
  Transfer = 'Transfer',
  Sale = 'Sale',
  Repurchase = 'Repurchase',
  Forfeiture = 'Forfeiture',
  Acceleration = 'Acceleration',
  Split = 'Split',
  Investment = 'Investment',
  Conversion = 'Conversion',
  Exchange = 'Exchange',
  Dividend = 'Dividend',
  Taxes = 'Taxes',
  Other = 'Other',
}

export enum HoldingsInvestmentTransactionTypeEnum {
  Issuance = 'Issuance',
  Exercise = 'Exercise',
  Settlement = 'Settlement',
  Transfer = 'Transfer',
  Sale = 'Sale',
  Repurchase = 'Repurchase',
  Forfeiture = 'Forfeiture',
  Acceleration = 'Acceleration',
  Split = 'Split',
  Investment = 'Investment',
  Conversion = 'Conversion',
  Exchange = 'Exchange',
  Dividend = 'Dividend',
  Other = 'Other',
}

export enum HoldingsEquityGrantStatusEnum {
  Transferred = 'Transferred',
  Canceled = 'Canceled',
  AwaitingAcceptance = 'Awaiting Acceptance',
  Approved = 'Approved',
  Active = 'Active',
  Expired = 'Expired',
  Inactive = 'Inactive',
}

export enum HoldingsInvestmentStatusEnum {
  Active = 'Active',
  Pending = 'Pending',
  Inactive = 'Inactive',
}

export enum HoldingsInvestmentTypeEnum {
  EquityFinancing = 'Equity Financing',
  Investment = 'Investment',
  SecondarySale = 'Secondary Sale',
  Transfer = 'Transfer',
  CommonStockSale = 'Common Stock Sale',
  Dividend = 'Dividend',
}

export enum HoldingsConvertibleInstrumentStatusEnum {
  Active = 'Active',
  Inactive = 'Inactive',
  Converted = 'Converted',
  Closed = 'Closed',
  PendingClose = 'Pending Close',
}

export enum HoldingsVestingScheduleTypeEnum {
  Options = 'options',
  IsoNqo = 'iso-nqo',
  Rsu = 'rsu',
}

export enum HoldingsVestingScheduleStatusEnum {
  Vested = 'vested',
  Scheduled = 'scheduled',
  ConditionUnmet = 'condition-unmet',
  RsuVested = 'rsu-vested',
  RsuDistributed = 'rsu-distributed',
  EstimatedTaxes = 'estimated-taxes',
}

/** @format string */
export enum UtilizationCategoryItemTitleEnum {
  Reservation = 'Reservation',
  Forfeiture = 'Forfeiture',
  Repurchase = 'Repurchase',
  Grant = 'Grant',
}

/** @default "full" */
export enum CaptableOptionsModeEnum {
  Full = 'full',
  Summary = 'summary',
}

export enum FundraisingReportSummaryEventDetailTypeEnum {
  String = 'string',
  Number = 'number',
  Date = 'date',
  Currency = 'currency',
}

export enum FundraisingReportSummaryEventInvestmentTypeEnum {
  BridgeNote = 'bridge-note',
  Npa = 'npa',
  Safe = 'safe',
  Kiss = 'kiss',
  Preferred = 'preferred',
  Common = 'common',
  Unknown = 'unknown',
}

/** Type of the log in to perform. "login" is a regular login, "signup" is used when a brand new user without established credentials accessing the login page. */
export enum AuthConfigResponseLoginTypeEnum {
  Login = 'login',
  Signup = 'signup',
}

/**
 * When identification successful, the next step to for the user to take
 * @default null
 */
export enum AuthIdentifyResponseNextEnum {
  Completed = 'completed',
  Login = 'login',
  Signup = 'signup',
  Abort = 'abort',
}

export enum AuthConvertResponseNextEnum {
  Login = 'login',
  Merge = 'merge',
  Consent = 'consent',
  Converted = 'converted',
  Completed = 'completed',
}

/** When synchronizing, prefer gustoValue, shoobxValue, values match or values cannot be synced automatically? */
export enum GustoEmployeeDiffFieldPreferEnum {
  Gusto = 'gusto',
  Shoobx = 'shoobx',
  Match = 'match',
  Nosync = 'nosync',
}

/** Keep this in sync with interfaces.SignatureMethod */
export enum SignatureUpdateMethodEnum {
  LowresDrawn = 'lowres_drawn',
  Upload = 'upload',
  Type = 'type',
}

/** Health status */
export enum HealthStatusEnum {
  Ok = 'ok',
  Warning = 'warning',
  Critical = 'critical',
}

export enum TaxReportItemEventTypeEnum {
  Grant = 'Grant',
  Exercise = 'Exercise',
  Vesting = 'Vesting',
  Distribution = 'Distribution',
  Dividend = 'Dividend',
  Other = 'Other',
}

/** 83(b) Election Filing title */
export enum TaxReportItemStatus83BEnum {
  NA = 'N/A',
  Filed = 'Filed',
  NotFiled = 'Not Filed',
}

/** 83(b) Election Filing sub-title */
export enum TaxReportItemSubstatus83BEnum {
  Assumed = 'Assumed',
  InProgress = 'In Progress',
  Complete = 'Complete',
}

/** Sorting order */
export enum WebapiV2BillingticketsListDataParamsOrderEnum {
  Asc = 'asc',
  Desc = 'desc',
}

/** Sorting order */
export enum WebapiV2EmployeeListDataParamsOrderEnum {
  Asc = 'asc',
  Desc = 'desc',
}

/** Sorting order */
export enum WebapiV2StakeholdersListDataParamsOrderEnum {
  Asc = 'asc',
  Desc = 'desc',
}

/** Sort order */
export enum WebapiV2EquityTransactionsExercisesParamsSortOrderEnum {
  Asc = 'asc',
  Desc = 'desc',
}

/** Sort order */
export enum WebapiV2EquityTransactionsRepurchasesParamsSortOrderEnum {
  Asc = 'asc',
  Desc = 'desc',
}

/** Sort order */
export enum WebapiV2EquityTransactionsTransfersParamsSortOrderEnum {
  Asc = 'asc',
  Desc = 'desc',
}

/** Sort order */
export enum WebapiV2EquityTransactionsFile83BParamsSortOrderEnum {
  Asc = 'asc',
  Desc = 'desc',
}

/** Sort order */
export enum WebapiV2EquityTransactionsForfeituresParamsSortOrderEnum {
  Asc = 'asc',
  Desc = 'desc',
}

/** Sort order */
export enum WebapiV2EquityReportsPromiseParamsOrderEnum {
  Asc = 'asc',
  Desc = 'desc',
}

/** Reporting Frequency */
export enum WebapiV2EquitySiputilizationParamsReportingFrequencyEnum {
  Annual = 'annual',
  Quarterly = 'quarterly',
}

/** Reporting Frequency */
export enum WebapiV2EquitySiputilizationExportParamsReportingFrequencyEnum {
  Annual = 'annual',
  Quarterly = 'quarterly',
}

/** Sorting order */
export enum WebapiV2ProcessesListDataParamsOrderEnum {
  Asc = 'asc',
  Desc = 'desc',
}

/** Sorting order */
export enum WebapiV2EntityrelationshipsListDataParamsOrderEnum {
  Asc = 'asc',
  Desc = 'desc',
}

/** Sorting order */
export enum WebapiV2SupportticketsListDataParamsOrderEnum {
  Asc = 'asc',
  Desc = 'desc',
}
