import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';

@Component({
  selector: 'sbx-auth-agreement-page',
  templateUrl: './sbx-auth-agreement-page.component.html',
  styleUrls: ['./sbx-auth-agreement-page.component.scss'],
})
export class SbxAuthAgreementPageComponent implements OnInit {
  public agreementForm: FormGroup;

  constructor(
    private readonly router: Router,
    private readonly fb: FormBuilder,
  ) {}

  public ngOnInit(): void {
    this.initForm();
  }

  public takeAgreementAndContinue(): void {
    this.router.navigate(['/auth/signup-complete'], {
      queryParams: { agreeToTerms: this.agreementForm.value.agreeToTerms },
    });
  }

  private initForm(): void {
    this.agreementForm = this.fb.group({
      agreeToTerms: [false, Validators.required],
    });
  }
}
