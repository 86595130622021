import { Injectable, Inject } from '@angular/core';
import { SbxHttpClient, BackendLocation } from '@/core/http';
import { SbxFormModalService } from '@/shared/sbx-form-modal/sbx-form-modal.service';
import {
  PlannerStateResponse,
  LiquidationStack,
  GenericDeleteResponse,
  GenericStatusResponse,
  PlannerScenarioOutcomes,
  ExitInfo,
  InvestorModal,
  GetGraphPointResult,
} from '@shoobx/types/plannerapi-v1';
import { DownloadResponse } from '@shoobx/types/pieapi-v1';
import { AsyncTasks } from '../../../js/lib/tasks/service';

export enum ScenarioTypes {
  ScenarioA = 'scenarioA',
  ScenarioB = 'scenarioB',
}

@Injectable()
export class SbxExitScenarioPlannerService {
  API_VERSION = '';
  BASE_URL = '';

  constructor(
    @Inject(SbxHttpClient) public sbxHttpClient: SbxHttpClient,
    @Inject(SbxFormModalService) public sbxFormModalService: SbxFormModalService,
    @Inject(BackendLocation) public backendLocation: BackendLocation,
    @Inject(AsyncTasks) public asyncTasks,
  ) {}

  initialize(apiVersion: string, baseUrl: string) {
    this.API_VERSION = apiVersion;
    this.BASE_URL = baseUrl;
  }

  startSession() {
    return this.sbxHttpClient
      .entity(this.API_VERSION)
      .post<GenericStatusResponse>(`${this.BASE_URL}/startSession`)
      .toPromise();
  }

  saveSession() {
    return this.sbxHttpClient
      .entity(this.API_VERSION)
      .post<GenericStatusResponse>(`${this.BASE_URL}/saveSession`)
      .toPromise();
  }

  closeSession() {
    return this.sbxHttpClient
      .entity(this.API_VERSION)
      .post<GenericStatusResponse>(`${this.BASE_URL}/closeSession`)
      .toPromise();
  }

  reset() {
    return this.sbxHttpClient
      .entity(this.API_VERSION)
      .get<GenericStatusResponse>(`${this.BASE_URL}/reset`)
      .toPromise();
  }

  getState() {
    return this.sbxHttpClient
      .entity(this.API_VERSION)
      .get<PlannerStateResponse>(`${this.BASE_URL}/getState`);
  }

  getConversionPoints() {
    return this.sbxHttpClient
      .entity(this.API_VERSION)
      .get<PlannerScenarioOutcomes>(`${this.BASE_URL}/conversionPoints`);
  }

  getGraphpoint(params: ExitInfo) {
    return this.sbxHttpClient
      .entity(this.API_VERSION)
      .post<GetGraphPointResult>(`${this.BASE_URL}/graphPoint`, {
        params,
      });
  }

  async export(): Promise<string> {
    const data = await this.sbxHttpClient
      .entity(this.API_VERSION)
      .get<DownloadResponse>(`${this.BASE_URL}/xlsExport`)
      .toPromise();

    if (data.mode === 'file') {
      return data.file.downloadUrl;
    }

    return new Promise((resolve) =>
      this.asyncTasks.resultOfId(data.taskId).subscribe((res) => {
        if (res.state === 'FAILED') {
          resolve(null);
        }

        if (res.state === 'DONE') {
          resolve(res.result.url);
        }
      }),
    );
  }

  setParams(params: ExitInfo) {
    return this.sbxHttpClient
      .entity(this.API_VERSION)
      .post<GenericStatusResponse>(`${this.BASE_URL}/params`, {
        params,
      });
  }

  getAddStockClassFormUrl() {
    return `${this.BASE_URL}/stockclass/create`;
  }

  getEditStockClassFormUrl(stockClassId: string) {
    return `${this.BASE_URL}/stockclass/${stockClassId}/edit`;
  }

  removeStockClass(stockClassId: string) {
    return this.sbxHttpClient
      .entity(this.API_VERSION)
      .delete<GenericDeleteResponse>(`${this.BASE_URL}/stockclass/${stockClassId}`)
      .toPromise();
  }

  getLiquidationStack() {
    return this.sbxHttpClient
      .entity(this.API_VERSION)
      .get<LiquidationStack>(`${this.BASE_URL}/liquidationStack`);
  }

  saveLiquidationStack(stack: LiquidationStack) {
    return this.sbxHttpClient
      .entity(this.API_VERSION)
      .post<GenericStatusResponse>(`${this.BASE_URL}/liquidationStack`, {
        params: {
          stack,
        },
      });
  }

  getSettingsEditFormUrl() {
    return `${this.BASE_URL}/settings`;
  }

  getInvestmentEditFormUrl(investorId: string) {
    return `${this.BASE_URL}/investors/${investorId}/edit`;
  }

  getDebtEditFormUrl(conversionId: string) {
    return `${this.BASE_URL}/conversions/${conversionId}/edit`;
  }

  getHypotheticalDebtEditFormUrl(conversionId: string) {
    return `${this.BASE_URL}/conversions/${conversionId}/editHypothetical`;
  }

  getNonConvertibleDebtEditFormUrl() {
    return `${this.BASE_URL}/debtFees`;
  }

  getAddInvestorFormUrl() {
    return `${this.BASE_URL}/investors/create`;
  }

  getAddConvertibleFormUrl() {
    return `${this.BASE_URL}/conversions/create`;
  }

  getInvestor(investorId: string) {
    return this.sbxHttpClient
      .entity(this.API_VERSION)
      .get<InvestorModal>(`${this.BASE_URL}/investors/${investorId}/edit`);
  }

  saveInvestor(investorId: string, params) {
    return this.sbxHttpClient
      .entity(this.API_VERSION)
      .post<GenericStatusResponse>(`${this.BASE_URL}/investors/${investorId}/edit`, {
        params,
      });
  }

  removeInvestor(investorId: string) {
    return this.sbxHttpClient
      .entity(this.API_VERSION)
      .delete<GenericDeleteResponse>(`${this.BASE_URL}/investors/${investorId}`)
      .toPromise();
  }

  convertEverything(convert: boolean) {
    const params = { convert: convert };
    return this.sbxHttpClient
      .entity(this.API_VERSION)
      .post<GenericStatusResponse>(`${this.BASE_URL}/conversions/convertEverything`, {
        params,
      })
      .toPromise();
  }

  public convertSingle(
    conversionId: number,
    converts: boolean,
  ): Promise<GenericStatusResponse> {
    const params = { converts: converts };
    return this.sbxHttpClient
      .entity(this.API_VERSION)
      .post<GenericStatusResponse>(
        `${this.BASE_URL}/conversions/${conversionId}/update`,
        {
          params,
        },
      )
      .toPromise();
  }

  removeConvertible(convertibleId: string) {
    return this.sbxHttpClient
      .entity(this.API_VERSION)
      .delete<GenericDeleteResponse>(`${this.BASE_URL}/conversions/${convertibleId}`)
      .toPromise();
  }
}
