import { Plugin } from '@ckeditor/ckeditor5-core';
import CustomSpanStyleEditing from './customSpanStyleEditing';
import CustomSpanStyleUI from './customSpanStyleUI';

export default class CustomSpanStyle extends Plugin {
  /**
   * @inheritDoc
   */
  static get requires() {
    return [CustomSpanStyleEditing, CustomSpanStyleUI];
  }
  /**
   * @inheritDoc
   */
  static get pluginName() {
    return 'CustomSpanStyle';
  }
}
