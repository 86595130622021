import { NgModule } from '@angular/core';
import { UpgradedModule } from './upgraded/upgraded.module';
import { SbxPipesModule } from './pipes/pipes.module';
import { SbxButtonGroupModule } from './sbx-button-group/sbx-button-group.module';
import { SbxButtonModule } from './sbx-button/sbx-button.module';
import { SbxCarouselModule } from './sbx-carousel/sbx-carousel.module';
import { SbxDropdownModule } from './sbx-dropdown/sbx-dropdown.module';
import { SbxGridModule } from './sbx-grid/sbx-grid.module';
import { SbxIconModule } from './sbx-icon/sbx-icon.module';
import { SbxMenuListModule } from './sbx-menu-list/sbx-menu-list.module';
import { SbxModalModule } from './sbx-modal/sbx-modal.module';
import { SbxPopoverModule } from './sbx-popover/sbx-popover.module';
import { SbxScrollableModule } from './sbx-scrollable/sbx-scrollable.module';
import { SbxSlideToggleModule } from './sbx-slide-toggle/sbx-slide-toggle.module';
import { SbxTitleModule } from './sbx-title/sbx-title.module';
import { SbxScrollableListModule } from './sbx-scrollable-list/sbx-scrollable-list.module';
import { SbxSearchBarModule } from './sbx-search-bar/sbx-search-bar.module';
import { SbxTableModule } from './sbx-table/sbx-table.module';
import { SbxRecaptchaModule } from './sbx-recaptcha/sbx-recaptcha.module';
import { SbxSideNavModule } from './sbx-side-nav/sbx-side-nav.module';
import { SbxTopNavModule } from './sbx-top-nav/sbx-top-nav.module';
import { SbxRadioGroupModule } from './sbx-radio-group/sbx-radio-group.module';
import { SbxRadioModule } from './sbx-radio/sbx-radio.module';
import { SbxProcessStatusButtonModule } from './sbx-process-status-button/sbx-process-status-button.module';
import { SbxFormModule } from './sbx-form/sbx-form.module';
import { SbxStakeholderSearchModule } from './sbx-stakeholder-search/sbx-stakeholder-search.module';
import { SbxFormModalModule } from './sbx-form-modal/sbx-form-modal.module';
import { SbxSelectModule } from './sbx-select/sbx-select.module';
import { SbxPageHeaderModule } from './sbx-page-header/sbx-page-header.module';
import { SbxTreeModule } from './sbx-tree/sbx-tree.module';
import { SbxActionModule } from './sbx-action-menu/sbx-action-menu.module';
import { SbxCheckboxModule } from './sbx-checkbox/sbx-checkbox.module';
import { SbxStickyModule } from './sbx-sticky/sbx-sticky.module';
import { SbxAlertBannerModule } from './sbx-alert-banner/sbx-alert-banner.module';
import { SbxLoaderModule } from './sbx-loader/sbx-loader.module';
import { SbxDocumentReferenceModule } from './sbx-document-reference/sbx-document-reference.module';
import { SbxDocumentCardModule } from './sbx-document-card/sbx-document-card.module';
import { SbxClickOutsideModule } from './sbx-click-outside/sbx-click-outside.module';
import { SbxGestureModule } from './sbx-gesture/sbx-gesture.module';
import { SbxEditStakeholderModule } from './sbx-edit-stakeholder/sbx-edit-stakeholder.module';
import { SbxInviteStakeholderModule } from './sbx-invite-stakeholder/sbx-invite-stakeholder.module';
import { SbxConfirmationModalModule } from './sbx-confirmation-modal/sbx-confirmation-modal.module';
import { SbxProcessSummaryModule } from './sbx-process-summary/sbx-process-summary.module';
import { SbxCollapsableModule } from './sbx-collapsable/sbx-collapsable.module';
import { SbxProgressBarModule } from './sbx-progress-bar/sbx-progress-bar.module';
import { SbxProcessActionModule } from './sbx-process-action/sbx-process-action.module';
import { SbxCounselInfoModule } from './sbx-counsel-info/sbx-counsel-info.module';
import { SbxPropertiesListModule } from './sbx-properties-list/sbx-properties-list.module';
import { SbxPropertiesListTemplatedModule } from './sbx-properties-list-templated/sbx-properties-list-templated.module';
import { SbxChartModule } from './sbx-chart/sbx-chart.module';
import { SbxTimelineModule } from './sbx-timeline/sbx-timeline.module';
import { SbxEditorModule } from './sbx-editor/sbx-editor.module';
import { SbxWorkitemContentModule } from './sbx-workitem-content/sbx-workitem-content.module';
import { SbxDraggableListModule } from './sbx-draggable-list/sbx-draggable-list.module';
import { SbxExhibitListModule } from './sbx-exhibit-list/sbx-exhibit-list.module';
import { SbxWiPanelGroupModule } from './sbx-wi-panel-group/sbx-wi-panel-group.module';
import { SbxSaveFooterModule } from './sbx-save-footer/sbx-save-footer.module';
import { SbxPageModule } from './sbx-page/sbx-page.module';
import { SbxPagingModule } from './sbx-paging/sbx-paging.module';
import { SbxActivityLogModalModule } from './sbx-activity-log-modal/sbx-activity-log-modal.module';
import { SbxDropzoneModule } from './sbx-dropzone/sbx-dropzone.module';
import { SbxStakeholderCardModule } from './sbx-stakeholder-card/sbx-stakeholder-card.module';
import { SbxStakeholderCardListModule } from './sbx-stakeholder-card-list/sbx-stakeholder-card-list.module';
import { SbxListingModule } from './sbx-listing/sbx-listing.module';
import { SbxPrincipalChooserModule } from './sbx-principal-chooser/sbx-principal-chooser.module';
import { SbxCollapsibleTableModule } from './sbx-collapsible-table/sbx-collapsible-table.module';
import { SbxCaptableDownloadModule } from './sbx-captable-download/sbx-captable-download.module';
import { SbxMessageModule } from '@/shared/sbx-message/sbx-message.module';
import { SbxEquityStructureTreeMapModule } from '@/shared/sbx-equity-structure-tree-map/sbx-equity-structure-tree-map.module';
import { SbxTabsLayoutModule } from '@/shared/sbx-tabs-layout/sbx-tabs-layout.module';
import { SbxUploadMissingDocumentModule } from './sbx-upload-missing-document/sbx-upload-missing-document.module';
import { SbxImageUploadModalModule } from './sbx-image-upload-modal/sbx-image-upload-modal.module';
import { SbxHotLinksModule } from './sbx-hot-links/sbx-hot-links.module';
import { SbxSignatureModalModule } from './sbx-signature-modal/sbx-signature-modal.module';
import { SbxDocumentModule } from './sbx-document/sbx-document.module';
import { SbxResponsiveNavModule } from './sbx-responsive-nav/sbx-responsive-nav.module';
import { SbxNavigationModule } from './sbx-navigation/sbx-navigation.module';
import { SbxPdfViewerModule } from './sbx-pdf-viewer/sbx-pdf-viewer.module';
import { SbxSearchCompanyModule } from '@/shared/sbx-search-company/sbx-search-company.module';

@NgModule({
  exports: [
    UpgradedModule,
    SbxPipesModule,
    SbxButtonGroupModule,
    SbxButtonModule,
    SbxCheckboxModule,
    SbxCarouselModule,
    SbxClickOutsideModule,
    SbxDropdownModule,
    SbxGestureModule,
    SbxGridModule,
    SbxIconModule,
    SbxMenuListModule,
    SbxModalModule,
    SbxFormModalModule,
    SbxPopoverModule,
    SbxScrollableModule,
    SbxSlideToggleModule,
    SbxTitleModule,
    SbxScrollableListModule,
    SbxSearchBarModule,
    SbxTableModule,
    SbxRecaptchaModule,
    SbxSideNavModule,
    SbxTopNavModule,
    SbxRadioGroupModule,
    SbxRadioModule,
    SbxProcessStatusButtonModule,
    SbxFormModule,
    SbxStakeholderSearchModule,
    SbxSelectModule,
    SbxPageHeaderModule,
    SbxTreeModule,
    SbxActionModule,
    SbxStickyModule,
    SbxAlertBannerModule,
    SbxLoaderModule,
    SbxDocumentReferenceModule,
    SbxDocumentCardModule,
    SbxEditStakeholderModule,
    SbxInviteStakeholderModule,
    SbxFormModalModule,
    SbxConfirmationModalModule,
    SbxProcessSummaryModule,
    SbxCollapsableModule,
    SbxProgressBarModule,
    SbxProcessActionModule,
    SbxCounselInfoModule,
    SbxPropertiesListModule,
    SbxPropertiesListTemplatedModule,
    SbxChartModule,
    SbxTimelineModule,
    SbxEditorModule,
    SbxWorkitemContentModule,
    SbxDraggableListModule,
    SbxExhibitListModule,
    SbxWiPanelGroupModule,
    SbxSaveFooterModule,
    SbxPageModule,
    SbxPagingModule,
    SbxActivityLogModalModule,
    SbxDropzoneModule,
    SbxStakeholderCardModule,
    SbxStakeholderCardListModule,
    SbxListingModule,
    SbxPrincipalChooserModule,
    SbxCollapsibleTableModule,
    SbxCaptableDownloadModule,
    SbxMessageModule,
    SbxEquityStructureTreeMapModule,
    SbxTabsLayoutModule,
    SbxUploadMissingDocumentModule,
    SbxImageUploadModalModule,
    SbxHotLinksModule,
    SbxSignatureModalModule,
    SbxDocumentModule,
    SbxResponsiveNavModule,
    SbxNavigationModule,
    SbxPdfViewerModule,
    SbxSearchCompanyModule,
  ],
})
export class SharedModule {}
