import { Plugin } from '@ckeditor/ckeditor5-core';
import {
  toWidget,
  viewToModelPositionOutsideModelElement,
  Widget,
} from '@ckeditor/ckeditor5-widget';
import { ClickObserver } from '@ckeditor/ckeditor5-engine';
const UNKNOWN_ID = 'unknown';
import {
  PlaceholderCommand,
  PlaceholderUI,
  MouseOverObserver,
  MouseOutObserver,
} from './Placeholder';

function getPillClass({
  modelName,
  className,
  attributes,
  downcastMapper,
  upcastMapper,
  click,
  elementName,
}) {
  const element = elementName;
  attributes = [...attributes, 'tooltipTitle', 'tooltipContent'];

  class BasePill extends Plugin {
    static get requires() {
      return [Widget];
    }

    init() {
      this._defineSchema();
      this._defineConverters();
      this.editor.commands.add(modelName, new PlaceholderCommand(this.editor));

      this.editor.editing.mapper.on(
        'viewToModelPosition',
        viewToModelPositionOutsideModelElement(this.editor.model, (viewElement) => {
          return viewElement.hasClass(className);
        }),
      );

      this.registerHoverHandler();

      if (click) {
        this.registerClickHandler();
      }
    }

    registerHoverHandler() {
      this.editor.editing.view.addObserver(MouseOverObserver);
      this.listenTo(this.editor.editing.view.document, 'mouseover', (event, data) => {
        if (data.target.hasClass(className)) {
          const tooltipTitle = data.target.getAttribute('tooltipTitle');
          const tooltipContent = data.target.getAttribute('tooltipContent');
          const el = this.editor.editing.view.domConverter.mapViewToDom(data.target);

          if (tooltipTitle || tooltipContent) {
            // eslint-disable-next-line no-extra-parens
            (<any>$(el)).popover({
              container: 'body',
              trigger: 'hover',
              placement: 'auto',
              title: tooltipTitle === 'undefined' ? '' : tooltipTitle,
              content: tooltipContent === 'undefined' ? '' : tooltipContent,
            });
            // eslint-disable-next-line no-extra-parens
            (<any>$(el)).popover('show');
          }
        }
      });

      this.editor.editing.view.addObserver(MouseOutObserver);
      this.listenTo(this.editor.editing.view.document, 'mouseout', (event, data) => {
        if (data.target.hasClass(className)) {
          const el = this.editor.editing.view.domConverter.mapViewToDom(data.target);
          // eslint-disable-next-line no-extra-parens
          (<any>$(el)).popover('destroy');
        }
      });

      this.listenTo(this.editor.editing.view.document, 'keydown', () => {
        const el = document.querySelector('.popover');
        if (el) {
          el.parentNode.removeChild(el);
        }
      });
    }

    registerClickHandler() {
      this.editor.editing.view.addObserver(ClickObserver);

      this.listenTo(this.editor.editing.view.document, 'click', (event, data) => {
        if (data.target.hasClass(className)) {
          click(event, data, this.editor);
        }
      });
    }

    _defineSchema() {
      const schema = this.editor.model.schema;

      schema.register(modelName, {
        allowWhere: '$text',
        isInline: true,
        isObject: true,
        allowAttributes: [...attributes, 'innerHTML', 'className'],
      });
    }

    _defineConverters() {
      const conversion = this.editor.conversion;

      conversion.for('upcast').elementToElement({
        view: {
          name: element,
          classes: [className],
        },
        model: (viewElement, conversionApi) => {
          const attrs = {
            innerHTML: '',
            className: {},
          };
          attrs.innerHTML = viewElement.getChild(0)
            ? (viewElement.getChild(0) as any).data
            : '';
          attrs.className = Array.from(viewElement.getClassNames())[0];
          attributes.forEach((attribute) => {
            attrs[attribute] = viewElement.getAttribute(attribute);
          });
          return conversionApi.writer.createElement(
            modelName,
            upcastMapper(attrs, this.editor),
          );
        },
      });

      conversion.for('editingDowncast').elementToElement({
        model: modelName,
        view: (modelItem, conversionApi) => {
          const widgetElement = createPlaceholderView(
            modelItem,
            conversionApi,
            this.editor,
          );

          return toWidget(widgetElement, conversionApi.writer);
        },
      });

      conversion.for('dataDowncast').elementToElement({
        model: modelName,
        view: createPlaceholderView,
      });

      function createPlaceholderView(modelItem, conversionApi, editor) {
        const attrs = {
          innerHTML: '',
        };
        attrs.innerHTML = modelItem.getAttribute('innerHTML')
          ? modelItem.getAttribute('innerHTML')
          : '';
        attributes.forEach((attribute) => {
          attrs[attribute] = modelItem.getAttribute(attribute);
        });
        const mappedAttrs = downcastMapper(attrs, editor);

        const placeholderView = conversionApi.writer.createContainerElement(element, {
          class: className,
          ...mappedAttrs,
        });

        const text = conversionApi.writer.createText(mappedAttrs.innerHTML);
        conversionApi.writer.insert(
          conversionApi.writer.createPositionAt(placeholderView, 0),
          text,
        );

        return placeholderView;
      }
    }
  }

  return BasePill;
}

export { getPillClass, PlaceholderUI };

// TODO: Move to TermPill.ts
class TermPill extends Plugin {
  static get requires() {
    return [
      getPillClass({
        modelName: 'termPills',
        className: 'metadata-placeholder',
        attributes: ['data-field', 'data-title', 'data-formatter', 'data-field-ref'],
        elementName: 'span',
        downcastMapper: (attributes, editor) => {
          return Object.keys(attributes)
            .filter((key) => attributes[key] !== undefined)
            .reduce((acc, key) => ({ ...acc, [key]: attributes[key] }), {});
        },
        upcastMapper: (attributes, editor) => {
          const format =
            attributes['data-formatter'] === 'undefined'
              ? 'default'
              : attributes['data-formatter'];

          const externalData = editor.config.get('termPills');
          if (!externalData) {
            return attributes;
          }

          const { values } = externalData.data();
          const key = attributes['data-field'];

          function decodeHTML(html) {
            const doc = new DOMParser().parseFromString(html, 'text/html');
            return doc.documentElement.textContent;
          }
          const term = values[key];
          const termFormat = term.formats.find((f) => f.format === format);
          const value = decodeHTML(termFormat.value) || `<${term.title}>`;
          attributes['data-formatter'] = format;
          attributes.innerHTML = value.replace(/<br \/>/g, '\n');
          attributes.tooltipTitle = term.title;
          attributes.tooltipContent = termFormat.value
            ? decodeHTML(termFormat.value).replace(/<br \/>/g, '\n')
            : 'No value yet';

          return attributes;
        },
        click: (event, data, editor) => {
          editor.execute('termPills', { target: data.target });
        },
      }),
      PlaceholderUI,
    ];
  }
}

export { TermPill };

// TODO: Move to RefPill.ts
class RefPill extends Plugin {
  static get requires() {
    return [
      getPillClass({
        modelName: 'refPills',
        className: 'richtext-reference-pill',
        attributes: [
          'data-apply-styling',
          'data-reference-id',
          'data-is-single',
          'data-ref-format',
        ],
        elementName: 'span',
        downcastMapper: (attributes, editor) => {
          return Object.keys(attributes)
            .filter((key) => attributes[key] !== undefined)
            .reduce((acc, key) => ({ ...acc, [key]: attributes[key] }), {});
        },
        upcastMapper: (attributes, editor) => {
          attributes['data-is-single'] =
            attributes['data-is-single'] === 'undefined'
              ? 'false'
              : attributes['data-is-single'];

          const externalData = editor.config.get('refPills');
          if (!externalData) {
            return attributes;
          }

          const { references } = externalData.data();
          const key = attributes['data-reference-id'];
          const isSingle = attributes['data-is-single'];
          const format = attributes['data-ref-format'];
          const reference = references.find((r) => r.value === key);
          if (reference) {
            const ref = isSingle === 'true' ? reference.singleRef : reference.ref;
            attributes.innerHTML =
              format !== undefined && format !== 'undefined'
                ? format.replace('%s', ref.replace(/\./g, ''))
                : ref;
            attributes.tooltipContent = reference.title;
          }
          return attributes;
        },
        click: (event, data, editor) => {
          editor.execute('refPills', { target: data.target });
        },
      }),
      PlaceholderUI,
    ];
  }
}

export { RefPill };

// TODO: Move to AnchorPill.ts
class AnchorPill extends Plugin {
  static get requires() {
    return [
      getPillClass({
        modelName: 'anchorPill',
        className: 'anchor',
        attributes: ['data-reference-id', 'data-title'],
        elementName: 'span',
        downcastMapper: (attributes, editor) => {
          return Object.keys(attributes)
            .filter((key) => attributes[key] !== undefined)
            .reduce((acc, key) => ({ ...acc, [key]: attributes[key] }), {});
        },
        upcastMapper: (attributes, editor) => {
          return attributes;
        },
        // eslint-disable-next-line @typescript-eslint/no-empty-function
        click: () => {},
      }),
      PlaceholderUI,
    ];
  }
}

export { AnchorPill };

// TODO: Move to StaticPill.ts
class StaticPill extends Plugin {
  static get requires() {
    return [
      getPillClass({
        modelName: 'staticPill',
        className: 'dynamic-content',
        attributes: ['data-field', 'data-title', 'data-expr', 'data-field-ref'],
        elementName: 'span',
        downcastMapper: (attributes, editor) => {
          return Object.keys(attributes)
            .filter((key) => attributes[key] !== undefined)
            .reduce((acc, key) => ({ ...acc, [key]: attributes[key] }), {});
        },
        upcastMapper: (attributes, editor) => {
          attributes.tooltipContent =
            attributes['data-title'] === 'undefined'
              ? 'Dynamic Content'
              : attributes['data-title'];

          return attributes;
        },
        // eslint-disable-next-line @typescript-eslint/no-empty-function
        click: () => {},
      }),
      PlaceholderUI,
    ];
  }
}

export { StaticPill };

// TODO: Move to ExhibitPill.ts
class ExhibitPill extends Plugin {
  static get requires() {
    return [
      getPillClass({
        modelName: 'exhibitPills',
        className: 'richtext-exhibit-pill',
        attributes: ['data-exhibit-id', 'data-is-assigned', 'selected'],
        elementName: 'span',
        downcastMapper: (attributes, editor) => {
          attributes.innerHTML = 'Exhibit 📎';
          attributes.selected = 'false';
          return Object.keys(attributes)
            .filter((key) => attributes[key] !== undefined)
            .reduce((acc, key) => ({ ...acc, [key]: attributes[key] }), {});
        },
        upcastMapper: (attributes, editor) => {
          const externalData = editor.config.get('exhibitPills');
          if (!externalData) {
            return attributes;
          }
          const { exhibits } = externalData.data();
          const index = exhibits.findIndex((exbibit) => {
            return exbibit.id === attributes['data-exhibit-id'];
          });
          if (index === -1) {
            attributes.innerHTML = 'Exhibit 📎';
            attributes['data-exhibit-id'] = UNKNOWN_ID;
            attributes['data-is-assigned'] = false;
            attributes.tooltipContent = 'Click to link exhibit document';
          } else {
            attributes.innerHTML = 'Exhibit 📎';
            attributes['data-is-assigned'] = true;
            attributes.tooltipContent = exhibits[index].title;
          }
          attributes.tooltipTitle = '';
          return attributes;
        },
        click: (event, data, editor) => {
          const externalData = editor.config.get('exhibitPills');
          if (externalData) {
            editor.execute('exhibitPills', { target: data.target });
          }
        },
      }),
      PlaceholderUI,
    ];
  }
}

export { ExhibitPill };
