<div class="sbx-auth-overlay">
  <div class="sbx-auth-overlay-container">
    <div class="sbx-auth-header">
      <div class="sbx-auth-header-title" *ngIf="title">{{ title }}</div>
      <div class="sbx-auth-header-close">
        <img
          [src]="sbxUrlService.assetUrl('img/fidelity/svg/close.svg')"
          alt="close"
          (click)="closeOverlay()"
        />
      </div>
    </div>
    <ng-content select=".sbx-auth-overlay-content"></ng-content>
  </div>
</div>
