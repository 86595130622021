import { SbxAuthService } from '@/sbx-auth/sbx-auth.service';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { take } from 'rxjs';

@Component({
  selector: 'sbx-auth-email-input',
  templateUrl: './sbx-auth-email-input.component.html',
  styleUrls: ['./sbx-auth-email-input.component.scss'],
})
export class SbxAuthEmailInputComponent implements OnInit {
  public emailForm: FormGroup;

  constructor(
    private readonly fb: FormBuilder,
    private readonly sbxAuthService: SbxAuthService,
  ) {}

  public ngOnInit(): void {
    this.initForm();
  }

  public getAvailableIdentificationMethods(): void {
    const email = this.emailForm.value.email;
    this.sbxAuthService.identificationMethods(email).pipe(take(1)).subscribe();
  }

  private initForm(): void {
    this.emailForm = this.fb.group({
      email: ['', [Validators.required, Validators.email]],
    });
  }
}
