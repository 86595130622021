import { Command } from '@ckeditor/ckeditor5-core';

const CUSTOMSTYLE = 'customParaStyle';

export default class CustomParaStyleCommand extends Command {
  refresh() {
    const model = this.editor.model;
    const doc = model.document;
    this.value = doc.selection.getFirstPosition().parent.getAttribute(CUSTOMSTYLE);
    this.isEnabled = model.schema.checkAttributeInSelection(doc.selection, CUSTOMSTYLE);
  }

  execute(options = {}) {
    const model = this.editor.model;
    const document = model.document;
    const selection = document.selection;
    const customStyle = options.value;
    const selectionParent = selection.getFirstPosition().parent;

    model.change((writer) => {
      if (selectionParent.hasAttribute(CUSTOMSTYLE)) {
        if (!customStyle || this.value === customStyle) {
          writer.removeAttribute(CUSTOMSTYLE, selectionParent);
        } else {
          writer.setAttribute(CUSTOMSTYLE, customStyle, selectionParent);
        }
      } else if (customStyle) {
        writer.setAttribute(CUSTOMSTYLE, customStyle, selectionParent);
      }
    });
  }
}
