<div class="sbx-auth-recovery-method-page-content">
  <p class="sbx-auth-main-title">
    How would you like to receive your verification code?
  </p>
  <form [formGroup]="authMethodForm" (ngSubmit)="sendRecoveryRequest()">
    <div
      class="sbx-auth-form-group"
      *ngIf="availableIdentificationMethods$ | async as options"
    >
      <div *ngFor="let option of options">
        <div class="sbx-auth-option">
          <input
            class="sbx-auth-radio"
            formControlName="method"
            type="radio"
            id="{{ option.method }}"
            value="{{ option.method }}"
          />
          <label for="{{ option.method }}">{{ option.description }}</label>
        </div>
      </div>
      <div class="sbx-auth-submit-btn">
        <button
          type="submit"
          class="sbx-auth-primary-button"
          [disabled]="!authMethodForm.valid"
        >
          Continue
        </button>
      </div>
    </div>
  </form>
</div>
