import { InjectionToken, ValueProvider } from '@angular/core';

interface IFontAwesomeIconTypes {
  address: string;
  alert: string;
  anglesDown: string;
  arrowsAlt: string;
  bars: string;
  ban: string;
  binoculars: string;
  birthday: string;
  bookOpen: string;
  book: string;
  briefcase: string;
  briefcaseTime: string;
  building: string;
  buildingDark: string;
  buildingColumns: string;
  check: string;
  circle: string;
  city: string;
  checkCircle: string;
  certificate: string;
  chevronCircleLeft: string;
  chevronCircleRight: string;
  chevronDown: string;
  chevronLeft: string;
  chevronRight: string;
  clipboardList: string;
  clone: string;
  close: string;
  chartLine: string;
  chartPie: string;
  confirmation: string;
  comment: string;
  commentsDollar: string;
  dataRoom: string;
  calendar: string;
  calendarSolid: string;
  date: string;
  delete: string;
  detailGrid: string;
  details: string;
  note: string;
  document: string;
  documentFilled: string;
  dollarSign: string;
  download: string;
  edit: string;
  ellipsis: string;
  envelope: string;
  envelopesBulk: string;
  error: string;
  exclamationTriangle: string;
  exclamationCircle: string;
  flag: string;
  filter: string;
  fileContract: string;
  fileUpload: string;
  fileImport: string;
  folder: string;
  gear: string;
  gears: string;
  glasses: string;
  handshake: string;
  hashtag: string;
  help: string;
  hide: string;
  highlighter: string;
  history: string;
  image: string;
  infoCircle: string;
  information: string;
  iCursor: string;
  key: string;
  listAlt: string;
  listOl: string;
  listUl: string;
  listThick: string;
  lock: string;
  minus: string;
  money: string;
  moneyCheckAlt: string;
  paperclip: string;
  pen: string;
  pending: string;
  plus: string;
  plusCircle: string;
  qrCode: string;
  question: string;
  redo: string;
  questionCircle: string;
  refresh: string;
  rocket: string;
  scale: string;
  search: string;
  server: string;
  sitemap: string;
  share: string;
  show: string;
  sign: string;
  signature: string;
  siteMap: string;
  spinner: string;
  spinner2x: string;
  spinner3x: string;
  spinner5x: string;
  spinner8x: string;
  star: string;
  tasks: string;
  table: string;
  bulkActions: string;
  exercise: string;
  termsheet: string;
  ticket: string;
  time: string;
  trash: string;
  triangleDown: string;
  triangleLeft: string;
  triangleRight: string;
  triangleUp: string;
  undo: string;
  upload: string;
  user: string;
  userEdit: string;
  users: string;
  userAlt: string;
  sortAsc: string;
  sortDesc: string;
  linkedin: string;
  twitter: string;
  facebook: string;
  instagram: string;
  timeline: string;
  arrowLeft: string;
  arrowRight: string;
  arrowLeftRight: string;
  arrowUp: string;
  arrowDown: string;
  arrowLeftCircle: string;
  arrowRightCircle: string;
  arrowLeftCircleAlt: string;
  paste: string;
  twoPeople: string;
  inbox: string;
  gravel: string;
  feedback: string;
  hourglass: string;
  print: string;
  selectAll: string;
  circlePlus: string;
  moneyBill: string;
  copy: string;
  circleUser: string;
  link: string;
  linkSlash: string;
  eye: string;
  eyeSlash: string;
  fileLinesFilled: string;
  fileLines: string;
}

interface IShoobxFontIconTypes {
  shoobx: string;
  shoobxLogo: string;
  docPending: string;
  docGenerated: string;
  docUploaded: string;
  docDeferred: string;
  docCertificate: string;
  childArrow: string;
  slash: string;
  shoobxCircle: string;
  alertCircle: string;
  signatureCircle: string;
  metadataCircle: string;
  boldCheckmarkCircle: string;
  pendingCircle: string;
  verified: string;
  emailVerified: string;
  shoobxVerified: string;
  domainVerified: string;
  personVerified: string;
  carta: string;
}

export const FA_ICONS = {
  // ACTION ICONS
  // Commonly used icons for action buttons and framework elements
  address: 'fas fa-address-book',
  alert: 'fas fa-exclamation-triangle',
  anglesDown: 'fas fa-angles-down',
  arrowsAlt: 'fas fa-arrows-alt',
  bars: 'fas fa-bars',
  ban: 'fas fa-ban',
  binoculars: 'fas fa-binoculars',
  birthday: 'fas fa-birthday-cake',
  bookOpen: 'fas fa-book-open',
  book: 'fas fa-book',
  briefcase: 'fas fa-briefcase',
  briefcaseTime: 'fas fa-business-time',
  building: 'far fa-building',
  buildingDark: 'fas fa-building',
  buildingColumns: 'fas fa-building-columns',
  check: 'fas fa-check',
  circle: 'far fa-circle',
  clipboardList: 'fas fa-clipboard-list',
  city: 'fas fa-city',
  clone: 'far fa-clone',
  close: 'fas fa-times-circle',
  chartLine: 'fas fa-chart-line',
  chartPie: 'fas fa-chart-pie',
  checkCircle: 'fas fa-check-circle',
  certificate: 'fas fa-certificate',
  chevronCircleLeft: 'fas fa-chevron-circle-left',
  chevronCircleRight: 'fas fa-chevron-circle-right',
  chevronDown: 'fas fa-chevron-down',
  chevronLeft: 'fas fa-chevron-left',
  chevronRight: 'fas fa-chevron-right',
  confirmation: 'fas fa-check',
  comment: 'fas fa-comment',
  commentsDollar: 'fas fa-comments-dollar',
  dataRoom: 'fas fa-file-alt',
  calendar: 'far fa-calendar',
  calendarSolid: 'fas fa-calendar',
  date: 'fas fa-calendar-alt',
  delete: 'fas fa-times',
  detailGrid: 'fas fa-th',
  details: 'fas fa-th-list',
  note: 'far fa-sticky-note',
  document: 'far fa-file',
  documentFilled: 'far fa-file-alt',
  dollarSign: 'fas fa-dollar-sign',
  download: 'fas fa-download',
  edit: 'fas fa-edit',
  ellipsis: 'fas fa-ellipsis-h',
  envelope: 'far fa-envelope',
  envelopesBulk: 'fas fa-mail-bulk',
  error: 'fas fa-exclamation',
  exclamationTriangle: 'fas fa-exclamation-triangle',
  exclamationCircle: 'fas fa-exclamation-circle',
  flag: 'fas fa-flag',
  filter: 'fas fa-solid fa-filter',
  fileContract: 'fas fa-file-contract',
  fileUpload: 'fas fa-file-upload',
  fileImport: 'fas fa-file-import',
  folder: 'fas fa-folder',
  gear: 'fas fa-cog',
  gears: 'fas fa-cogs',
  glasses: 'fas fa-glasses',
  handshake: 'fas fa-handshake',
  hashtag: 'fas fa-hashtag',
  help: 'fas fa-question-circle',
  hide: 'far fa-eye-slash',
  highlighter: 'fas fa-highlighter',
  history: 'fas fa-book',
  image: 'fas fa-image',
  infoCircle: 'fas fa-info-circle',
  information: 'fas fa-info',
  iCursor: 'fas fa-i-cursor',
  key: 'fas fa-key',
  listAlt: 'far fa-list-alt',
  listOl: 'fas fa-list-ol',
  listUl: 'fas fa-list',
  listThick: 'fas fa-th-list',
  lock: 'fas fa-lock',
  minus: 'fas fa-minus',
  money: 'far fa-money-bill-alt',
  moneyCheckAlt: 'fas fa-money-check-alt',
  paperclip: 'fas fa-paperclip',
  pen: 'fas fa-pen-clip',
  pending: 'fas fa-ellipsis-h',
  print: 'fas fa-print',
  plus: 'fas fa-plus',
  plusCircle: 'fas fa-plus-circle',
  qrCode: 'fas fa-qrcode',
  question: 'fas fa-question',
  redo: 'fas fa-redo',
  questionCircle: 'fas fa-question-circle',
  refresh: 'fas fa-sync',
  rocket: 'fas fa-rocket',
  scale: 'fas fa-balance-scale',
  search: 'fas fa-search',
  server: 'fas fa-server',
  selectAll: 'fas fa-check-double',
  sitemap: 'fas fa-sitemap',
  share: 'fas fa-share',
  show: 'far fa-eye',
  sign: 'fas fa-pencil-alt',
  signature: 'fas fa-file-signature',
  siteMap: 'fas fa-sitemap',
  spinner: 'fas fa-spin fa-spinner',
  spinner2x: 'fas fa-spin fa-spinner fa-2x',
  spinner3x: 'fas fa-spin fa-spinner fa-3x',
  spinner5x: 'fas fa-spin fa-spinner fa-5x',
  spinner8x: 'fas fa-spin fa-spinner fa-8x',
  star: 'fas fa-star',
  tasks: 'fas fa-tasks',
  table: 'fas fa-table',
  bulkActions: 'fas fa-mail-bulk',
  exercise: 'fas fa-file-invoice',
  termsheet: 'fas fa-file-invoice-dollar',
  ticket: 'fas fa-ticket-alt',
  time: 'far fa-clock',
  trash: 'fas fa-trash',
  triangleDown: 'fas fa-caret-down',
  triangleLeft: 'fas fa-caret-left',
  triangleRight: 'fas fa-caret-right',
  triangleUp: 'fas fa-caret-up',
  undo: 'fa fa-undo',
  upload: 'fas fa-upload',
  user: 'fas fa-user',
  userEdit: 'fas fa-user-edit',
  userGear: 'fas fa-user-cog',
  userShield: 'fas fa-user-shield',
  users: 'fas fa-users',
  sortAsc: 'fas fa-sort-amount-up',
  sortDesc: 'fas fa-sort-amount-down',
  linkedin: 'fab fa-linkedin',
  twitter: 'fab fa-twitter-square',
  facebook: 'fab fa-facebook-square',
  instagram: 'fab fa-instagram',
  timeline: 'fas fa-history',
  arrowRight: 'fas fa-long-arrow-alt-right',
  arrowLeft: 'fas fa-long-arrow-alt-left',
  arrowLeftRight: 'fas fa-exchange-alt',
  arrowUp: 'fas fa-long-arrow-alt-up',
  arrowDown: 'fas fa-long-arrow-alt-down',
  arrowLeftCircle: 'far fa-arrow-alt-circle-left',
  arrowRightCircle: 'far fa-arrow-alt-circle-right',
  arrowLeftCircleAlt: 'fas fa-circle-chevron-left',
  paste: 'fas fa-paste',
  twoPeople: 'fas fa-user-friends',
  inbox: 'fa fa-inbox',
  gravel: 'fas fa-gavel',
  feedback: 'fas fa-comments',
  hourglass: 'fas fa-hourglass-half',
  circlePlus: 'fas fa-circle-plus',
  moneyBill: 'fas fa-money-bill',
  copy: 'far fa-copy',
  circleUser: 'fas fa-circle-user',
  link: 'fas fa-link',
  linkSlash: 'fas fa-link-slash',
  eye: 'far fa-solid fa-eye',
  eyeSlash: 'far fa-solid fa-eye-slash',
  fileLinesFilled: 'fas fa-file-text',
  fileLines: 'far fa-file-text',
};

export const SBX_ICONS = {
  // SHOOBX ICONS
  // Commonly used icons for action buttons and framework elements
  shoobx: 'sbxf sbxf-shoobx-x',
  shoobxLogo: 'sbxf sbxf-shoobx-logo',
  docPending: 'sbxf sbxf-doc-pending',
  docGenerated: 'sbxf sbxf-doc-generated',
  docUploaded: 'sbxf sbxf-doc-uploaded',
  docDeferred: 'sbxf sbxf-doc-deferred',
  docCertificate: 'sbxf sbxf-doc-certificate',
  childArrow: 'sbxf sbxf-child-arrow',
  slash: 'sbxf sbxf-slash',
  shoobxCircle: 'sbxf sbxf-shoobx-circle',
  alertCircle: 'sbxf sbxf-alert-circle',
  signatureCircle: 'sbxf sbxf-signature-circle',
  metadataCircle: 'sbxf sbxf-metadata-circle',
  boldCheckmarkCircle: 'sbxf sbxf-checkmark-circle',
  pendingCircle: 'sbxf sbxf-pending-circle',
  verified: 'sbxf sbxf-check-verified',
  emailVerified: 'sbxf sbxf-email-verified',
  shoobxVerified: 'sbxf sbxf-shoobx-verified',
  domainVerified: 'sbxf sbxf-domain-verified',
  personVerified: 'sbxf sbxf-person-verified',
  carta: 'sbxf sbxf-carta',
};

interface ISbxIconTypes extends IFontAwesomeIconTypes, IShoobxFontIconTypes {}

type ISbxIconType = keyof ISbxIconTypes;

const SbxIconTypes: InjectionToken<ISbxIconTypes> = new InjectionToken<ISbxIconTypes>(
  'SbxIconTypes',
);
const SbxIconTypesProvider: ValueProvider = {
  provide: SbxIconTypes,
  useValue: { ...FA_ICONS, ...SBX_ICONS },
};

export { ISbxIconTypes, ISbxIconType, SbxIconTypes, SbxIconTypesProvider };
