import { Plugin } from '@ckeditor/ckeditor5-core';
import { Injectable } from '@angular/core';

function getClassInstance() {
  return class SoftBreak extends Plugin {
    init() {
      const editor = this.editor;
      editor.editing.view.document.on(
        'enter',
        (evt, data) => {
          if (data.isSoft) {
            editor.execute('enter');
          } else {
            editor.execute('shiftEnter');
          }
          data.preventDefault();
          evt.stop();
          editor.editing.view.scrollToTheSelection();
        },
        { priority: 'high' },
      );
    }
  };
}

@Injectable()
export class SbxSoftBreakService {
  getSoftBreakClass() {
    return getClassInstance();
  }
}
