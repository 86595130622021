import { Command } from '@ckeditor/ckeditor5-core';

export class CustomElemCommand extends Command {
  constructor(editor, tagName, placeholder, inline, attributes) {
    super(editor);
    this.tagName = tagName;
    this.placeholder = placeholder;
    this.attributes = attributes;
    this.inline = inline;
  }

  execute() {
    const model = this.editor.model;

    model.change((writer) => {
      const elem = writer.createElement(this.tagName, this.attributes);
      writer.appendText(this.placeholder, elem);
      const insertAtSelection = model.document.selection.getFirstPosition();
      model.insertContent(elem, insertAtSelection);

      if (elem.parent) {
        writer.setSelection(elem, 'on');
      }
    });
  }
}
