<p class="sbx-auth-main-title">Sign up for Fidelity Private Shares</p>
<div class="sbx-auth-content">
  <div class="sbx-auth-create-login">
    <p class="sbx-auth-sub-title">New to Fidelity?</p>

    <button 
      class="sbx-auth-primary-button" 
      (click)="navigateToSignup()" 
      [disabled]="isLoading">
        Create a Fidelity login
    </button>
  </div>

  <div class="sbx-auth-separator">
    <div class="sbx-auth-horizontal-line"></div>
    <div class="sbx-auth-text">Or</div>
    <div class="sbx-auth-horizontal-line"></div>
  </div>

  <div class="sbx-auth-sign-up">
    <p class="sbx-auth-sub-title">Already a Fidelity customer?</p>

    <button
      class="sbx-auth-secondary-button"
      (click)="navigateToLogin(loginVisitPurpose.newUser)"
    >
      Sign up with an existing Fidelity login
    </button>
    <p class="sbx-auth-info">
      Choose this option, if you have any kind of account with Fidelity, like a 401(k),
      IRA, brokerage account, etc.
    </p>
  </div>
</div>
