import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import {
  AuthIdentifyRequest,
  AuthIdentifyResponse,
  AuthIdentifyResponseNextEnum,
} from '@shoobx/types/webapi-v2';
import { Observable, Subject, catchError, finalize, of, take, takeUntil, tap } from 'rxjs';
import { SbxAuthService } from '../sbx-auth.service';
import { SbxUrlService } from '@/core/url/url.service';
import { LegacyLoginVisitPurpose } from '../utils/enums/legacy-login-visit-purpose';
import { LoginVisitPurpose } from '../utils/enums/login-visit-purpose';

@Component({
  selector: 'sbx-auth-legacy-login-page',
  templateUrl: './sbx-auth-legacy-login-page.component.html',
  styleUrls: ['./sbx-auth-legacy-login-page.component.scss'],
})
export class SbxAuthLegacyLoginPageComponent implements OnInit, OnDestroy {
  public authForm: FormGroup;
  public isPopupVisible = true;
  public errorMessage: string | null;
  public hide = true;
  public purpose: LegacyLoginVisitPurpose;
  public legacyLoginVisitPurposeEnum = LegacyLoginVisitPurpose;
  public isLoading = false;

  private destroy$: Subject<void> = new Subject();

  constructor(
    private readonly fb: FormBuilder,
    private readonly sbxAuthService: SbxAuthService,
    private readonly router: Router,
    protected readonly sbxUrlService: SbxUrlService,
    private readonly route: ActivatedRoute,
  ) {}

  public ngOnInit(): void {
    this.initForm();
    this.parseQueryParams();
  }

  public ngOnDestroy(): void {
    this.errorMessage = null;
    this.destroy$.next();
    this.destroy$.complete();
  }

  public submitForm(): void {
    this.isLoading = true;
    const email = this.authForm.value.email;
    const password = this.authForm.value.password;
    const authIdentifyRequestParams: AuthIdentifyRequest = {
      email,
      password,
      otp: null,
    };
    this.errorMessage = null;
    this.sbxAuthService
      .identifyUser(authIdentifyRequestParams)
      .pipe(
        take(1),
        tap((res) => this.handleIdentifyUserSuccess(res)),
        catchError(() => this.handleIdentifyUserError()),
        finalize(() => { this.isLoading = false; })
      )
      .subscribe();
  }

  public navigateToRecoveryPage(): void {
    this.router.navigate(['/auth/password-recovery']);
  }

  public hidePopup(): void {
    this.isPopupVisible = false;
  }

  public togglePasswordHide(): void {
    this.hide = !this.hide;
  }

  private initForm(): void {
    this.authForm = this.fb.group({
      email: ['', [Validators.required, Validators.email]],
      password: ['', Validators.required],
    });

    this.authForm.valueChanges.pipe(takeUntil(this.destroy$)).subscribe(() => {
      this.errorMessage = null;
    });
  }

  private parseQueryParams(): void {
    this.route.queryParams.pipe(take(1)).subscribe((params) => {
      this.purpose = params.purpose;
    });
  }

  private handleIdentifyUserSuccess(response: AuthIdentifyResponse): void {
    if (response.error) {
      this.errorMessage = response.error;
      return;
    }
    this.errorMessage = null;

    if (response.next === AuthIdentifyResponseNextEnum.Signup) {
      this.router.navigate(['/auth/choice'], {
        queryParams: { signupUrl: response.signupUrl, isVerified: true },
      });
    }
    if (response.next === AuthIdentifyResponseNextEnum.Completed) {
      this.router.navigate(['/auth/signup-complete']);
    }
    if (response.next === AuthIdentifyResponseNextEnum.Login) {
      this.router.navigate(['/auth/login'], {
        queryParams: { purpose: LoginVisitPurpose.recognized },
      });
    }
    if (response.next === AuthIdentifyResponseNextEnum.Abort) {
      this.router.navigate(['/auth/login'], {
        queryParams: { purpose: LoginVisitPurpose.abort },
      });
    }
  }

  private handleIdentifyUserError(): Observable<never> {
    window.location.replace(`/spa/error?withoutRedirect=true`);
    return of();
  }
}
