<div class="sbx-auth-alert-container" [ngClass]="'sbx-auth-alert-type-' + type">
  <div class="sbx-auth-alert-icon">
    <img
      [src]="sbxUrlService.assetUrl('img/fidelity/svg/alert-critical.svg')"
      alt="alert-critical"
      *ngIf="type === alertType.critical"
    />
    <img
      [src]="sbxUrlService.assetUrl('img/fidelity/svg/alert-info.svg')"
      alt="alert-info"
      *ngIf="type === alertType.info"
    />
    <img
      [src]="sbxUrlService.assetUrl('img/fidelity/svg/alert-success.svg')"
      alt="alert-success"
      *ngIf="type === alertType.success"
    />
    <img
      [src]="sbxUrlService.assetUrl('img/fidelity/svg/alert-warning.svg')"
      alt="alert-warning"
      *ngIf="type === alertType.warning"
    />
  </div>
  <div class="sbx-auth-alert-content">
    <ng-content></ng-content>
  </div>
  <div class="sbx-auth-alert-close">
    <img
      [src]="sbxUrlService.assetUrl('img/fidelity/svg/close-critical.svg')"
      alt="close-critical"
      (click)="closeAlert()"
      *ngIf="type === alertType.critical"
    />
    <img
      [src]="sbxUrlService.assetUrl('img/fidelity/svg/close-info.svg')"
      alt="close-info"
      (click)="closeAlert()"
      *ngIf="type === alertType.info"
    />
    <img
      [src]="sbxUrlService.assetUrl('img/fidelity/svg/close-success.svg')"
      alt="close-success"
      (click)="closeAlert()"
      *ngIf="type === alertType.success"
    />
    <img
      [src]="sbxUrlService.assetUrl('img/fidelity/svg/close-warning.svg')"
      alt="close-warning"
      (click)="closeAlert()"
      *ngIf="type === alertType.warning"
    />
  </div>
</div>
