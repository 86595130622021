import { Component, Inject } from '@angular/core';
import { SbxAuthService } from '../sbx-auth.service';
import { finalize, take } from 'rxjs';
import { AuthSignupConfigResponse } from '@shoobx/types/webapi-v2';
import { Router } from '@angular/router';
import { LoginVisitPurpose } from '../utils/enums/login-visit-purpose';

@Component({
  selector: 'sbx-auth-new-user-page',
  templateUrl: './sbx-auth-new-user-page.component.html',
  styleUrls: ['./sbx-auth-new-user-page.component.scss'],
})
export class SbxAuthNewUserPageComponent {
  loginVisitPurpose = LoginVisitPurpose;
  isLoading = false;

  constructor(
    private readonly sbxAuthService: SbxAuthService,
    private readonly router: Router,
    @Inject(Window) private readonly window: Window,
  ) {}

  public navigateToLogin(purpose: LoginVisitPurpose): void {
    this.router.navigate(['/auth/login'], {
      queryParams: { purpose },
    });
  }

  public navigateToSignup(): void {
    this.isLoading = true;
    this.sbxAuthService
      .signupConfig()
      .pipe(
        take(1),
        finalize(() => { this.isLoading = false; }))
      .subscribe((res: AuthSignupConfigResponse) => {
        if (res.alreadySignedUp) {
          // User already signed up and cannot sign up again. Ask to log in.
          this.navigateToLogin(LoginVisitPurpose.recognized);
        }
        if (res.signupUrl) {
          this.isLoading = true;
          this.window.location.assign(res.signupUrl);
        }
      });
  }
}
