<p class="sbx-auth-main-title">
  That Fidelity login is connected to a different Fidelity Private Shares account
</p>
<p class="sbx-auth-sub-title">
  By continuing, we'll merge this account with the one associated with the email:
</p>
<p class="sbx-auth-sub-title" id="duplicateHint">{{ duplicateHint$ | async }}</p>
<div class="sbx-auth-button-panel">
  <button
    class="sbx-auth-primary-button"
    id="continueAndMergeButton"
    (click)="mergeAndContinue()"
  >
    Continue and merge accounts
  </button>
  <button class="sbx-auth-secondary-button" id="cancelButton" (click)="cancel()">
    Cancel
  </button>
</div>
