import { Routes } from '@angular/router';
import { SbxAuthChoicePageComponent } from './sbx-auth-choice-page/sbx-auth-choice-page.component';
import { SbxAuthLoginPageComponent } from './sbx-auth-login-page/sbx-auth-login-page.component';
import { SbxAuthSignupCompletePageComponent } from './sbx-auth-signup-complete-page/sbx-auth-signup-complete-page.component';
import { SbxAuthLegacyLoginPageComponent } from './sbx-auth-legacy-login-page/sbx-auth-legacy-login-page.component';
import { SbxAuthTransitionStatusComponent } from './sbx-auth-transition-status/sbx-auth-transition-status.component';
import { SbxAuthMergePageComponent } from './sbx-auth-merge-page/sbx-auth-merge-page.component';
import { SbxAuthPasswordRecoveryPageComponent } from './sbx-auth-password-recovery-page/sbx-auth-password-recovery-page.component';
import { SbxAuthVerificationCodeComponent } from './sbx-auth-verification-code/sbx-auth-verification-code.component';
import { SbxAuthNewUserPageComponent } from './sbx-auth-new-user-page/sbx-auth-new-user-page.component';
import { SbxAuthAgreementPageComponent } from './sbx-auth-agreement-page/sbx-auth-agreement-page.component';

export enum AuthRoutePath {
  login = 'login',
  legacy = 'legacy',
  transitionStatus = 'transition-status',
  choice = 'choice',
  signupComplete = 'signup-complete',
  merge = 'merge',
  passwordRecovery = 'password-recovery',
  verificationCode = 'verification-code',
  newUser = 'new-user',
  agreement = 'agreement',
}

export const authRoutes: Routes = [
  {
    path: '',
    data: { hideBackground: true },
    children: [
      {
        path: AuthRoutePath.login,
        component: SbxAuthLoginPageComponent,
      },
      {
        path: AuthRoutePath.legacy,
        component: SbxAuthLegacyLoginPageComponent,
      },
      {
        path: AuthRoutePath.transitionStatus,
        component: SbxAuthTransitionStatusComponent,
      },
      {
        path: AuthRoutePath.choice,
        component: SbxAuthChoicePageComponent,
      },
      {
        path: AuthRoutePath.signupComplete,
        component: SbxAuthSignupCompletePageComponent,
      },
      {
        path: AuthRoutePath.merge,
        component: SbxAuthMergePageComponent,
      },
      {
        path: AuthRoutePath.passwordRecovery,
        component: SbxAuthPasswordRecoveryPageComponent,
      },
      {
        path: AuthRoutePath.verificationCode,
        component: SbxAuthVerificationCodeComponent,
      },
      {
        path: AuthRoutePath.newUser,
        component: SbxAuthNewUserPageComponent,
      },
      {
        path: AuthRoutePath.agreement,
        component: SbxAuthAgreementPageComponent,
      },
    ],
  },
];
