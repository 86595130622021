<div class="sbx-auth-login-form-container">
  <p
    class="sbx-auth-title"
    *ngIf="purpose === legacyLoginVisitPurposeEnum.startTransition"
  >
    Enter your credentials to get started
  </p>
  <p
    class="sbx-auth-title"
    *ngIf="purpose === legacyLoginVisitPurposeEnum.completeTransition"
  >
    Enter your old credentials to complete the transition process
  </p>
  <form [formGroup]="authForm" (ngSubmit)="submitForm()">
    <div class="sbx-auth-form-group">
      <label for="email">Email</label>
      <input
        class="sbx-auth-input"
        type="email"
        id="email"
        formControlName="email"
        name="email"
      />
      <label for="password">Password</label>
      <div class="sbx-auth-password-input">
        <input
          class="sbx-auth-input"
          type="{{ hide ? 'password' : 'text' }}"
          id="password"
          formControlName="password"
          name="password"
        />
        <div
          class="sbx-auth-password-visibility-toggle-icon"
          (click)="togglePasswordHide()"
        >
          <ng-container *ngTemplateOutlet="hide ? unmask : mask"></ng-container>
        </div>
      </div>
    </div>

    <div
      [style.visibility]="errorMessage ? 'visible' : 'hidden'"
      class="sbx-auth-error-message"
    >
      <img
        [src]="sbxUrlService.assetUrl('img/fidelity/svg/alert-critical.svg')"
        alt="alert-critical"
      />{{ errorMessage }}
    </div>

    <div class="sbx-auth-controls">
      <button
        class="sbx-auth-primary-button"
        type="submit"
        [disabled]="authForm.invalid || isLoading"
      >
        Continue
      </button>

      <a class="sbx-auth-link" (click)="navigateToRecoveryPage()"
        >Forgot your Fidelity Private Shares password?</a
      >
    </div>
  </form>
</div>

<sbx-info-action-popup (close)="hidePopup()" *ngIf="isPopupVisible">
  <p class="content">
    Have questions? Visit our
    <a
      class="sbx-auth-link"
      target="_blank"
      href="https://shoobx.zendesk.com/hc/en-us/articles/27911891574157-Fidelity-Log-In-Credentials"
      >Login Transition Help Center</a
    >.
  </p>
</sbx-info-action-popup>

<ng-template #unmask>
  <img [src]="sbxUrlService.assetUrl('img/fidelity/svg/unmask.svg')" alt="unmask" />
</ng-template>

<ng-template #mask>
  <img [src]="sbxUrlService.assetUrl('img/fidelity/svg/mask.svg')" alt="mask" />
</ng-template>
