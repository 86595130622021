import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SbxCompiledContentComponent } from './compiled-content.component';
import { SbxDepartmentsAdminComponent } from './departments-admin.component';
import { SbxHrTicketsAdminComponent } from './hr-tickets-admin.component';
import { SbxPdfUploadComponent } from './pdf-upload.component';
import { SbxDocumentDownloadComponent } from './document-download.component';
import { SbxDocumentDownloadDropdownComponent } from './document-download-dropdown.component';
import { SbxGenerateCustomizationReportComponent } from './generate-customization-report.component';
import { ProcessUrlInfoServiceProvider } from './process-url-info.service';
import { ProcessStatusServiceProvider } from './process-status.service';
import { SbxLoginFormComponent } from './sbx-login-form.component';
import { SbxWorkitemRemindButtonComponent } from './workitem-remind-button.component';
import { SbxRequireVerificationComponent } from './sbx-require-verification.component';
import { ProcessButtonModelServiceProvider } from './process-button-model.service';

@NgModule({
  imports: [CommonModule],
  declarations: [
    SbxCompiledContentComponent,
    SbxDepartmentsAdminComponent,
    SbxHrTicketsAdminComponent,
    SbxPdfUploadComponent,
    SbxDocumentDownloadComponent,
    SbxDocumentDownloadDropdownComponent,
    SbxGenerateCustomizationReportComponent,
    SbxLoginFormComponent,
    SbxWorkitemRemindButtonComponent,
    SbxRequireVerificationComponent,
  ],
  exports: [
    SbxCompiledContentComponent,
    SbxDepartmentsAdminComponent,
    SbxHrTicketsAdminComponent,
    SbxPdfUploadComponent,
    SbxDocumentDownloadComponent,
    SbxDocumentDownloadDropdownComponent,
    SbxGenerateCustomizationReportComponent,
    SbxLoginFormComponent,
    SbxWorkitemRemindButtonComponent,
    SbxRequireVerificationComponent,
  ],
  providers: [
    ProcessUrlInfoServiceProvider,
    ProcessStatusServiceProvider,
    ProcessButtonModelServiceProvider,
  ],
})
export class UpgradedModule {}
